var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-number" }, [
    _c("input", {
      ref: "customInputField",
      staticClass: "txt no-spinners u-textRight",
      class: {
        "is-danger": _vm.error,
        right: _vm.isTypePercentage,
        disabled: _vm.disabled,
      },
      attrs: {
        autocomplete: "off",
        type: "number",
        step: "any",
        placeholder: _vm.placeholder,
        "data-vv-delay": _vm.isDelayInput
          ? _vm.$getConst("INPUT_DELAY_TIME")
          : 0,
        disabled: _vm.disabled,
      },
      domProps: { value: _vm.value },
      on: {
        blur: function ($event) {
          return _vm.submitInput($event, "blur")
        },
        input: function ($event) {
          return _vm.submitInput($event, "input")
        },
      },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isTypePercentage,
            expression: "isTypePercentage",
          },
        ],
        staticClass: "percentage",
        class: _vm.disabled ? "disabled" : "",
      },
      [_vm._v("%")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }