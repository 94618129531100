<template lang="pug">
.total-counts
    .total-count(
      v-for="(countData, index) in totalCountArray"
      :class="countData.extraClass"
    )
      .total-count-label(:id="`total-count-list-label-${index}`") {{ countData.totalCountLabel }}:
      .total-count-value.ff-mono(:id="`total-count-list-value-${index}`") {{ countData.totalCountValue }}
</template>

<script>

export default {
  props: {
    totalCountArray: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

  .total-counts {
    display: flex;
    align-items: center;
    .total-count {
      &.with-bg {
        display: flex;
        align-items: center;
        background-color: $color-white;
        border: 1px solid $color-gray;
        border-radius: $border-high-radius;
      }

      &.simple {
        .total-count-value {
          margin-top: 5px;
        }
      }

      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;

      &-label, &-value {
        font-size: $font-size-micro;
      }

      &-label {
        color: $color-success;
        font-weight: $font-weight-bold;
        margin-right: 10px;
        white-space: nowrap;
      }

      &-value {
        color: $color-text-dark;
      }
    }
  }
</style>
