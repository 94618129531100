<template lang="pug">
.toggleList
    h2.title {{ title }}
    SelectedButtons(
    type="tab",
    :id="id",
    :data="list",
    v-model="selectedItem",
    size="xsm")
</template>

<script>

export default {
  name: 'ToggleFilter',
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {},
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array
    }
  },
  computed: {
    selectedButtonIdTitle () {
      return this.title.toLowerCase().replace(' ', '-')
    }
  },
  data () {
    return {
      selectedItem: null
    }
  },
  mounted () {
    this.selectedItem = this.list[0].value
  },
  watch: {
    selectedItem () {
      this.$emit('input', this.selectedItem)
    }
  }
}
</script>

<style lang="scss" scoped>

  .toggleList {
    padding: 20px 20px 0 20px;
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 13px;
    }
  }
</style>
