<template lang="pug">
.item-info
    .row(v-for="item in listData" :class="{ 'multiple-values': item.list.length > 1 }")
      .col.title {{ item.title }}
      .col.value
        span.item-info__name(v-for="infoName in item.list")
          | {{ infoName }}
</template>

<script>

export default {
  name: 'item-info-showcase',
  props: {
    listData: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

  .item-info {
    margin-bottom: 25px;

    .row {
      position: relative;
      margin-top: -1px;
      height: $finger-size;
      border: 1px solid $color-gray;
      background-color: white;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: $font-size-small;
      padding-right: 10px;
      justify-content: space-between;

      &.multiple-values {
        height: auto;
        min-height: $finger-size;
        align-items: flex-start;
        padding: 18px 10px;
      }

      &:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      .col {
        &.title {
          font-weight: $font-weight-bold;
          color: $color-success;
        }

        &.value {
          color: $color-text-dark;
        }

        .item-info__name {
          &:not(:last-child) {
            &::after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0 5px;
              vertical-align: middle;
              background: $color-gray;
              border-radius: 50%;
            }
          }
        }

      }

      &.multiple-values .col.value {
        padding-left: 10px;
        text-align: right;
      }
    }
  }
</style>
