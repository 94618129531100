<template lang="pug">
component(
      :is     ="hasRoute ? 'router-link' : 'button'"
      :tag    ="hasRoute ? 'button' : false",
      :to     ="to",
      :class  ="variants",
      type    ='button',
      v-bind  ="$attrs",
      v-on    ="$listeners"
    )

    Loading(
      theme   ="disable",
      :class  ="[{'icon-left': !justIcon}]"
      v-show  ="isLoading"
    )

    Icon(
      :name   ="iconName",
      v-if    ="iconName && !isLoading",
      :class  ="[iconClass, {'icon-left': !justIcon}]"
      :useIconClass="useIconClass"
    )

    slot
</template>

<script>

export default {
  props: {
    justIcon: {
      type: Boolean,
      default: false
    },
    useIconClass: {
      type: Boolean,
      default: true
    },
    clearVariant: {
      type: Boolean,
      default: false
    },
    variant: { type: String },
    size: { type: String },
    inline: { type: Boolean },
    primary: { type: Boolean },
    secondary: { type: Boolean },
    danger: { type: Boolean },
    seashell: { type: Boolean },
    success: { type: Boolean },
    ghostsuccess: { type: Boolean },
    green: { type: Boolean },
    glowsuccess: { type: Boolean },
    iconName: {
      default: '',
      type: String
    },
    iconClass: {
      default: '',
      type: String
    },
    to: {
      type: [String, Object],
      default: () => {}
    },
    isLoading: { type: Boolean, default: false }
  },
  computed: {
    variants () {
      return {
        btn: !this.clearVariant,
        [`btn-${this.size}`]: this.size,
        [`${this.variant && this.variant.substring(0, 3) === 'btn' ? this.variant : !this.clearVariant ? 'btn-' + this.variant : this.variant}`]: this.variant,
        [`btn-inline`]: this.inline,
        [`btn-primary-green`]: this.secondary ? false : this.primary,
        [`btn-secondary-green`]: this.primary ? false : this.secondary,
        [`btn-seashell`]: this.seashell,
        [`btn-danger`]: this.danger,
        [`btn-success`]: this.success,
        [`btn-ghost-success`]: this.ghostsuccess,
        [`btn-green`]: this.green,
        [`btn-glow-success`]: this.glowsuccess
      }
    },
    hasRoute () {
      return (
        (typeof this.to === 'string' && this.to.length > 0) ||
        (typeof this.to === 'object' && Object.keys(this.to).length > 0)
      )
    }
  }
}
</script>
