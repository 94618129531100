var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-multiselect-list" }, [
    _c(
      "div",
      {
        staticClass: "select dropdown-handle-button",
        class: {
          "has-values": _vm.hasValues && !_vm.disablePopup,
          "is-danger": _vm.isValid,
        },
        attrs: {
          "aria-controls": _vm.uniquePopupId,
          "aria-expanded": _vm.isPopupVisible,
          tabindex: "0",
        },
        on: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.togglePopup.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.togglePopup.apply(null, arguments)
            },
          ],
          click: _vm.togglePopup,
        },
      },
      [
        _c("Icon", {
          staticClass: "dropdown-handle-button",
          attrs: { name: "icon-down-arrow" },
        }),
        _c("div", { staticClass: "dropdown-handle-button-values" }, [
          _vm._v(_vm._s(_vm.dropdownLabel)),
        ]),
      ],
      1
    ),
    _vm.isPopupVisible && !_vm.disablePopup
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.hidePopup,
                expression: "hidePopup",
              },
              {
                name: "shortkey",
                rawName: "v-shortkey",
                value: ["esc"],
                expression: "['esc']",
              },
            ],
            staticClass: "popup",
            attrs: { id: _vm.uniquePopupId },
            on: { shortkey: _vm.hidePopup },
          },
          [
            _c(
              "div",
              { staticClass: "popup-content" },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  { staticClass: "MiniPopup-tr popup-row" },
                  [
                    _c("CustomCheckbox", {
                      staticClass: "popup-item-check",
                      attrs: {
                        id: `checkbox-multiselect-list-item-${_vm.id}-${index}`,
                        checked: _vm.selected.indexOf(item) > -1,
                        label: item.name,
                      },
                      on: {
                        change: (isChecked) => _vm.selectItem(item, isChecked),
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }