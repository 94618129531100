var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.hasRoute ? "router-link" : "button",
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: _vm.variants,
          attrs: {
            tag: _vm.hasRoute ? "button" : false,
            to: _vm.to,
            type: "button",
          },
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("Loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        class: [{ "icon-left": !_vm.justIcon }],
        attrs: { theme: "disable" },
      }),
      _vm.iconName && !_vm.isLoading
        ? _c("Icon", {
            class: [_vm.iconClass, { "icon-left": !_vm.justIcon }],
            attrs: { name: _vm.iconName, useIconClass: _vm.useIconClass },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }