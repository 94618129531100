<template lang="pug">
svg(
    :class="[name, useIconClass ? 'icon' : null]"
  )
    use(:xlink:href="`#${name}`")
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    useIconClass: {
      type: Boolean,
      default: true
    }
  }
}
</script>
