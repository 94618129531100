<template lang="pug">
div 
  Button.import-btn(
    secondary,
    size="small"
    :disabled="isDisabled"
    :id="`btn-import-export-${componentName}`"
    :justIcon="true"
    iconName="icon-down-arrow"
    iconClass="icon-right"
    @click.stop="toggleOtherOptions"
  )
    span {{ $t('Global.importExport_Header') }}
    a.display-none-download-link(
      :download="downloadFileExportName"
      ref="downloadButton"
    )
    input#file.import-excel-hidden(
      type='file'
      ref='file'
      :accept="importIsEmitData ? 'text/xml' : 'all'"
      :multiple="importIsEmitData ? 'multiple' : false"
      @change='handleFileUpload'
    )
    .MiniPopup.right.import-export(
      v-click-outside="closeOtherOptions"
      v-if="showOtherOptions"
      v-shortkey="['esc']"
    )
      Button(
        v-if="isTemplateDownloadActive"
        size="small"
        secondary
        :disabled="templateDisabled"
        variant="full"
        :id="`import-export-template-download-${componentName}`"
        iconName="icon-import-export-template"
        @click="downloadTemplate"
      )
        span {{ $t('Global.importExport_Template') }}

      Button(
        v-if="isImportActive"
        size="small"
        variant="full"
        secondary
        :id="`import-export-import-${componentName}`"
        iconName="icon-import-export-import-xls"
        @click="clickFile"
      )
        span {{ getImportText }}


      Button(
        v-if="isExportActive"
        size="small"
        secondary
        :disabled="downloadDisabled"
        variant="full"
        :id="`import-export-download-${componentName}`"
        iconName="icon-import-export-download-xls"
        @click="download"
      )
        span {{ getExportText }}

      Button(
        v-if="isUpdateActive"
        size="small"
        variant="full"
        secondary
        :id="`import-export-update-${componentName}`"
        iconName="icon-xls"
      )
        span {{ $t('Global.importExport_Update') }}
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import downloadCSVMixin from '@/mixins/download-actions'

export default {
  name: 'import-export',
  mixins: [downloadCSVMixin],
  props: {
    componentName: {
      type: String,
      default: ''
    },
    activeImportExports: {
      type: Array,
      default: () =>[]
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isTypeMassec: {
      type: Boolean,
      default: false
    },
    templateUrl: {
      type: String,
      default: ''
    },
    templateDisabled: {
      type: Boolean,
      default: false
    },
    templateFileType:{
      type: String,
      default: 'csv'
    },
    templateFileName: {
      type: String,
      default: ''
    },
    templateMethod: {
      type: String,
      default: 'GET'
    },
    importFileType: {
      type: String,
      default: 'file'
    },
    importUrl: {
      type: String,
      default: ''
    },
    importDisabled: {
      type: Boolean,
      default: false
    },
    importIsLoading: {
      type: Boolean,
      default: false
    },
    importIsEmitData: {
      type: Boolean,
      default: false
    },
    importText: {
      type: String,
      default: ''
    },
    downloadRef: {
      type: String,
      default: ''
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    downloadCustomPopup: {
      type: Boolean,
      default: false
    },
    downloadFileName: {
      type: String,
      default: ''
    },
    downloadFileType: {
      type: String,
      default: 'csv'
    },
    downloadText: {
      type: String,
      default: ''
    },
    downloadDisabled: {
      type: Boolean,
      default: false
    },
    downloadIsLoading: {
      type: Boolean,
      default: false
    },
    downloadClasses: {
      type: String,
      default: ''
    },
    downloadParams: {
      type: Object,
      default: () => {}
    },
    downloadMethod: {
      type: String,
      default: 'POST'
    },
    massecInventoryInfo: {
      type: Object,
      default: () => {}
    },
    massecPage: {
      type: String,
      default: ''
    },

  },
  data () {
    return {
      file: '',
      downloadData: '',
      showOtherOptions: false,
    }
  },

  watch: {
    file() {
      this.submitFile()
    }
  },

  mounted () {
    if(this.isTypeMassec) {
      this.checkIntegrationMassecStatus()
    }
  },

  computed: {

    ...mapGetters('Integration', [
      'massecIsActive'
    ]),

    succesActionRouterName () {
      switch (this.massecPage) {
        case 'inventory':
          return 'InventoryMassecImport'
        case 'spot-inventory':
          return 'InventorySpotMassecImport'
      }
    },


    downloadFileExportName () {
      return `${this.downloadFileName}.${this.downloadFileType}`
    },
    getImportText () {
      return this.importText || this.$t('Global.importExport_Import')
    },
    getExportText () {
      return this.downloadText || this.$t('Global.importExport_Export')
    },

    massecImportUrl () {
      return `Inventory/import/massec?InventoryHeaderId=${this.massecInventoryInfo.id}`
    },    

    isImportActive () {
      return this.activeImportExports.length > 0 && this.activeImportExports.includes('import')
    },
    isExportActive () {
      return this.activeImportExports.length > 0 && this.activeImportExports.includes('export')
    },
    isUpdateActive () {
      return this.activeImportExports.length > 0 && this.activeImportExports.includes('update')
    },
    isTemplateDownloadActive () {
      return this.activeImportExports.length > 0 && this.activeImportExports.includes('template')
    },
    isMassecActive () {
      return this.activeImportExports.length > 0 && this.activeImportExports.includes('massec')
    },

  },

  methods: {
    ...mapActions('Global', [
      'importFile'
    ]),
    ...mapActions('Reports', [
      'downloadReports'
    ]),
    ...mapActions('Global', [
      'exportExcel'
    ]),
    ...mapActions('Integration', [
      'checkIntegrationMassecStatus'
    ]),

    // Massec
    importConfirmAction (result) {
      this.$router.push({
        name: this.succesActionRouterName,
        params: {
          countList: [...result.data.inventoryCountList]
        }
      }) 
    },

    massecSubmitFile () {
      this.importFile({ url, file })
        .then(result => {
          this.importConfirmAction(result)
        })
    },

    // Import Excel
    submitFile (url = this.importUrl, file = this.file) {
      this.importFile({ url, file })
        .then(result => {
          this.$emit('importFileSuccess', result)
        })
    },

    handleFileUpload () {
      if (this.$refs.file.files.length > 0) {
        if (this.importIsEmitData) this.$emit('fileUpload', this.$refs.file.files)
        else {
          this.file = this.$refs.file.files[0]
          this.$refs.file.value = ''
        }
      }
    },
    clickFile () {
      this.closeOtherOptions()
      this.$refs.file.click()
    },

    downloadCSV (val) {
      const link = this.$refs.wrapper.$refs.downloadButton
      const url = val === 'template' ? this.templateUrl : this.downloadUrl
      const params = val === 'template' ? this.templateParams : this.downloadParams
      const refName = this.downloadCustomPopup ? 'wrapper' : null
      this.downloadCSVAction({ link, url, params, refName })
    },

    downloadExcel (val) {
      const url = val === 'template' ? this.templateUrl : this.downloadUrl
      const params = val === 'template' ? this.templateParams : this.downloadParams
      const refName = this.downloadCustomPopup ? 'wrapper' : null
      const method = val === 'template' ? this.templateMethod : this.downloadMethod
      this.downloadExcelAction({ url, refName, params, method })
    },
    //For PriceQuote
    download () {
      if(this.downloadCustomPopup) this.$emit('downloadClick')
      else this.downloadFile()
    },

    downloadTemplate () {
      if (this.templateFileType === 'xlsx') this.downloadExcel('template')
      else this.downloadCSV('template')
    },

    downloadFile () {
      if (this.downloadFileType === 'xlsx') this.downloadExcel()
      else this.downloadCSV()
    },

    toggleOtherOptions () {
      this.showOtherOptions = !this.showOtherOptions
      this.$emit('toggleOtherOptions', this.showOtherOptions)
    },

    closeOtherOptions () {
      this.showOtherOptions = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/stylesheet/config/mixin";

.import-btn {
  display: flex;
  flex-direction: row-reverse;  

  :deep() .icon-right {
    height: 10px !important;
    width: 10px !important;
  }
}
.import-export {
  .btn {
    justify-content: start;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0 1px $color-gray;
    &:hover {
      background-color: $color-gray;
    }
  }
  span {
    color: $color-light;
    font-weight: $font-weight-bold;
  }
}
.MiniPopup {
  z-index: $z-index-lg;
  padding: 1px;
  width: max-content;
  top: 45px;
  min-width: 165px;
  border: none;
  box-shadow: none
}
.import-excel {
  position: relative;

  &-hidden {
    position: absolute;
    display: none;
    width: 1px;
    height: 1px;
  }
}
.display-none-download-link {
  display: none;
}
</style>
