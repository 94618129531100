<template lang="pug">
transition(name="slide")
    .Popup(
    v-shortkey="['esc']",
    @shortkey="close")

      .Popup-overlay(@click="close")

      .Popup-body

        .Popup-header
          .content
            h3.Popup-header-title
              slot(name="title")
            Button.Popup-header-close(
            id="btn-popup-view-close"
            variant="icon"
            type="button"
            iconName="icon-popup-close"
            iconClass="icon-popup-close"
            @click="close")

        .Popup-content
          .Item(
          v-for="(field, index) in fields",
          :key="index",
          v-if="hyphen || item[field.name] !== 'null' && item[field.name] !== null && item[field.name] !== ''")
            h4.title {{ field.title || '–' }}
            p {{ item[field.name] || '–' }}

</template>

<script>

export default {
  name: 'PopupView',
  props: {
    item: {
      type: Object,
      default: {}
    },
    fields: {
      type: Array,
      default: []
    },
    hyphen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      if (this.$route.matched.length > 0) {
        this.$router.push({
          name: this.$route.matched[0].name
        })
      }
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

  .Popup {
    display: block;

    &-content {
      padding: 0;
    }
  }

  .Item {
    padding: 20px 30px;

    &:nth-child(even) {
      background-color: #f5fbff;
    }

    .title {
      font-weight: $font-weight-normal;
      margin-bottom: 10px;
      color: $color-text-light;
    }
  }
</style>
