<template lang="pug">
thead.Tableee-head
    tr.Tableee-row
      th.Tableee-col.selectable-col.fixed(v-if="selectableAll")
        CustomCheckbox(
          :id="`checkbox-table-row`"
          :label="''"
          :checked="isAllSelected()"
          @change="allSelected")
      template(v-for="(field, index) in fields")
        th.Tableee-col(
          v-if="field.headerPortal"
          :id="`table-head-${componentName}-col-${index}`"
          :class="columnHeaderClassObject(field)"
          :style="field.style || ''")
          portalTarget(:class="columnHeaderClassObject(field)" :name="field.headerPortal" :id="`table-head-${componentName}-col-${index}`" :slot-props="field")
        th.Tableee-col(
        v-else-if="field.is_visible"
        :id="`table-head-${componentName}-col-${index}`"
        :class="columnHeaderClassObject(field)")
          .flexbox
            span(
              :style="field.style || ''"
            ) {{ field.short_title || '' }}

            .info(v-if="infoData && infoData.length && field.infoHeader")
              span.info-button
                Icon.show-MiniPopup.info-button-icon(name="icon-global-info-filled")
                ul.info-popup
                  li.info-popup-row(v-for="item in infoData")
                    span.info-popup-value(:class="`bgc-${item.value}`")
                    span.info-popup-text {{ item.text }}

            toggle-sorting.m-left-15(
              v-if="field.sortingObject && field.is_sortable"
              :item="field.sortingObject"
              @sort="$emit('sort', field)"
            )
            span.m-left-15.filter-button(
              :ref="`searchFilterButton${index}`"
              v-if="field.filter"
              @click="openPopup(field)"
            )
              Icon(name="icon-global-settings")

            popup-filter(
              label="name"
              v-if="field.filter && field.filter.showFilter"
              v-click-outside="() => closeFilterPopup(field)"
              :popup-position="position(`searchFilterButton${index}`)"
              :headerLabel="field.filter.headerLabel"
              :unselectText="$t('Global.Toggle_Unselect')"
              :list="field.filter.list"
              :isClearFilterAvailable="field.filter.isClearFilterAvailable"
              :selected-list="field.filter.selectedList"
              :block-scroll="false"
              @onChangeCheckbox="({ item, isChecked }) => onChangePopupFilterCheckbox({ item, isChecked, field })"
              @submitFilter="submitFilter(field)"
              @toggleAll="isSelectedAll => toggleAll(isSelectedAll, field)"
              @clearFilter="clearFilter(field)"
              @changeSearch="search => changePopupFilterSearch(search, field)"
              :key="field.name"
            )

      th.Tableee-col.action(v-if="!readOnly")
      th.Tableee-col.portal-action(v-if="usePortalAction")
</template>

<script>

import toggleSorting from '@/view/global/toggle-sorting'
import popupFilter from '@/view/global/popup-filter'

export default {
  name: 'TableHead',
  props: ['fields', 'readOnly', 'selectableAll', 'componentName', 'isCheckedAll', 'selectedRows', 'data', 'usePortalAction', 'infoData'],
  components: {
    toggleSorting,
    popupFilter
  },
  data () {
    return {
      isOpen: false,
      stockItemList: []
    }
  },
  computed: {
    columnHeaderClassObject () {
      return ({ name, type, fixedColumnHeader }) => ([
        name,
        { fixed: fixedColumnHeader },
        {
          mono: (
            type === 'currency' ||
              type === 'mono' ||
              type === 'percent'
          )
        }
      ])
    }
  },
  methods: {

    allSelected () {
      this.$emit('showAllSelectedItems', !this.isCheckedAll)
    },
    isAllSelected () {
      return this.data.length === this.selectedRows.length
    },

    position (item) {
      if (!Object.keys(this.$refs).length) return
      const filterWidth = 281
      return {
        position: 'fixed',
        left: this.$refs[item][0].getBoundingClientRect().left - (filterWidth / 2) + 5 + 'px',
        top: this.$refs[item][0].getBoundingClientRect().top + 10 + 'px'
      }
    },

    openPopup (field) {
      field.filter.showFilter = true
    },

    onChangePopupFilterCheckbox ({ item, isChecked, field }) {
      if (isChecked) {
        field.filter.selectedList.push(item)
      } else {
        field.filter.selectedList.splice(field.filter.selectedList.findIndex(i => i.id === item.id), 1)
      }
    },

    submitFilter (field) {
      this.closeFilterPopup(field)
      this.$emit('submitFilter', field)
    },

    clearFilter (field) {
      if (field.filter.backendSearch) {
        field.filter.list = []
        field.filter.selectedList = []
      } else field.filter.selectedList = []
      this.closeFilterPopup(field)
      this.$emit('clearFilter', field)
    },

    toggleAll(isSelectedAll, field) {
      field.filter.selectedList = []
      if (!isSelectedAll) {
        field.filter.list.forEach(item => field.filter.selectedList.push(item))
      }
    },

    changePopupFilterSearch (search, field) {
      this.$emit('changePopupFilterSearch', {search, field})
    },

    closeFilterPopup (field) {
      field.filter.showFilter = false
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";
  $info-icon-size: 15px;
  .info {
    display: flex;

    &-button {
      position: relative;
      height: auto;

      &-icon {
        margin-left: 2.4px;
        width: $info-icon-size;
        height: $info-icon-size;
        color: $color-primary;
        cursor: pointer;
      }
    }

    &-popup {
      @include MiniPopupMixin(
        $display: none,
        $width: 186px,
        $max-width: 340px,
        $triangle-direction: 'left',
        $padding-hor: 0,
        $padding-ver: 0,
      );

      color: $color-dark;

      &-row {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
      }

      &-row:not(:last-child) {
        border-bottom: 1px solid $color-gray;
      }

      &-value {
        font-size: $font-size-normal;
        min-width: 30px;
        min-height: 7px;
        border-radius: 3.5px;
      }

      &-text {
        margin-left: 11px;
        color: $color-dark;
        font-size: $font-size-normal;
      }
    }
  }
  .filter-button {
    color: #d0d0d2;
  }

</style>
