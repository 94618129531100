var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Tableee-foot" }, [
    _c("div", {
      staticClass: "info",
      domProps: { innerHTML: _vm._s(_vm.footNote) },
    }),
    _c("div", { staticClass: "pagination" }, [
      _vm.showPageSize
        ? _c(
            "div",
            {
              staticClass: "pagination-system",
              attrs: { id: "pagination-system-selection" },
            },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("Global.goPaginationNumberSizeLabel"))),
              ]),
              _c("customSelect", {
                attrs: {
                  optionData: _vm.numberData,
                  componentName: "table-foot-select",
                  isDefaultTextActive: false,
                },
                on: { inputSelected: _vm.onPageSizeChange },
                model: {
                  value: _vm.page.size,
                  callback: function ($$v) {
                    _vm.$set(_vm.page, "size", $$v)
                  },
                  expression: "page.size",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showPagination
        ? _c(
            "div",
            {
              staticClass: "pagination-system",
              attrs: { id: "pagination-system" },
            },
            [
              _c("label", { staticClass: "seperator" }),
              _c(
                "Button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.page.number > 1,
                      expression: "page.number > 1",
                    },
                  ],
                  class: { "is-disable": !(_vm.page.number > 1) },
                  attrs: {
                    id: "btn-table-pagination-prev",
                    type: "button",
                    primary: "",
                    size: "xsmall",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onChangePage("prev")
                    },
                  },
                },
                [_vm._v(_vm._s("<"))]
              ),
              _vm.showNumberFirst && !_vm.isPageLastUnder5
                ? _c(
                    "Button",
                    {
                      staticClass: "btn",
                      attrs: {
                        type: "button",
                        primary: "",
                        size: "xsmall",
                        id: "btn-table-pagination-number-1",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onChangePage(1)
                        },
                      },
                    },
                    [_vm._v("1")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.showNumberFirst &&
                        _vm.page.number - 1 !== 2 &&
                        !_vm.isPageLastUnder5,
                      expression:
                        "showNumberFirst && page.number - 1 !== 2 && !isPageLastUnder5",
                    },
                  ],
                  staticClass: "dot-Pagination",
                },
                [_vm._v("...")]
              ),
              _vm._l(_vm.pageLoop, function (p) {
                return _c(
                  "button",
                  {
                    staticClass: "btn",
                    class: { "is-active": p === _vm.page.number },
                    attrs: {
                      type: "button",
                      id: `btn-table-pagination-number-${p}`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onChangePage(p)
                      },
                    },
                  },
                  [_vm._v(_vm._s(p))]
                )
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.showNumberLast &&
                        _vm.page.number !== _vm.page.last - 2 &&
                        !_vm.isPageLastUnder5,
                      expression:
                        "showNumberLast && page.number !== page.last - 2 && !isPageLastUnder5",
                    },
                  ],
                  staticClass: "dot-Pagination",
                },
                [_vm._v("...")]
              ),
              _vm.showNumberLast && !_vm.isPageLastUnder5
                ? _c(
                    "Button",
                    {
                      attrs: {
                        type: "button",
                        primary: "",
                        size: "xsmall",
                        id: `btn-table-pagination-number-${1}`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onChangePage(_vm.page.last)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.page.last))]
                  )
                : _vm._e(),
              _c(
                "Button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.page.number < _vm.page.last,
                      expression: "page.number < page.last ",
                    },
                  ],
                  class: { "is-disable": _vm.page.number >= _vm.page.last },
                  attrs: {
                    type: "button",
                    primary: "",
                    size: "xsmall",
                    id: "btn-table-pagination-next",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onChangePage("next")
                    },
                  },
                },
                [_vm._v(_vm._s(">"))]
              ),
              _c(
                "label",
                { staticClass: "seperator", attrs: { for: "inputValue" } },
                [_vm._v(_vm._s(_vm.$t("Global.goPaginationNumberLabel")))]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                staticClass: "no-spinners",
                attrs: { type: "number", step: "any", name: "inputValue" },
                domProps: { value: _vm.inputValue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputValue = $event.target.value
                  },
                },
              }),
              _c("Button", {
                attrs: {
                  type: "button",
                  primary: "",
                  size: "xsmall",
                  id: "btn-table-pagination-next",
                  iconName: "icon-table-next",
                  iconClass: "icon-table-next",
                  disabled:
                    _vm.inputValue < 1 ||
                    +_vm.inputValue === +_vm.page.number ||
                    _vm.inputValue > _vm.page.last,
                },
                on: {
                  click: function ($event) {
                    return _vm.onChangePage(_vm.inputValue)
                  },
                },
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }