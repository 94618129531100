var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "EmptyState" }, [
    _c(
      "div",
      { staticClass: "EmptyState-inner" },
      [
        _c("Icon", {
          staticClass: "empty-icon",
          attrs: { name: _vm.iconName, useIconClass: false },
        }),
        _c("h3", { staticClass: "title", attrs: { id: "empty-state-title" } }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c(
          "p",
          {
            staticClass: "description",
            attrs: { id: "empty-state-description" },
          },
          [_vm._v(_vm._s(_vm.description))]
        ),
        _vm.optionalText
          ? _c(
              "span",
              {
                staticClass: "optional-text",
                attrs: { id: "empty-state-optional-text" },
              },
              [
                _c("Icon", {
                  staticClass: "optional-text-icon",
                  attrs: { name: _vm.optionalIcon, useIconClass: false },
                }),
                _c("h4", { staticClass: "optional-text-title" }, [
                  _vm._v(_vm._s(_vm.optionalText)),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.buttonText
          ? _c(
              "Button",
              {
                class: `btn ${_vm.buttonType}`,
                attrs: {
                  size: "large",
                  id: "btn-empty-state-action",
                  type: "button",
                },
                on: { click: _vm.add },
              },
              [
                _vm.showIcon
                  ? _c("Icon", {
                      staticClass: "icon icon-left",
                      attrs: { name: _vm.buttonIcon, useIconClass: false },
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.buttonText))]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }