<template lang="pug">
div.appTooltip(
    :class="variants",
    v-if="text"
    )
    span.text {{ text }}
</template>

<script>

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    variant: { type: String },
    primary: { type: Boolean },
    black: { type: Boolean },
    primaryLight: { type: Boolean },
    success: { type: Boolean },
    dark: { type: Boolean },
    light: { type: Boolean },
    white: {type: Boolean},
    seashell: { type: Boolean },
    warning: { type: Boolean },
    gray: { type: Boolean },
    ocean: { type: Boolean },
    transparent: { type: Boolean },
    left: { type: Boolean },
    right: { type: Boolean },
    top: { type: Boolean },
    bottom: { type: Boolean },
    bottomLeft: { type: Boolean }
  },
  computed: {
    variants () {
      return {
        [`black`]: this.black,
        [`primary`]: this.primary,
        [`dark`]: this.dark,
        [`light`]: this.light,
        [`light-primary`]: this.primaryLight,
        [`success`]: this.success,
        [`seashell`]: this.seashell,
        [`warning`]: this.warning,
        [`gray`]: this.gray,
        [`ocean`]: this.ocean,
        [`white`]: this.white,
        [`transparent`]: this.transparent,
        [`left`]: this.left,
        [`right`]: this.right,
        [`top`]: this.top,
        [`bottom`]: this.bottom,
        [`bottomLeft`]: this.bottomLeft,
        [`${this.variant}`]: this.variant
      }
    }
  }
}
</script>

<style lang="scss">

.appTooltip {
  &.small {
    font-size: 10px;
  }
  color: #fff;
  padding: 8px 12px;
  border-radius: $border-radius;
  width: max-content;
  max-width: 320px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  box-shadow: 0px 0px 2px #00000017;
  position: absolute;
  z-index: $z-index-xl;
   @each $variant, $color in $theme  {
     &.#{$variant} {
        background: $color;
        @if ($variant == 'light' or $variant == 'ocean' or $variant == 'light-primary' or $variant == 'white') {
          color: $color-dark;
        }
        &.left::before {
          border-color: transparent transparent transparent $color;
        }
        &.right::before {
          border-color: transparent $color transparent transparent;
        }
        &.top::before {
          border-color: $color transparent transparent transparent;
        }
        &.bottom::before {
          border-color: transparent transparent $color transparent;
        }
        &.bottomLeft::before {
          border-color: transparent transparent $color transparent;
        }
     }
   }

  .text {
    display: flex;
    white-space: normal;
    text-align: center;
    font-weight: 400;
    color: $color-dark;
  }
  &.left {
    right: 100%;
    transform: translateX(5px) translateY(-50%);
    top: 50%;
    &::before {
      content: '';
      z-index: $z-index-xl;
      position: absolute;
      right: -10px;
      border-width: 5px;
      border-style: solid;
    }
  }
  &.right {
    left: 100%;
    transform: translateX(5px) ;
    top: 50%;
    &::before {
      content: '';
      z-index: $z-index-xl;
      left: -10px;
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }

  &.top {
    bottom: 100%;
    margin-bottom: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    &::before {
      content: '';
      z-index: $z-index-xl;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
    }
  }

  &.bottom {
    top: 100%;
    left: 55%;
    z-index: $z-index-xl;
    transform: translateX(-50%);
    margin-top: 10px;
    &::before {
      content: '';
      z-index: $z-index-xl;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
    }
  }
  &.bottomLeft {
    top: 100%;
    left: 0;
    margin-top: 5px;

    &::before {
      content: '';
      z-index: $z-index-xl;
      position: absolute;
      bottom: 100%;
      left: 20px;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
    }
  }
}
.tooltip-relative{
  cursor: pointer;
  //position: relative;
  &:hover {
    position: relative;
    .appTooltip {
      display: block;
    }
  }
}
</style>
