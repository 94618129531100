<template lang="pug">
li
    a.dropLink(
      :id="link.id"
      v-if="hasChild",
      @click="toggleDropdown",
      :class="{ 'isSelected' : showChildren, 'combo-router-active': hasActiveChild }")
        span.icon-body.tooltip-relative
          Icon(:name="link.icon")
          Tooltip.small(
          v-if="MenuMinimalize"
          white,
          right,
          :text="name")
        span.homeName(v-if="!MenuMinimalize") {{ name }}
        span.arrow-down-icon(v-if="!MenuMinimalize")
          Icon(name="icon-arrow-down")

    router-link.dropLink(
      :class="{'selfLink' : link.role === 'MainLink'}"
      v-else,
      @click.native="toggleDropdown"
      :id="`button-sidebar-${link.route}-link`"
      :to="{ name: link.route }")
        span.icon-body.tooltip-relative
          Icon(:name="link.icon")
          Tooltip.small(
          v-if="MenuMinimalize",
          white,
          right,
          :text="name")
        span(v-if="!MenuMinimalize") {{ name }}

    template(v-if="hasChild && !MenuMinimalize")
      ul(v-if="showChildren")
        li(
          v-for="(child,index) in link.children"
          :key="index")
          router-link.childLink(
            :id="`button-sidebar-${child.name}-link`"
            :to="{ name: child.name }")
            span {{ child.linkName }}
            svg.icon.after-icon(v-if="afterIcon(child)" :class="`icon-${afterIcon(child)}`")
              use(:xlink:href="`#icon-${afterIcon(child)}`")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SidebarLink',
  data () {
    return {
      showChildren: ''
    }
  },

  props: {
    link: {
      type: Object,
      default () {
        return {}
      }
    },
    salesWarning: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.showChildren = this.link.showChildren
  },
  computed: {
    ...mapGetters('Global', [
      'MenuMinimalize'
    ]),
    hasChild () {
      return this.link.role === 'TabLink'
    },
    afterIcon () {
      return (child) => {
        return this.getAfterIcon(child)
      }
    },
    hasActiveChild () {
      let hasActiveRoute = false
      this.link.children.forEach(x => {
        const path = this.$route.path.slice(0, x.path.length)
        if (path === x.path) {
          hasActiveRoute = true
        }
      })
      return hasActiveRoute
    },
    name () {
      return this.link.name
    }
  },
  methods: {
    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE']),
    toggleDropdown () {
      this.showChildren = !this.showChildren
      this.$parent.$refs.NavRoute.forEach(x => {
        if (x.link.name !== this.link.name) {
          x.showChildren = false
        }
      })
      if (this.MenuMinimalize) {
        this.TOGGLE_MENU_MINIMALIZE()
      }
    },
    getAfterIcon (item) {
      switch (item.name) {
        case 'MenuItems':
          return this.salesWarning ? 'row-status' : ''
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .after-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  .dropLink {
    &.combo-router-active {
      color: #fff;
      border-left: 3px solid $color-orange;
      background: $color-sidebar-dark;
    }
  }
</style>
