<template lang="pug">
.optional-fields.m-top-25
   Loading(v-if="isFetchingFields")

   .fields(v-else)
      template(
        v-for="(field, index) in fields"
      )

        // textbox
        template(v-if="field.columnType === 1")

          .Form-item(:class="{'required': field.isRequired }")
            label.Form-item-label {{ field.columnName }}
            .control
              input.txt(
              :name="`customInput${index}`"
              :id="`customInput${index}`"
              autocomplete="off"
              v-model="customMetaValues.find(item => item.customColumnMetaId === field.id).value"
              :class="{ 'is-danger': veeErrors.has(`customInput${index}`)}"
              :data-vv-as="field.columnName"
              v-validate="textboxValidate(field)"
              type="text")

              p.Form-item-help.is-danger(
                v-show="veeErrors.has(`customInput${index}`)"
              ) {{ veeErrors.first(`customInput${index}`) }}

        // checkbox
        template(v-else-if="field.columnType === 2")
          .Form-item
            CustomCheckbox.customFieldCheck(
              :id="`customInput${index}`",
              v-model="customMetaValues.find(item => item.customColumnMetaId === field.id).value",
              :label="field.columnName"
            )

        // dropdown
        template(v-else-if="field.columnType === 3")
          .Form-item(:class="{'required': field.isRequired }")
            label.Form-item-label {{ field.columnName }}
            .control.form-item-select
              Icon.icon-down-arrow(name="icon-down-arrow")
              select.select(
                :id="`customInput${index}`",
                :name="`customInput${index}`",
                v-validate="dropdownValidate(field)",
                v-model="customMetaValues.find(item => item.customColumnMetaId === field.id).dropdownMetaId",
                :class="{ 'is-danger': veeErrors.has(`customInput${index}`)}"
                :data-vv-as="field.columnName")

                option#custom-input-dropdown-option-unselected(:value="null") {{ $t('Global.Multiselect_Unselected_Text')}}
                option(
                  :id="`custom-input-dropdown-option-${field.columnName}`"
                  v-for="item in field.dropdownList"
                  :key="item.id"
                  :value="item.id"
                ) {{ item.value }}

            p.Form-item-help.is-danger(
              v-show="veeErrors.has(`customInput${index}`)"
            ) {{ veeErrors.first(`customInput${index}`) }}
</template>

<script>
import { mapActions } from 'vuex'

//

export default {
  name: 'CustomInput',
  props: {
    value: {},
    tableType: {
      type: Number,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fields: {},
      customMetaValues: []
    }
  },

  computed: {
    isFetchingFields () {
      return this.$wait.is(['fetchOptionsFieldByTableType', 'isSettingCustomInputsData'])
    },
    textboxValidate () {
      return (field) => {
        let validate = 'max:64'
        if (field.isRequired) {
          validate += '|required'
        }
        if (field.isUnique) {
          const url = this.isEdit ? `ismetavaluealreadyexist?RowId=${this.$route.params.id}&` : `ismetavaluealreadyexist?`
          validate += `|very_singularity:Meta/${url}TableType=${this.tableType},Value`
        }
        return validate
      }
    },
    dropdownValidate () {
      return (field) => {
        return field.isRequired ? `required:${field.isRequired}|not_in:-1` : ''
      }
    }
  },
  watch: {
    customMetaValues: {
      handler () {
        let values = this.customMetaValues.map(item => {
          const field = this.fields.find(_ => _.id === item.customColumnMetaId)
          const dropItem = item.columnType === 3 && field ? field.dropdownList.find(d => d.id === item.dropdownMetaId) : null
          const value = item.columnType === 3 ? (dropItem ? dropItem.value : null) : item.value
          return {
            ...item,
            value
          }
        })
        this.$emit('input', values)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('OptionalFields', ['fetchOptionsFieldByTableType']),
    createOptionalFormData () {
      this.customMetaValues = this.fields.map(item => {
        let value
        let dropdownMetaId
        switch (item.columnType) {
          case 1:
            value = ''
            dropdownMetaId = null
            break
          case 2:
            value = false
            dropdownMetaId = null
            break
          case 3:
            value = ''
            dropdownMetaId = null
            break
          default:
            break
        }
        return {
          customColumnMetaId: item.id,
          type: item.columnType,
          value,
          dropdownMetaId
        }
      })
    }
  },
  async mounted () {
    const { data } = await this.fetchOptionsFieldByTableType(this.tableType)
    this.fields = await data.metaFieldList
    if (this.isEdit) {
      this.$wait.start('isSettingCustomInputsData')
      const values = await this.value
      this.customMetaValues = await values.map(field => {
        const value = field.columnType === 2 ? field.value === 'true' : field.value
        return {
          ...field,
          value
        }
      })
      this.$wait.end('isSettingCustomInputsData')
    } else {
      await this.createOptionalFormData()
    }
  }
}
</script>

<style lang="scss" scoped>

  .customFieldCheck {
    justify-content: flex-start !important;
  }
</style>
