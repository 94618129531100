<template lang="pug">
.container.p-0
    .Boxlist
      .Boxlist-item.flexbox.direction-column(
        v-for="(row, rowIndex) in data"
        :class="showPassiveBox && !row.isActive ? 'passive': extraClass.forBox")
          .Boxlist-actions

            portal-target(name="action-calorie" class="action-calorie" :slot-props="row = setNewRow(row, rowIndex)")

            portal-target(name="action-buttons" class="action-buttons" :slot-props="row = setNewRow(row, rowIndex)")

            portal-target(name="box-header-info" class="box-header-info" :slot-props="row = setNewRow(row, rowIndex)")

            portalTarget(name="actionPortal-Box" :slot-props="row" v-if="usePortalAction")
            
            ActionButton(
              v-if="!usePortalAction"
              id="box-action-button"
              :data="actions"
              :item="row"
              @onItemEdit="onItemEdit(row)"
              @onItemDelete="onItemRemove(row)"
              @onItemCopy="onItemCopy(row)"
              @onItemChangeActiveStatus="onItemChangeActiveStatus(row)"
            )

          .box-header.flexbox(
            :class="showPassiveBox && !row.isActive ? 'passive-head' : extraClass.forHead"
          )

              portal-target(name="box-header-left" :slot-props="row = setNewRow(row, rowIndex)")
              div.title(
                :id="`box-list-${componentName}-title-${rowIndex}`"
              )
                p.matched-title-name-text.tooltip-relative(v-if="title.length" :id="`box-list-${componentName}-title-name-text-${rowIndex}`") {{ getName(row[title[0].name]) }}
                span.matched-property-name.tooltip-relative(v-if="subTitle.length" :id="`box-list-${componentName}-span-name-text-${rowIndex}`") {{ getName(getSelectionName(subTitle,row)) }}
                  Tooltip(
                    v-if="(subTitle[0].useCustomSubTitle ? getCustomSubTitle(row) : row[subTitle[0].name])?.length > 30"
                    white,
                    bottom,
                    :text="subTitle[0].useCustomSubTitle ? getCustomSubTitle(row) : row[subTitle[0].name]"
                  )
          BoxContent(
            :componentName="componentName"
            :fields="fields"
            :extraClass="extraClass.forContent"
            :data="row"
            :rowIndex="rowIndex"
          )
          Button.boxLink(
            :id="`box-list-${componentName}-row-${rowIndex}`"
            variant="full"
            @click="onClick(row, 'Show')",
          )
    template(v-if="page")
      BoxFoot(
        :componentName="componentName"
        :page="page",
        @delegateOnChangePage="onChangePage"
        @delegateOnChangePageSize="onChangePageSize"
      )
</template>
<script>
import BoxContent from './content'
import BoxFoot from '../table/foot'

export default {
  name: 'BoxView',

  components: {
    BoxContent,
    BoxFoot
  },

  props: {
    fields: {
      type: Array,
      default: []
    },

    showPassiveBox: {
      type: Boolean,
      default: false
    },

    extraClass: {
      type: Object,
      default () {
        return {
          forContent: '',
          forBox: '',
          forHead: ''
        }
      }
    },

    componentName: {
      type: String,
      default: 'component'
    },

    actions: {
      type: Array,
      default () {
        return []
      }
    },

    page: {
      type: Object
    },

    data: {
      type: Array,
      default: []
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    usePortalAction: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showActions: false
    }
  },

  computed: {
    title () {
      return this.fields.filter(field => !!field.headerTitle)
    },
    subTitle () {
      return this.fields.filter(field => !!field.headerSubTitle)
    }
  },

  methods: {
    setNewRow (row, index) {
      return {index: index, ...row}
    },
    onClick (item, emitName) {
      this.$emit('delegateOnItem' + emitName, item)
    },
    onItemEdit (item) {
      this.$emit('delegateOnItemEdit', item)
    },
    onItemRemove (item) {
      this.$emit('delegateOnItemRemove', item)
    },
    onItemCopy (item) {
      this.$emit('delegateOnItemcopy', item)
    },
    onItemChangeActiveStatus (item) {
      this.$emit('delegateOnItemChangeActiveStatus', item)
    },
    onHover (item, emitName) {
      this.$emit('delegateOnItemHover' + emitName, item)
    },
    onChangePage (page) {
      this.$emit('delegateOnChangePage', page)
    },
    onChangePageSize (pageSize) {
      this.$emit('delegateOnChangePageSize', pageSize)
    },
    showActionBar (item) {
      this.showActions = !this.showActions
    },

    closeActionBar (item) {
      this.showActions = false
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    shouldShow (item) {
      return (
        !this.readOnly &&
            (!this.actions.length || this.actions.indexOf(item) > -1)
      )
    },
    getCustomSubTitle (row) {
      let subTitleItem = this.fields.filter(field => !!field.headerSubTitle)
      let customSubTitle = row[subTitleItem[0].name] ? subTitleItem[0].customSubTitle : null
      return customSubTitle
    },
    getSelectionName (subTitle, row) {
      return subTitle[0].useCustomSubTitle ? this.getCustomSubTitle(row) : row[subTitle[0].name]
    },
    getName (item) {
      return item?.length > 30 ? item.substr(0, 30).concat('...') : item
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

  .matched-title-name-text{
    white-space: initial;
    max-width: 130px;
    word-break: break-word;
  }
  .boxLink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: transparent;
    cursor:default;
  }
  .title-abs{
    .title{
      position: absolute !important;
    }
  }
  .shadow-glow:hover{
    box-shadow: 0 2px 15px 0 rgba(38, 166, 253, 0.28) !important;
  }
  .Boxlist{

    &-item {
      padding: 0px;
      min-width: 273px;
    }

    &-item:hover > .box-header {
      padding-right: 60px;
    }
  }

    .box {
      min-width: 273px;
      height: 182px;
      border: 1px solid $color-gray;
      border-radius: $border-radius;

      &-header {
        min-height: 75px;
        padding: 15px 20px;
        width: 100%;
        background-color: $color-lighter-blue;
        font-weight: $font-weight-bold;
        color: $color-dark;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;

        & .matched-property-name{
          font-size: 14px;
          font-weight: normal;
        }
      }

      :deep()  &-content {
        width: 100%;
        padding: 15px 15px;
        font-size: $font-size-small;

        .box-row {
          width: 100%;
          display: grid;
          .title {
            font-size: 12px;
            color: $color-light;
            min-width: fit-content;
          }
          .value {
            margin-top: 5px;
            font-size: 14px;
            color: $color-dark;
          }
        }

      }
    }
    .passive {
      background-color: lighten($color-gray, 6%) !important;
      &-head {
      background: rgb(230, 229, 229) !important;
      }
    }
    :deep(.Boxlist-actions) {
      z-index: $z-index-sm !important;
    }

    :deep(.tooltip-relative) {
      z-index: $z-index-xs !important;
      position: relative;
    }
</style>
