<template lang="pug">
  businessMultiselect(
      componentName="multiselect-storeGroup-filter"
      :label="$t('Global.toolbar_storeGroups_Label')"
      :popupHeader="$t('Global.toolbar_storeGroups_PopupHeader')"
      :multiselectItems="isPermitted ? permittedStoreGroupList : storeGroupList"
      ref="businessStoreGroupFilter"
      @submitFilter="submitFilter"
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {

  props: {
    isPermitted: {
      type: Boolean,
      default: false
    }
  },

  components: {
    businessMultiselect
  },

  async created () {
    if (this.isPermitted) { await this.getPermittedStoreGroup() } else { await this.getStoreGroupList({pageNumber: 1, pageSize: 9999}) }
  },

  computed: {
    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),
    ...mapGetters('Settings', [
      'permittedStoreGroupList'
    ])
  },
  methods: {
    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),
    ...mapActions('Settings', [
      'getPermittedStoreGroup'
    ]),

    submitFilter ({list, dontSubmit}) {
      this.$emit('submitFilter', {list, dontSubmit})
    },

    clearStoreGroupFilter (dontSubmit = true) {
      this.$refs.businessStoreGroupFilter.clearAllFilter(dontSubmit)
    }

  }

}
</script>
