var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "optional-fields m-top-25" },
    [
      _vm.isFetchingFields
        ? _c("Loading")
        : _c(
            "div",
            { staticClass: "fields" },
            [
              _vm._l(_vm.fields, function (field, index) {
                return [
                  field.columnType === 1
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "Form-item",
                            class: { required: field.isRequired },
                          },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(_vm._s(field.columnName)),
                            ]),
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customMetaValues.find(
                                      (item) =>
                                        item.customColumnMetaId === field.id
                                    ).value,
                                    expression:
                                      "customMetaValues.find(item => item.customColumnMetaId === field.id).value",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.textboxValidate(field),
                                    expression: "textboxValidate(field)",
                                  },
                                ],
                                staticClass: "txt",
                                class: {
                                  "is-danger": _vm.veeErrors.has(
                                    `customInput${index}`
                                  ),
                                },
                                attrs: {
                                  name: `customInput${index}`,
                                  id: `customInput${index}`,
                                  autocomplete: "off",
                                  "data-vv-as": field.columnName,
                                  type: "text",
                                },
                                domProps: {
                                  value: _vm.customMetaValues.find(
                                    (item) =>
                                      item.customColumnMetaId === field.id
                                  ).value,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.customMetaValues.find(
                                        (item) =>
                                          item.customColumnMetaId === field.id
                                      ),
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has(
                                        `customInput${index}`
                                      ),
                                      expression:
                                        "veeErrors.has(`customInput${index}`)",
                                    },
                                  ],
                                  staticClass: "Form-item-help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.veeErrors.first(`customInput${index}`)
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : field.columnType === 2
                    ? [
                        _c(
                          "div",
                          { staticClass: "Form-item" },
                          [
                            _c("CustomCheckbox", {
                              staticClass: "customFieldCheck",
                              attrs: {
                                id: `customInput${index}`,
                                label: field.columnName,
                              },
                              model: {
                                value: _vm.customMetaValues.find(
                                  (item) => item.customColumnMetaId === field.id
                                ).value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customMetaValues.find(
                                      (item) =>
                                        item.customColumnMetaId === field.id
                                    ),
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "customMetaValues.find(item => item.customColumnMetaId === field.id).value",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : field.columnType === 3
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "Form-item",
                            class: { required: field.isRequired },
                          },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(_vm._s(field.columnName)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control form-item-select" },
                              [
                                _c("Icon", {
                                  staticClass: "icon-down-arrow",
                                  attrs: { name: "icon-down-arrow" },
                                }),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: _vm.dropdownValidate(field),
                                        expression: "dropdownValidate(field)",
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customMetaValues.find(
                                          (item) =>
                                            item.customColumnMetaId === field.id
                                        ).dropdownMetaId,
                                        expression:
                                          "customMetaValues.find(item => item.customColumnMetaId === field.id).dropdownMetaId",
                                      },
                                    ],
                                    staticClass: "select",
                                    class: {
                                      "is-danger": _vm.veeErrors.has(
                                        `customInput${index}`
                                      ),
                                    },
                                    attrs: {
                                      id: `customInput${index}`,
                                      name: `customInput${index}`,
                                      "data-vv-as": field.columnName,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.customMetaValues.find(
                                            (item) =>
                                              item.customColumnMetaId ===
                                              field.id
                                          ),
                                          "dropdownMetaId",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          id: "custom-input-dropdown-option-unselected",
                                        },
                                        domProps: { value: null },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Global.Multiselect_Unselected_Text"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(field.dropdownList, function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            id: `custom-input-dropdown-option-${field.columnName}`,
                                          },
                                          domProps: { value: item.id },
                                        },
                                        [_vm._v(_vm._s(item.value))]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.veeErrors.has(
                                      `customInput${index}`
                                    ),
                                    expression:
                                      "veeErrors.has(`customInput${index}`)",
                                  },
                                ],
                                staticClass: "Form-item-help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.veeErrors.first(`customInput${index}`)
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }