<template lang="pug">
.custom-multiselect-list
    .select.dropdown-handle-button(
      :class="{ 'has-values': hasValues && !disablePopup,'is-danger': isValid }"
      @keydown.enter="togglePopup"
      @keydown.prevent.space="togglePopup"
      @click="togglePopup"
      :aria-controls="uniquePopupId"
      :aria-expanded="isPopupVisible"
      tabindex="0")
        Icon.dropdown-handle-button(name="icon-down-arrow")
        .dropdown-handle-button-values
          | {{ dropdownLabel }}

    .popup(
    v-if="isPopupVisible && !disablePopup"
    :id="uniquePopupId"
    v-click-outside="hidePopup"
    v-shortkey="['esc']"
    @shortkey="hidePopup")

      .popup-content

        .MiniPopup-tr.popup-row(v-for="(item, index) in items")
          CustomCheckbox.popup-item-check(
          :id="`checkbox-multiselect-list-item-${id}-${index}`"
          :checked="selected.indexOf(item) > -1"
          :label="item.name"
          @change="isChecked => selectItem(item, isChecked)")
</template>

<script>


export default {
  name: 'CustomMultiselectList',

  props: {
    id: {
      type: String,
      required: true
    },

    items: {
      type: Array,
      default () {
        return []
      }
    },

    selected: {
      type: Array,
      default () {
        return []
      }
    },

    unselectedText: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    disablePopup: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isPopupVisible: false
    }
  },

  computed: {
    uniquePopupId () {
      const number = performance.now().toString().split('.').join('-')
      return `custom-multiselect-list-popup-${number}`
    },

    hasValues () {
      return this.selected.length > 0
    },

    dropdownLabel () {
      if (this.hasValues) {
        return this.selected.map(i => i.name).join(', ')
      } else {
        return this.unselectedText || this.$t('Global.Multiselect_Unselected_Text')
      }
    }
  },

  methods: {
    togglePopup () {
      this.isPopupVisible = !this.isPopupVisible
    },

    hidePopup () {
      this.isPopupVisible = false
    },

    selectItem (item, isChecked) {
      this.$emit('delegateCheckItem', { item, isChecked })
    }
  }

}
</script>

<style lang="scss" scoped>

.custom-multiselect-list {
  position: relative;

  .dropdown-handle {
    display: flex;
    align-items: center;
    font-size: $font-size-micro;
    user-select: none;

    &-button {
      position: relative;
      padding-right: 25px;
      height: 46px;
      border-radius: $border-high-radius;
      color: inherit;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;

      &:focus {
        border: 1px solid $color-gray;
        box-shadow: 0 0 5px $color-gray;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: calc(50%);
        transform: translateY(-50%);
        right: 10px;
        width: 20px;
      }

      &.has-values {
        color: $color-success;
      }
    }
  }

  .popup {
    width: 100%;
    box-shadow: 0 2px 15px 0 rgba($color-primary, 0.4);
    border-radius: $border-radius;
    background-color: #fff;
    position: absolute;
    z-index: $z-index-xs;

    &-content {
      position: relative;
      max-height: 290px;
      overflow: auto;
    }

    &-row {
      padding: 0;

      label {
        display: block;
        width: 100%;
        padding: 16px 10px;
      }
    }

    &-item-check {
      margin-right: 10px;
    }
  }
}

</style>
