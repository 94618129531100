var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-excel" },
    [
      _c(
        "Button",
        {
          staticClass: "import-excel-button m-right-15",
          attrs: {
            justIcon: true,
            useIconClass: false,
            isLoading: _vm.isLoading,
            disabled: _vm.isLoading || _vm.disabled,
            id: `import-excel${_vm.componentName}`,
          },
          on: { click: _vm.clickFile },
        },
        [
          _c("Icon", {
            class: !_vm.isTypeMassec ? "doc-type-icon" : "",
            attrs: { name: _vm.iconName },
          }),
          !_vm.isTypeMassec
            ? _c("Icon", {
                staticClass: "arrow-icon",
                attrs: {
                  name: _vm.typeImport ? "import-arrow" : "export-arrow",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("input", {
        ref: "file",
        staticClass: "import-excel-hidden",
        attrs: {
          id: "file",
          type: "file",
          accept: _vm.isEmitData ? "text/xml" : "all",
          multiple: _vm.isEmitData ? "multiple" : false,
        },
        on: { change: _vm.handleFileUpload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }