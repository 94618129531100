<template lang="pug">
.title-box(:class="{'active' : active}" :id="`title-box-${componentName}-${boxIndex}`" @click="onClick(data, 'View')")
    .title-box-top.tooltip-relative
      .title(:id="`title-list-${componentName}-${boxIndex}`")
        .icon-container
          Icon.mr-5(v-if="icon" :name="icon")
        p(:id="`title-${componentName}-${boxIndex}`") {{ getTitle(title) }}       
      Tooltip.m-top-25(
        v-if="title.length >= tooltipCharacterSize && showTooltip"
        :id="`title-tooltips-${componentName}`"
        white
        :text="title"
      )  
    .actions
      ActionButton(
        :data="actions"
        :item="data"
        @onItemDelete="delegateOnItemRemove(data)"
        @onItemEdit="delegateOnItemEdit(data)"
      )
    .title-box-bottom.m-top-10(v-if="description")
      .description
        p.box-description(:id="`description-${componentName}-${boxIndex}`") {{ description }}
</template>

<script>
export default {
  name: 'titlebox',
  props: {
    data: {
      type: Object
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    active: {
      type: Boolean,
      default: false
    },
    boxIndex: {
      type: Number,
      default: 1
    },
    componentName: {
      type: String,
      default: ''
    },
    isArrowIconVisible: {
      type: Boolean,
      default: true
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    tooltipCharacterSize: {
      type: Number,
      default: 25
    },
  },

  methods: {
    getTitle (title) {
      return title.length > this.tooltipCharacterSize && this.showTooltip ? title.substr(0, this.tooltipCharacterSize).concat('...') : title
    },

    onClick (item, emitName) {
      if(!this.shouldShow('view')) return
      else this.$emit('delegateOnItem' + emitName, item)
    },

    delegateOnItemRemove (item) {
      this.$emit('delegateOnItemRemove', item)
    },
    delegateOnItemEdit (item) {
      this.$emit('delegateOnItemEdit', item)
    },

    shouldShow (item) {
      return (!this.readOnly && (!this.actions.length || this.actions.indexOf(item) > -1))
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep() .appTooltip .text{
    max-width: 200px !important;
  }

  .active{
    border: solid 1px $color-light-blue !important;
    background-color: $color-ocean !important;
  }
  .title-box{
    width: 100%;
    min-height: 61px;
    border-radius: 3px;
    border: solid 1px $color-gray;
    background-color: #ffffff;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    position: relative;
    flex-wrap: wrap;
    &-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title{
        font-size: 16px;
        font-weight: bold;
        color: $color-light;
        display: flex;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .icon{
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .actions {
        opacity: 1;
        display: flex;
        &-span{
          z-index: $z-index-md;
        }
        .action-box{
          z-index: $z-index-xxl;
        }
      }
    }
    &-bottom {
      .description {
        .box-description{
          font-size: $font-size-small;
          font-weight: normal;
          color: $color-light
        }
      }
    }
    &:hover{
      border: solid 1px $color-light-blue;
      background-color: $color-ocean;
      cursor: pointer;
      .actions {
        opacity: 1;
        z-index: $z-index-md;
      }
      .title{
        color: $color-primary;
      }
    }
  }
  .icon-drink-bottle{
    margin-left: 4px;
    width: 11px !important;
  }
  .boxLink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: transparent;
    cursor:pointer;
  }
</style>
