<template lang="pug">
.notification
    button.notification-button(
      type="button"
      @click="changePopupStatus()"
    )
      Icon.notification-icon(name="icon-global-bell")

    notificationDetail(
      v-if="isPopupOpen"
      v-click-outside="hideNotificationPopup"
      :notiData="notiData"
      :isNotificationLoading="isNotificationLoading"
    )

</template>

<script>

import { mapActions } from 'vuex'
import notificationDetail from './details'

export default {
  name: 'notification',
  components: {
    notificationDetail
  },
  data () {
    return {
      isPopupOpen: false,
      notiData: [
        { title: 'title', desc: 'Lorem ipsum dolor', link: 'link' },
        { title: 'title', desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi blandit sollicitudin semper. Curabitur nec ultricies dui. Suspendisse in auctor est, sed gravida justo. Etiam placerat vulputate magna vel tristique. Praesent eget ligula vitae mauris mollis malesuada. Cras rutrum, quam at sollicitudin vestibulum, sapien elit gravida lorem, at blandit est diam id dolor. Maecenas consectetur, diam non faucibus ornare, dui nulla malesuada metus, vitae semper mi ex sit amet ex. Morbi dignissim massa et mauris porta suscipit. Duis aliquet erat nec sapien malesuada laoreet. Donec vel libero vehicula, finibus felis at, auctor neque. Aenean sed lectus in tellus faucibus rhoncus id sit amet tortor. Integer quis tempus lacus. Donec a ipsum ut risus euismod vestibulum ac vitae turpis.', link: 'link' },
        { title: 'title', desc: 'Ut egestas quam quis nisl aliquet, non dictum mauris fermentum. Phasellus in ante et sapien mattis vehicula. ', link: 'link' },
        { title: 'title', desc: 'Lorem ipsum dolor', link: 'link' },
        { title: 'title', desc: 'Maecenas molestie ante at diam elementum tristique. Aliquam vehicula ultrices volutpat. Etiam iaculis eros tristique vestibulum ornare. Maecenas dignissim non eros et aliquet. Maecenas pharetra ipsum ac ornare vestibulum.', link: 'link' },
      ]
    }
  },
  computed: {
    isNotificationLoading () {
      return this.$wait.is(['getRequestHeaderAll'])
    }
  },
  methods: {
    ...mapActions('Request', [
      'getRequestHeaderAll'
    ]),

    changePopupStatus () {
      this.isPopupOpen = !this.isPopupOpen
      if (this.isPopupOpen) this.getNotificationList()
    },

    hideNotificationPopup () {
      this.isPopupOpen = false
    },

    async getList (pageNumber) {
      await this.getNotificationList(pageNumber)
    },

    async getNotificationList (pageNumber = 1) {
      const payload = {
        pageNumber: pageNumber,
        pageSize: 20
      }
      this.getRequestHeaderAll(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

  .notification-button {
    background-color: $color-seashell;
    border-radius: 50%;
    width: $finger-size-small;
    height: $finger-size-small;
    color: $color-success;
    border: 2px solid;
  }

  .notification-icon {
    margin-bottom: 3px;
  }
</style>
