var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "zoom" } }, [
    _c("div", { staticClass: "Alert" }, [
      _c(
        "div",
        { staticClass: "Alert-body" },
        [
          _c(
            "figure",
            { staticClass: "Alert-icon" },
            [_c("Icon", { attrs: { name: _vm.icon } })],
            1
          ),
          _c(
            "h3",
            { staticClass: "Alert-title", attrs: { id: "alert-title" } },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "p",
            {
              staticClass: "Alert-description",
              attrs: { id: "alert-description" },
            },
            [
              _vm.tableName
                ? [_vm._v(_vm._s(_vm.tableName))]
                : [
                    _vm._v(
                      _vm._s(
                        _vm.description ||
                          _vm.$t("Global.deleteAlert_WillDelete_Description")
                      )
                    ),
                  ],
            ],
            2
          ),
          _vm.isGetDataLoading ? _c("Loading") : _vm._e(),
          _vm.fields.length > 0 || !_vm.isGetDataLoading
            ? _c("div", { staticClass: "Alert-item" }, [
                _c(
                  "table",
                  { staticClass: "Tableee-root", attrs: { id: "table-root" } },
                  [
                    _c(
                      "thead",
                      {
                        staticClass: "Tableee-head",
                        attrs: { id: "table-head" },
                      },
                      [
                        _c(
                          "tr",
                          { staticClass: "Tableee-row" },
                          _vm._l(_vm.fields, function (field, index) {
                            return field.is_visible
                              ? _c(
                                  "th",
                                  {
                                    key: field.name,
                                    staticClass: "Tableee-col",
                                    attrs: { id: `table-head-col-${index}` },
                                  },
                                  [_vm._v(_vm._s(field.short_title))]
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "tbody",
                      {
                        staticClass: "Tableee-body",
                        attrs: { id: "table-body" },
                      },
                      [
                        _c(
                          "tr",
                          { staticClass: "Tableee-row" },
                          _vm._l(_vm.fields, function (field, index) {
                            return field.is_visible
                              ? _c(
                                  "td",
                                  {
                                    key: field.name,
                                    staticClass: "Tableee-col",
                                    attrs: { id: `table-body-col-${index}` },
                                  },
                                  [
                                    field.is_currency
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.getFieldValue(field.name)
                                              )
                                            )
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getFieldValue(field.name)
                                            )
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "Alert-actions" },
            [
              _vm.hasCancelButton
                ? _c(
                    "Button",
                    {
                      ref: "focusedButton",
                      staticClass: "m-right-10",
                      attrs: {
                        secondary: "",
                        id: "btn-delete-alert-cancel",
                        type: "button",
                        size: "medium",
                        variant: "full",
                      },
                      on: { click: _vm.handleCancel },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.cancelButton ||
                              _vm.$t("Global.alert_CancelButton")
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "Button",
                {
                  staticClass: "m-left-10",
                  attrs: {
                    primary: "",
                    id: "btn-delete-alert-ok",
                    type: "button",
                    size: "medium",
                    variant: "full",
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.handleOk },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLoading,
                          expression: "!isLoading",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.okButton || _vm.$t("Global.alert_OkButton"))
                      ),
                    ]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }