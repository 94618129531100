<template lang="pug">
.total-counts(
    v-click-outside="closeTotalCountsDropdownStatus"
    )
    button.total-counts-button(
      id="button-total-counts-popup"
      @click="changeTotalCountsDropdownStatus"
    )
      template(v-if="isLoading")
        Loading
      template(v-else)
        span.total-counts-button-title {{ $t(title) }}
        Icon(name="icon-down-arrow")
    .total-counts-items(
      :class="{'show-total-counts': showTotalCountPopup}"
    )
      .item(v-for="item in totalData")
        .item-key {{ item.key }}
        template
          .item-value.ff-mono(v-if="item.type === 0") {{ item.value | formatCurrency2Digits }}
          .item-value.ff-mono(v-else) %{{ item.value | percentFixed }}
</template>

<script>

export default {
  name: 'totalCounts',
  props: {
    totalData: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showTotalCountPopup: false
    }
  },

  methods: {
    changeTotalCountsDropdownStatus () {
      this.showTotalCountPopup = !this.showTotalCountPopup
    },

    closeTotalCountsDropdownStatus () {
      this.showTotalCountPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>

  .total-counts {
    position: relative;
    background: $color-white;

    &-button {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 10px;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      outline: none;
      cursor: pointer;

      &-title {
        color: $color-dark;
        font-size: $font-size-micro;
        font-weight: 600;
        white-space: nowrap;
      }

      svg {
        margin-left: 10px;
        color: $color-success;
      }
    }

    &-items {
      display: none;
      position: absolute;
      margin-top: 10px;
      z-index: 3;
      font-size: $font-size-small;
      box-shadow: 0 0 10px rgba(black, 0.2);
      background-color: white;
      border: 1px solid $color-primary;
      border-radius: $border-radius;
      right: 0;

      &::before {
        content: '';
        position: absolute;
        width: $triangle-size;
        height: $triangle-size;
        border: solid $color-primary;
        background-color: #fff;
        border-width: 1px 0 0 1px;
        border-top-left-radius: $border-radius;
        transform: rotate(45deg);
        top: -$triangle-size / 2 - 1.5px; // -1.5px borderdan geliyor
        right: 20px;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 33.33%;
        padding: 20px 10px;
        border-right: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;

        &-key {
          color: $color-success;
          font-weight: $font-weight-bold;
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }

    .show-total-counts {
      display: flex;
      flex-wrap: wrap;
      min-width: 500px;
    }
  }
</style>
