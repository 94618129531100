<template lang="pug">
customSelect(
    v-model="selectedRouteName"
    selectedEmitValue="value"
    optionTitle="name"
    :optionData="linkList"
    :isDefaultTextActive = "false"
    @inputSelected = "getOptionValue"
  )
</template>

<script>
import customSelect from '@/view/global/custom-select'

export default {
  data () {
    return {
      linkList: [],
      selectedRouteName: null
    }
  },

  components: {
    customSelect
  },

  props: {
    selectedList: {
      type: Array,
      default () {
        return []
      }
    },
    translationGroup: {
      type: String,
      default: 'Global'
    },
    matchedRoute: {
      type: String,
      default: ''
    }
  },

  methods: {
    getOptionValue (option) {
      this.$router.push({ name: option.value })
    },

    getCurrentOption () {
      if (this.matchedRoute) return this.linkList.find(i => i.value === this.matchedRoute).value
      else return this.linkList.find(i => i.value === this.currentRouteName).value
    }
  },

  computed: {
    currentRouteName () {
      return this.$route.matched[0].name
    }
  },

  mounted () {
    this.linkList = this.selectedList.map(i => {
      return {
        name: this.$t(`${this.translationGroup}.breadcrumb_${i}`),
        value: i
      }
    })
    this.selectedRouteName = this.getCurrentOption()
  }
}
</script>
