var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c("div", { staticClass: "Popup" }, [
      _c("div", { staticClass: "Popup-overlay", on: { click: _vm.close } }),
      _c("div", { staticClass: "Popup-body" }, [
        _c(
          "div",
          { staticClass: "Popup-header" },
          [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "h3",
                  { staticClass: "Popup-header-title word-break-word" },
                  [_vm._t("title")],
                  2
                ),
                _vm._t("detail"),
              ],
              2
            ),
            _c("Button", {
              staticClass: "Popup-header-close",
              attrs: {
                disabled: _vm.disableCloseButton,
                id: "btn-popup-close",
                variant: "icon",
                type: "button",
                iconName: "icon-popup-close",
                iconClass: "icon-popup-close",
              },
              on: { click: _vm.close },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "Popup-content" }, [_vm._t("content")], 2),
        _c("div", { staticClass: "Popup-footer" }, [_vm._t("footer")], 2),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }