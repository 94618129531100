<template lang="pug">
.popup-container.m-right-20(
    v-click-outside="hideReasonPopup"
  )
    Button(
      :id="`${componentName}-reason-show`"
      type="button"
      size="small"
      danger
      :disabled="isButtonDisable"
      @click="showReasonPopup") {{ buttonText }}
    form.description-right-MiniPopup(
      v-if="showReasonButtonPopup"
      :id="`${componentName}-reason-form`"
      @submit.prevent="onSubmit"
    )
      label.description-MiniPopup-label.required(
        :id="`${componentName}-reason-label`"
      ) {{ descText }}
      textarea.txt.description-MiniPopup-input(
        ref="reason"
        name="reason"
        autocomplete="off"
        v-validate="{required: true, max: 128}"
        v-model="reasonDescription"
        :class="{ 'is-danger': veeErrors.has(`reason`) }"
        :id="`${componentName}-reason-input`"
      )
      Button.m-top-15(
        type="submit"
        primary
        size="medium"
        variant="full"
        :disabled="isDisable"
        :id="`${componentName}-reason-submit`"
      )
        span {{ agreeButtonText }}
</template>

<script>

export default {
  name: 'mini-popup-button',
  props: {
    data: {
      type: Object,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    descText: {
      type: String,
      required: true
    },
    isButtonDisable: {
      type: Boolean,
      default: false
    },
    agreeButtonText: {
      type: String,
      required: true
    },
    componentName: {
      type: String,
      default: 'description'
    },
    isDisable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showReasonButtonPopup: false,
      reasonDescription: null
    }
  },
  methods: {
    showReasonPopup () {
      this.showReasonButtonPopup = !this.showReasonButtonPopup
    },
    hideReasonPopup () {
      this.showReasonButtonPopup = false
    },
    onSubmit () {
      this.$validator.validateAll().then(async result => {
        if (!result) return
        const submitData = {
          ...this.data,
          reasonDescription: this.reasonDescription
        }
        this.$emit('onSubmit', submitData)
      })
    }
  }
}
</script>

<style scoped lang="scss">

  @import "~@/stylesheet/config/mixin";
  .popup-container {
    .btn {
      &.passive {
        @include GhostButton($color: $color-warning, $amount: 30);
      }
      &:disabled {
        border: 0;
      }
    }
    .description-right-MiniPopup {
      top: auto!important;
      right: calc(10% + 30px) !important;
      @include MiniPopupMixin(
        $width: 296px,
        $margin: 30px -10px 0 0,
        $triangle-direction: 'right',
        $border-color: $color-light-blue,
        $padding-hor: 25px,
      );
    }
  }
</style>
