<template lang="pug">
.Loading
    img(:src="loading")
</template>

<script>
import loadingActive from '@/view/global/loading/assets/active.gif'
import loadingDisable from '@/view/global/loading/assets/disable.gif'

export default {
  name: 'Loading',
  props: {
    theme: {
      type: String,
      default: 'active'
    }
  },
  computed: {
    loading () {
      if (this.theme === 'disable') return loadingDisable
      return loadingActive
    }
  }
}
</script>

<style lang="scss">

  .Loading {
    img {
      width: 26px;
    }
  }
</style>
