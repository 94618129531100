var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Container" },
    [
      _c("div", { staticClass: "page-info", on: { click: _vm.getInfo } }, [
        _c("div", { staticClass: "page-info-title" }, [
          _vm._v(_vm._s(_vm.$t("Page.newNotification")) + " > "),
        ]),
        _c("div", { staticClass: "page-info-content m-left-5" }, [
          _vm._v(_vm._s(_vm.$t("Page.notificationText"))),
        ]),
      ]),
      _vm.hasHeaderSlots
        ? _c("header", { staticClass: "Header" }, [
            _c("div", { staticClass: "header-left" }, [
              _c(
                "div",
                { staticClass: "header-title-breadcrumb" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title m-right-10",
                      style: { color: _vm.titleColorSelection },
                    },
                    [
                      _vm.isLoadingLang
                        ? _c("Loading")
                        : _c(
                            "div",
                            [
                              !_vm.hasDashboardSlots
                                ? _c("p", [_vm._v(_vm._s(_vm.pageTitle))])
                                : _vm._e(),
                              _vm._t("header-title"),
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                  _c("Breadcrumb", { staticClass: "breadcrumb" }),
                  _vm._t("header-breadcrumb-left"),
                ],
                2
              ),
              _vm.hasHeaderLeftDropdownSlots
                ? _c(
                    "div",
                    { staticClass: "header-left-dropdown" },
                    [_vm._t("header-left-dropdown")],
                    2
                  )
                : _vm._e(),
              _vm.hasHeaderLeftSearchSlots
                ? _c(
                    "div",
                    { staticClass: "header-left-search" },
                    [_vm._t("header-left-search")],
                    2
                  )
                : _vm._e(),
            ]),
            _vm.hasHeaderCenterSlots
              ? _c(
                  "div",
                  { staticClass: "header-center" },
                  [_vm._t("header-center")],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "header-right" }, [
              _vm.hasHeaderRightSearchSlots
                ? _c(
                    "div",
                    { staticClass: "header-right-search" },
                    [_vm._t("header-right-search")],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "header-right-dropdown" },
                [_vm._t("header-right-dropdown")],
                2
              ),
              _c(
                "div",
                { staticClass: "header-right-content" },
                [_vm._t("header-right-content")],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm.$slots["overgroups-left"] || _vm.$slots["overgroups-right"]
        ? _c("Nav", { staticClass: "Overgroups" }, [
            _c(
              "div",
              { staticClass: "overgroups-left" },
              [_vm._t("overgroups-left")],
              2
            ),
            _c(
              "div",
              { staticClass: "overgroups-right" },
              [_vm._t("overgroups-right")],
              2
            ),
          ])
        : _vm._e(),
      _vm.$slots["toolbar-left"] || _vm.$slots["toolbar-right"]
        ? _c("Nav", { staticClass: "toolbar" }, [
            _c(
              "div",
              { staticClass: "toolbar-left" },
              [_vm.$slots["toolbar-left"] ? _vm._t("toolbar-left") : _vm._e()],
              2
            ),
            _c(
              "div",
              { staticClass: "toolbar-right" },
              [
                _vm.$slots["toolbar-right"]
                  ? _vm._t("toolbar-right")
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c("Article", { staticClass: "Content" }, [
        _vm.$slots["content-left"]
          ? _c(
              "div",
              { staticClass: "content-left" },
              [_vm._t("content-left")],
              2
            )
          : _vm._e(),
        _vm.$slots["content-center"]
          ? _c(
              "div",
              { staticClass: "content-center" },
              [_vm._t("content-center")],
              2
            )
          : _vm._e(),
        _vm.$slots["content-right"]
          ? _c(
              "div",
              { staticClass: "content-right" },
              [_vm._t("content-right")],
              2
            )
          : _vm._e(),
      ]),
      _vm.hasNoContentSlots
        ? _c("Article", { staticClass: "No-Content" }, [
            _c(
              "div",
              { staticClass: "no-content", attrs: { name: "no-content" } },
              [_vm._t("no-content")],
              2
            ),
          ])
        : _vm._e(),
      _vm.hasFooterSlots
        ? _c("Footer", { staticClass: "Footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }