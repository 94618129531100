var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("customSelect", {
    attrs: {
      selectedEmitValue: "value",
      optionTitle: "name",
      optionData: _vm.linkList,
      isDefaultTextActive: false,
    },
    on: { inputSelected: _vm.getOptionValue },
    model: {
      value: _vm.selectedRouteName,
      callback: function ($$v) {
        _vm.selectedRouteName = $$v
      },
      expression: "selectedRouteName",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }