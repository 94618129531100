<template lang="pug">
Popover(
    toggle-outline,
    toggleIcon="icon-down-arrow"
    popover-variants="primary"
    ref="filterPopover"
    :toggle-variant=" isApplyFilter ? 'green btn-xs' : 'border-gray btn-xs c-success'",
    position="left")

      template(slot="label")
        span(v-if="isApplyFilter") {{ $t('Global.appliedFilter') }}
        span(v-else) {{ $t('Global.GlobalFilterTitle') }}

      template(slot="content")
        .filterPopoverContent
          .filterItems.checkFilter
            template(
              v-for="checkList in filterCheckList"
            )
              checkFilter.filterItems_item(
                :id="checkList.id"
                :title="checkList.name",
                :filterList="checkList.list",
                @input="emptyControl"
                v-model="values.filterCheckList[checkList.type]"
                :filterName="checkList.type"
                @onChangeAnyList="(name, selectedList) => $emit('onChangeAnyList', name, selectedList)"
              )
          .filterItems
            template(v-for="toggleList in filterToggleList")
              toggleFilter(
                :id="toggleList.id"
                :title="toggleList.name",
                :list="toggleList.list",
                v-model="values.filterToggleList[toggleList.type]")

      template(slot="footer")
        .actionFooter
          Button(
            id="btn-global-filter-apply"
            primary,
            size="medium",
            :disabled="isEmptyCheckList",
            iconName="icon-btn-success",
            @click="sendFilter"
          )
            span {{ $t('Global.GlobalFilterApplyButton') }}
</template>

<script>

import checkFilter from './checkFilter'
import toggleFilter from './toggleFilter'

export default {
  name: 'GeneralFilter',
  props: {
    filterCheckList: {
      type: Array,
      default () {
        return []
      }
    },
    filterToggleList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isApplyFilter: false,
      isEmptyCheckList: false
    }
  },
  components: {
    checkFilter,
    toggleFilter
  },
  computed: {
    values () {
      let formTypes = { 'filterCheckList': [], 'filterToggleList': [] }
      Object.keys(formTypes).forEach(fType => {
        let values = {}
        this[fType].forEach(item => {
          values[item.type] = null
        })
        formTypes[fType] = { ...values }
      })
      return formTypes
    }
  },
  methods: {
    emptyControl () {
      this.$nextTick(() => {
        let emptyItemCount = 0
        Object.keys(this.values.filterCheckList)
          .forEach(item => {
            if (this.values.filterCheckList[item] && this.values.filterCheckList[item].length === 0) {
              emptyItemCount += 1
            }
          })

        if (emptyItemCount > 0) {
          this.isEmptyCheckList = true
        } else {
          this.isEmptyCheckList = false
        }
      })
    },
    isChangedFilter () {
      let changedFilterCount = 0
      Object.keys(this.values.filterCheckList).forEach(filter => {
        const itemlist = this.filterCheckList.find(f => f.type === filter)
        changedFilterCount = this.values.filterCheckList[filter].length !== itemlist.list.length ? changedFilterCount + 1 : changedFilterCount
      })
      Object.keys(this.values.filterToggleList).forEach(toggleFilter => {
        const toggleItem = this.filterToggleList.find(t => t.type === toggleFilter)
        changedFilterCount = toggleItem.list[0].value === this.values.filterToggleList[toggleFilter] ? changedFilterCount : changedFilterCount + 1
      })
      this.isApplyFilter = changedFilterCount > 0
    },
    sendFilter () {
      let filters = {}
      Object.keys(this.values).forEach(filter => {
        filters = Object.assign(filters, this.values[filter])
      })
      this.$emit('filterSubmit', filters)
      // this.isChangedFilter()
      this.$refs.filterPopover.hide()
    }
  }
}
</script>

<style lang="scss">

  .filterPopoverContent {
    height: 529px;
    overflow-y: auto;
    overflow-x: hidden;
     &::-webkit-scrollbar {
      border: 6px solid rgba(#000, .11);
      width: 6px;
      border-radius: $border-radius;
    }
  }
  .filterItems {
    width: max-content;
    position: relative;
    &.checkFilter {
      display: flex;
      border-bottom: 1px solid $color-gray;
      .filterItems_item {
        width: 100%;
        min-width: 276px;
        height: 296px;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          border: 1px solid $color-gray;
          width: 1px;
        }
        &:hover {
          .filterItems_item::-webkit-scrollbar {
            display: block;
          }
        }
      }
    }
  }
  .actionFooter {
    padding: 20px;
    border-top: 1px solid $color-gray;
    position: relative;
    &::before {
      content: ".";
      display: flex;
      color: transparent;
      position: absolute;
      top: -41px;
      height: 40px;
      width: calc(100% - 40px);
      left: 20px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ffffff);
    }
  }
</style>
