<template lang="pug">
.OverGroups
  span.OverGroups-item(
    v-for="(item, index) in list"
    :id="`custom-toggle-group-${index}`"
    :class="[(selectedItem === item) || ( selectedItem && selectedItem[selectedKey] === item[selectedKey]) ? 'active' : '', useUpperCase ? 'upper-case' : '', isNotActiveDisabled ? 'pointer-none' : '', size]"
    @click="onChangeSelectedItem(item)") {{ item[fieldKey] }}
</template>

<script>

export default {
  name: 'ToggleStoreGroup',

  props: {
    list: {
      type: Array,
      required: true
    },
    selectedItem: {
      type: Object | null,
      required: true
    },
    fieldKey: {
      type: String,
      default: 'name'
    },
    selectedKey: {
      type: String,
      default: 'id'
    },
    useUpperCase: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'large'
    },
    isNotActiveDisabled: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onChangeSelectedItem (item) {
      if (!this.isNotActiveDisabled || (item !== this.selectedItem)) {
        this.$emit('delegateChangeSelectedItem', item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

   .OverGroups {
    display: flex;
    flex-wrap: wrap;
    &-item {
      padding: 10px 16px;
      font-weight: $font-weight-normal;
      margin-right: 10px;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      user-select: none;
      background: $color-white;
      &.active {
        color: $color-primary;
        font-weight: $font-weight-bold;
        border-color: $color-primary;
        &.pointer-none {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    .large {
      font-size: $font-size-small;
    }

    .small {
      font-size: $font-size-micro;
    }

    .upper-case {
      text-transform: uppercase;
    }
  }
</style>
