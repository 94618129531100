<template lang="pug">
.tab
    router-link(
      v-for="link in links"
      :key="link",
      :class="{'isSelected': isSelected(link), 'disabled': disabledValues.find(l => l === link)}",
      :id="`link-${translationGroup}-tab-header-${link}`",
      :to="{ name: link }")
        slot(name="linkExtra", :linkName="link")
        span {{ $t(`${translationGroup}.breadcrumb_${link}`) }}

</template>

<script>
/*
*
*
*
*
*/
export default {
  name: 'PageHeaderTabs',
  props: {
    links: {
      type: Array,
      required: true
    },
    disabledValues: {
      type: Array,
      default: () => []
    },
    matchedRouter: {
      type: String,
      default: null
    },
    translationGroup: {
      type: String,
      default: 'Global'
    }
  },
  methods: {
    isSelected (link) {
      return !this.matchedRouter ? this.$route.matched[0].name === link : this.matchedRouter === link
    }
  }
}
</script>

<style lang="scss" scoped>

  .tab {
    display: flex;
    a {
      border-radius: 3px;
      margin-right: 10px;
      color: $color-dark;
      min-height: 44px;
      padding: 10px 20px;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
      border-width: 1px 1px 0 1px;
      border: solid 1px $color-gray;
      background-color: $color-white;
      line-height: normal;
      &.isSelected {
        background-color: $color-primary;
        pointer-events: none;
        color: $color-white;
      }
      &.disabled {
        pointer-events: none;
        opacity: .5;
      }
    }
  }
</style>
