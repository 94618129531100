<template lang="pug">
.ReqireStep
    .inner-container
      Icon.icon-class(
        :name   ="iconName",
        v-if    ="iconName",
      )
      h3.title(id="require-step-title") {{ title }}
      p.description(id="require-step-description") {{ description }}
</template>

<script>

export default {
  name: 'ReqireStep',
  props: {
    iconName: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  }
}

</script>

<style lang="scss" scoped>

  .ReqireStep {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      min-height: 450px;

      .icon-class {
        margin-bottom: 30px;
        width: 201px;
        height: 240px;
      }

      .title{
        font-size: 22px;
      }

      .description {
        margin-top: 10px;
        margin-bottom: 20px;
        color: $color-light;
      }
      .inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .btn {
        width: auto;
        height: $input-size;
        align-self: center;
      }
  }
</style>
