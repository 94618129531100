var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "App" },
    [
      _c("ErrorTemplate"),
      _c("Icons"),
      _c("Notify"),
      _c("div", { staticClass: "Auth" }, [
        _c("div", { staticClass: "Auth-cover" }, [
          _c("div", { staticClass: "Auth-cover-body" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "Auth-cover-welcome" },
              [_c("Icon", { attrs: { name: "simpra-text" } })],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "Auth-body" }, [
          _c("div", { staticClass: "Auth-inner" }, [_c("router-view")], 1),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Auth-cover-logo" }, [
      _c("img", { attrs: { src: require("./assets/logo.svg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }