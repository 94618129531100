<template lang="pug">
businessMultiselect(
    componentName='recipe-groups'
    :label="$t('Global.toolbar_RecipeGroups_Label')"
    :popupHeader="$t('Global.toolbar_RecipeGroups_PopupHeader')"
    :customSelectedData="customSelectedData"
    :multiselectItems="recipeGroupList"
    @submitFilter="submitFilter"
    ref="businessRecipeGroupsFilter"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {

  components: {
    businessMultiselect
  },

  props: {
    customSelectedData: {
      type: Array,
      default: () => []
    }
  },

  async beforeMount () {
    await this.getPermittedRecipeGroups()
  },

  computed: {
    ...mapGetters('RecipeGroups', {
      recipeGroupList: 'permittedRecipeGroups'
    })
  },

  methods: {
    ...mapActions('RecipeGroups', [
      'getPermittedRecipeGroups'
    ]),

    submitFilter ({ list, dontSubmit }) {
      this.$emit('submitFilter', {list, dontSubmit})
    },

    clearRecipeGroupsFilter (dontSubmit = true) {
      this.$refs.businessRecipeGroupsFilter.clearAllFilter(dontSubmit)
    }
  }
}
</script>
