<template lang="pug">

  div.downloadField
    Button.report-download-button(
      :size="size"
      :justIcon="true"
      v-on="$listeners"
      v-bind="$attrs"
      :useIconClass="false"
      :isLoading="isLoading"
      :disabled="isLoading || disabled"
      :class="classes"
    )
      Icon.file-icon(
        :name="iconName"
      )

      span(v-if="textButton") {{ textButton }}

      Icon.arrow-icon(
        v-show="!useCustomButton"
        name="export-arrow"
      )

    a.display-none-download-link(
      :download="downloadFileName"
      ref="downloadButton"
    )
</template>

<script>
export default {
  name: 'PureDownloadButton',
  props: {
    iconName: {
      type: String,
      required: true
    },
    useCustomButton: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    downloadFileName: {
      type: String,
      required: true
    },
    textButton: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    }
  }
}
</script>

<style lang="scss" scoped>

  .downloadField {
    display: flex;
    align-items: center;
  }
  .report-download-button {
    width: 54px;
    box-shadow: 0px 1px 1px 1px $color-gray;
    position: relative;
    padding: 0;
    .file-icon {
      width: 20px;
      height: 20px;

    }
    .arrow-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      top: 0;
    }

   &:disabled {
    opacity: .5
   }
   a.display-none-download-link {
    display: none
   }
  }

  .report-normal-button {
    min-width: 44px;
    width: 100%;
    padding: 10px 15px;

    span {
      white-space: nowrap;
      margin-left: 5px;
    }
  }
</style>
