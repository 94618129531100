var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toggleList" },
    [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("SelectedButtons", {
        attrs: { type: "tab", id: _vm.id, data: _vm.list, size: "xsm" },
        model: {
          value: _vm.selectedItem,
          callback: function ($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }