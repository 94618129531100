var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "selectedButton", class: [_vm.type] },
    _vm._l(_vm.data, function (input, index) {
      return _c(
        "button",
        {
          class: { selected: _vm.value === input.value },
          size: _vm.size,
          attrs: { id: `selected-btn-${_vm.id}-${index}` },
          on: {
            click: function ($event) {
              return _vm.selectItem(input.value)
            },
          },
        },
        [_vm._v(_vm._s(input.text))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }