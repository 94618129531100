var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "Tableee-container",
      class: {
        "Tableee-scroll":
          _vm.fields.find((i) => i.fixedColumnHeader) ||
          _vm.addScrollToNoFixedColumns,
      },
    },
    [
      _vm.useInfiniteScroll && _vm.isLoadingInfiniteScrollRequest
        ? _c("VehicleLoading", { staticClass: "loading", attrs: { size: 250 } })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.handleScroll,
              expression: "handleScroll",
            },
          ],
          staticClass: "Tableee-main",
          class: {
            fixed: !!_vm.fields.filter((i) => i.fixedColumnHeader),
            "bg-shadow": _vm.isLoadingInfiniteScrollRequest,
          },
        },
        [
          _c(
            "table",
            { staticClass: "Tableee-root" },
            [
              _c("TableHead", {
                attrs: {
                  componentName: _vm.componentName,
                  fields: _vm.fields,
                  readOnly: _vm.readOnly,
                  usePortalAction: _vm.usePortalAction,
                  selectableAll: _vm.selectableAll,
                  isCheckedAll: _vm.isChecked,
                  infoData: _vm.infoData,
                  selectedRows: _vm.selectedRows,
                  data: _vm.data,
                },
                on: {
                  showAllSelectedItems: _vm.allItemChecked,
                  sort: (field) => _vm.$emit("sort", field),
                  openFilterPopup: (field) =>
                    _vm.$emit("openFilterPopup", field),
                  changePopupFilterSearch: _vm.changePopupFilterSearch,
                  submitFilter: (field) => _vm.$emit("submitFilter", field),
                  clearFilter: (field) => _vm.$emit("clearFilter", field),
                },
              }),
              _c("TableBody", {
                attrs: {
                  componentName: _vm.componentName,
                  fields: _vm.fields,
                  data: _vm.data,
                  pointer: _vm.pointer,
                  readOnly: _vm.readOnly,
                  actions: _vm.actions,
                  selectable: _vm.selectable,
                  selectedRows: _vm.selectedRows,
                  showCheckboxForPassives: _vm.showCheckboxForPassives,
                  usePortalAction: _vm.usePortalAction,
                  uniqueIdentifier: _vm.uniqueIdentifier,
                },
                on: {
                  delegateOnItemCopy: _vm.onItemCopy,
                  delegateOnItemEdit: _vm.onItemEdit,
                  delegateOnItemDelete: _vm.onItemDelete,
                  onItemChangeActiveStatus: _vm.onItemChangeActiveStatus,
                  delegateOnItemShow: _vm.onItemShow,
                  delegateOnItemShowCustom: (row) =>
                    _vm.$emit("delegateOnItemShowCustom", [row]),
                  delegateOnItemOverview: _vm.onItemOverview,
                  delegateOnItemSelect: _vm.onItemSelect,
                  delegateOnItemExport: _vm.onItemExport,
                },
              }),
            ],
            1
          ),
          _vm.isEmpty
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.emptyStateTitle,
                  description: _vm.emptyStateDesc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.page
        ? [
            !_vm.useInfiniteScroll
              ? _c("TableFoot", {
                  attrs: {
                    componentName: _vm.componentName,
                    page: _vm.page,
                    footNote: _vm.footNote,
                  },
                  on: {
                    delegateOnChangePage: _vm.onChangePage,
                    delegateOnChangePageSize: _vm.onPageSizeChange,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }