var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notification-menu right" },
    [
      _vm.isNotificationLoading
        ? _c("TableLoading")
        : _c("div", { staticClass: "notification-menu-container" }, [
            _c("div", { staticClass: "notification-menu-header" }, [
              _c("span", [_vm._v("Bildirimler")]),
            ]),
            _c(
              "div",
              { ref: "notBodyTop", staticClass: "notification-menu-body" },
              _vm._l(_vm.notiData, function (data) {
                return _c(
                  "div",
                  { staticClass: "notification-menu-body-list m-top-15" },
                  [
                    _c(
                      "div",
                      { staticClass: "notification-menu-body-title m-top-5" },
                      [
                        _c(
                          "h2",
                          {
                            attrs: { id: `notifiyDetail-title-${data.title}` },
                          },
                          [_vm._v(_vm._s(data.title))]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "notification-menu-body-desc m-top-5" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "notification-desc",
                            attrs: { id: `notifiyDetail-desc-${data.desc}` },
                          },
                          [_vm._v(_vm._s(data.desc))]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "notification-menu-body-link m-top-5" },
                      [
                        _c(
                          "span",
                          { attrs: { id: `notifiyDetail-link-${data.link}` } },
                          [_vm._v(_vm._s(data.link))]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }