var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Logo" },
    [
      _c(
        "router-link",
        {
          staticClass: "Logo-link",
          class: { "Mini-link": _vm.MenuMinimalize },
          attrs: { to: "/" },
        },
        [
          _vm.MenuMinimalize
            ? _c("Icon", { attrs: { name: "simpra-logo" } })
            : _c("img", { attrs: { src: require("./assets/logo.svg") } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }