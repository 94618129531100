<template lang="pug">
transition(name="fadeIn")
    .Alert(v-show="showAlert")
      transition(name="slideUp" mode="in-out")
        .content
          .Alert-overlay(@click="close")

          .Alert-body
            .Alert-body-icon
              Icon.icon-alert-warning(name="icon-global-warning")

            .Alert-body-text
              h3.title {{ alert.title }}
              p.description {{ alert.message }}

            .Alert-body-action
              Button.uppercase(
                :variant="cancelButton.style",
                @click="close"
              ) {{ cancelButtonText }}

              Button.uppercase(
                id="alert-body-action-confirm-button"
                v-if="confirmButton",
                :variant="confirmButton.style",
                :isLoading="confirmButtonIsRunAction",
                :disabled="confirmButtonIsRunAction || confirmButton.disabled",
                @click="confirmAction"
              ) {{ confirmButton.text }}

</template>

<script>
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'Alertbox',
  data () {
    return {
      alert: {
        title: null,
        message: null,
        cancelButton: {
          text: null,
          style: 'lg btn-outline-light',
          action: null
        },
        confirmButton: {
          text: null,
          style: 'lg btn-success',
          disabled: false,
          action: null
        }
      }
    }
  },
  computed: {
    showAlert () {
      return !!this.alert.title || !!this.alert.message
    },
    confirmButtonIsRunAction () {
      return this.$wait.is('isConfirmRunAction')
    },
    cancelButtonText () {
      return this.alert.cancelButton.text || this.$t('Global.deleteAlert_CancelButton')
    },
    cancelButton () {
      return this.alert.cancelButton
    },
    confirmButton () {
      return this.alert.confirmButton
    }
  },
  methods: {
    open (obj = {}) {
      this.alert.title = obj.title
      this.alert.message = obj.message

      if (obj.cancel) {
        this.alert.cancelButton.text = obj.cancel.text
        this.alert.cancelButton.style = obj.cancel.style || this.cancelButton.style
        this.alert.cancelButton.action = obj.cancel.action || null
      }

      if (obj.confirm) {
        this.alert.confirmButton.text = obj.confirm.text || this.$t('Global.deleteAlert_OKButton')
        this.alert.confirmButton.action = obj.confirm.action || null
        this.alert.confirmButton.disabled = obj.confirm.disabled || false
      }
    },

    reset () {
      this.alert.title = null
      this.alert.message = null
      this.alert.confirmButton = {
        text: null,
        style: 'lg btn-success',
        isActionRun: false,
        disabled: false,
        action: null
      }
      this.alert.cancelButton = {
        text: null,
        style: 'lg btn-outline-light',
        action: null
      }
    },
    close () {
      if (typeof this.cancelButton.action === 'function') {
        this.cancelButton.action()
      }
      this.reset()
    },
    confirmAction: vueWaitLoader(async function () {
      if (typeof this.confirmButton.action === 'function') {
        await this.confirmButton.action(this.close)
      }
    }, 'isConfirmRunAction')
  }
}
</script>

<style lang="scss" scoped>

 .Alert {
    &, &-overlay {
      width: 100vw;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: $z-index-xxxl;
    }
    &-overlay {
      background-color: #000;
      opacity: 0.5;
    }
    &-body {
      background: $color-white;
      min-height: 368px;
      width: 100%;
      max-width: 704px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: $z-index-xxxl;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: $border-radius;
      padding: 10px;
      &-icon {
        margin-bottom: 28px;
        .icon-alert-warning {
          width: 55px;
          height: 55px;
        }
      }
      &-text {
        text-align: center;
        .title {
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 5px;
          line-height: normal;
        }
        .description {
          font-size: 22px;
          color: $color-text-light;
        }
      }
      &-action {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .btn {
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
 }
</style>
