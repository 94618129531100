<template lang="pug">
textarea.txt(
    ref="customTextareaField"
    autocomplete="off"
    :class="{ 'is-danger': error }"
    :value="value"
    rows="5"
    @input.stop="submitText($event)"
  )
</template>

<script>
export default {
  name: 'custom-textarea-input',
  props: {
    value: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // Bu fonksiyon proje içerisinde referans ile çağrılıyor.
    focusCustomTextareaInput () {
      this.$refs.customTextareaField.focus()
    },
    submitText (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>