<template lang="pug">
transition(name="fade" v-if="error")
    .Alert
      .Alert-body
        figure.Alert-icon
          Icon(name="icon-global-warning")

        h3.Alert-title {{ title }}
        p.Alert-description(v-html="errorMessage")

        .Alert-item(v-if="errorFields.length > 0")
          TableView.Alert-table-view(
            :fields="errorFields"
            :data="errorData"
            :readOnly="true"
          )
        .Alert-item
          Button(
          primary
          id="btn-alert-ok"
          size="medium"
          @click="closeTemplate"
          type="button")
           | {{ $t('Global.global_okey') }}
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ErrorTemplate',
  computed: {
    ...mapGetters('Notify', ['error', 'errorMessage', 'errorData', 'errorFields']),
    title () {
      return this.$t('Global.global_hasError')
    }
  },
  methods: {
    ...mapActions('Notify', ['notifyErrorHide']),
    closeTemplate () {
      this.notifyErrorHide()
    }
  }
}
</script>

<style lang="scss" scoped>

  .zoom-enter-active,
  .zoom-leave-active {
    transition: 0.3s;
    opacity: 0;
    transform: scale(1.4);
  }

  .zoom-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .zoom-leave-to {
    transform: scale(0.6);
  }

  :deep(.Tableee-main) {
    min-height: 150px !important;
  }

  .Alert {
    z-index: $z-index-xxl + 10;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    &-body {
      text-align: center;
      color: $color-warning;
    }

    &-table-view {
      width: 100%;
    }

    &-icon {
      margin-bottom: 30px;
      font-size: 100px;
    }

    &-title {
      font-size: 1.6em;
      color: $color-text-dark;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 15px;
      color: $color-text-light;
      word-break: break-all;
      text-align: center;
      max-width: 700px;
    }

    &-item {
      margin-top: 40px;
      background-color: transparent;
      display: flex;
      justify-content: center;
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 40px;
      display: flex;
      align-items: center;

      .btn {
        min-width: 180px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
</style>
