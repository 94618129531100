<template lang="pug">
.Filter-popup(:style="popupPosition" :ref="filterRef")
    .Filter-popup-header
      label.Filter-popup-header__label {{ headerLabel }}

      .Filter-popup-toggle(v-if="!onlySearch").m-top-5
        span.c-warning(@click="clearFilter") {{ $t('Global.clear_filter') }}

        span(v-if="!isSelectedAll" @click="toggleAll") {{ $t('Global.Toggle_Select') }}
        span(v-if="isSelectedAll && selectedList.length" @click="toggleAll") {{ unselectText }}

    .Filter-popup-body.custom-scrollbar
      .Filter-popup-search
        input.txt(
          v-model="search"
          @input="$emit('changeSearch', search)"
          :placeholder="$t('Global.search_Placeholder')"
        )
        Icon(name="icon-search")
      .Filter-popup-item(
        v-for="(item, index) in searchedList"
        :id="`${componenetName}-item-${index}`"
        )
        CustomCheckbox.Filter-popup-checkbox(
          :label="item[label]"
          :checked="alreadySelected(item)"
          @change="isChecked => $emit('onChangeCheckbox', {item, isChecked})"
        )
    .Filter-popup-footer
      Button(
        primary
        size="small"
        variant="full"
        :disabled="isLoading || !selectedList.length"
        @click="$emit('submitFilter', search)"
        )
        span {{ $t('Global.Apply_The_Filter') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>

import matchSearch from '@/utils/matchingSearch'

export default {
  props: {
    componenetName: {
      type: String,
      default: 'custom-table-filter'
    },
    filterRef: {
      type: String,
      default: 'tableFilter'
    },
    popupPosition: {
      type: Object,
      required: true
    },
    headerLabel: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default () {
        return []
      }
    },
    selectedList: {
      type: Array,
      default () {
        return []
      }
    },
    isClearFilterAvailable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'name'
    },
    equalityLabel: {
      type: String,
      default: 'id'
    },
    blockScroll: {
      type: Boolean,
      default: false
    },
    onlySearch: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    unselectText: {
      type: String,
      default () {
        return this.$t('Global.Toggle_Unselect')
      }
    }
  },

  data () {
    return {
      search: ''
    }
  },

  computed: {
    isSelectedAll () {
      return this.nonSelectedList.length === 0
    },
    nonSelectedList () {
      return this.list.filter(item => !this.alreadySelected(item))
    },
    // sortedList() {
    //   // const nonSelectedList = this.list.filter(item => this.selectedList.indexOf(item) === -1)
    //   return [ ...this.selectedList, ...this.nonSelectedList ]
    // },
    searchedList () {
      const label = this.label
      let newList = []
      if (this.search) {
        newList = this.nonSelectedList.filter(item => matchSearch(item[label] || '', this.search))
      } else {
        newList = this.nonSelectedList
      }
      return [ ...this.selectedList, ...newList ]
    }
  },
  methods: {
    isEmptySelectedList () {
      return this.selectedList.length === 0
    },
    isEmptyList () {
      return this.list.length === 0
    },
    alreadySelected (item) {
      return this.selectedList.some(si => si[this.equalityLabel] === item[this.equalityLabel])
    },

    clearSearch () {
      this.search = ''
    },

    toggleAll () {
      this.$emit('toggleAll', this.isSelectedAll)
    },
    clearFilter () {
      this.search = ''
      this.$emit('clearFilter')
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

.Filter-popup {
  @include MiniPopupMixin(
    $width: 281px,
    $max-width: 281px,
    $margin: 14px 0 0 0,
    $triangle-direction: 'center',
    $padding-hor: 0,
    $padding-ver: 0,
    $display: flex
  );
  z-index: $z-index-xxxl;
  flex-direction: column;

  &-header {
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 1px solid $color-gray;

    &__label {
      color: $color-dark;
      font-size: $font-size-normal;
      font-family: $font-family;
      font-weight: $font-weight-bold;
    }
  }

  &-toggle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    span {
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }

  &-body {
    display: flex;
    width: 100%;
    max-height: 240px;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
  }

  &-item {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $color-gray;
  }

  &-checkbox {
    padding: 15px;
    color: $color-dark;
  }

  &-search {
    position: relative;
    width: 100%;

    input.txt {
      padding-left: 45px;
    }

    .icon-search {
      position: absolute;
      color: $color-dark;
      width: 20px;
      height: 20px;
      left: 15px;
      top: 15px;
    }
  }

  &-footer {
    border-top: 1px solid $color-gray;
    padding: 20px;
  }
}
</style>
