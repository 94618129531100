var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box-content", class: _vm.extraClass },
    _vm._l(_vm.filteredField, function (field, index) {
      return field.headerTitle !== false && field.is_visible
        ? _c(
            "div",
            { staticClass: "box-row box-border-bottom" },
            [
              _c(
                "span",
                {
                  staticClass: "title",
                  attrs: {
                    id: `box-list-${_vm.componentName}-${_vm.rowIndex}-title-${index}`,
                  },
                },
                [_vm._v(_vm._s(field.short_title || ""))]
              ),
              field.type === "currency"
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "ff-mono u-textRight",
                        attrs: {
                          id: `box-list-${_vm.componentName}-${_vm.rowIndex}-currency-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("formatCurrency")(_vm.data[field.name]))
                        ),
                      ]
                    ),
                  ]
                : field.type === "phone"
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "ff-mono u-textRight",
                        attrs: {
                          id: `box-list-${_vm.componentName}-${_vm.rowIndex}-phone-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("formatPhone")(_vm.data[field.name]))
                        ),
                      ]
                    ),
                  ]
                : field.type === "percent"
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "ff-mono u-textRight",
                        staticStyle: { display: "inline-block", width: "100%" },
                        attrs: {
                          id: `box-list-${_vm.componentName}-${_vm.rowIndex}-percent-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          "%" +
                            _vm._s(_vm._f("percentFixed")(_vm.data[field.name]))
                        ),
                      ]
                    ),
                  ]
                : field.type === "date"
                ? [
                    _c(
                      "span",
                      {
                        attrs: {
                          id: `box-list-${_vm.componentName}-row-${_vm.rowIndex}-date-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.data[field.name]
                              ? new Date(
                                  _vm.data[field.name]
                                ).toLocaleDateString(_vm.$i18n.locale)
                              : "-"
                          )
                        ),
                      ]
                    ),
                  ]
                : field.subname
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "value mw-70",
                        attrs: {
                          id: `box-list-${_vm.componentName}-${_vm.rowIndex}-sub-names-${index}`,
                        },
                      },
                      [_vm._v(_vm._s(_vm.getSubNames() || "-"))]
                    ),
                  ]
                : field.type === "portal"
                ? [
                    _c("portalTarget", {
                      staticClass: "portal",
                      attrs: {
                        name: field.name,
                        "slot-props": (_vm.row = _vm.setNewRow(
                          _vm.data,
                          _vm.rowIndex
                        )),
                      },
                    }),
                  ]
                : [
                    _c(
                      "span",
                      {
                        staticClass: "value m-left-5",
                        attrs: {
                          id: `box-list-${_vm.componentName}-${_vm.rowIndex}-value-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.data[field.name] || field.defaultValue || "-"
                          )
                        ),
                      ]
                    ),
                  ],
            ],
            2
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }