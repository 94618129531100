<template lang="pug">
.import-excel
    Button.import-excel-button.m-right-15(
      :justIcon="true"
      @click="clickFile",
      :useIconClass="false"
      :isLoading="isLoading"
      :disabled="isLoading || disabled"
      :id="`import-excel${componentName}`"
    )
      Icon(
        :name="iconName"
        :class="!isTypeMassec ? 'doc-type-icon' : ''"
      )
      Icon.arrow-icon(
        v-if="!isTypeMassec"
        :name="typeImport ? 'import-arrow' : 'export-arrow'"
      )
    input#file.import-excel-hidden(
      type='file'
      ref='file'
      :accept="isEmitData ? 'text/xml' : 'all'"
      :multiple="isEmitData ? 'multiple' : false"
      @change='handleFileUpload'
    )

</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {

    iconName: {
      type: String,
      default: 'icon-xls'
    },

    isTypeMassec: {
      type: Boolean,
      default: false
    },

    fileType: {
      type: String,
      default: 'file'
    },

    typeImport: {
      type: Boolean,
      default: true
    },

    url: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    componentName: {
      type: String,
      default: ''
    },
    isEmitData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      file: ''
    }
  },

  watch: {
    file () {
      this.submitFile()
    }
  },

  computed: {
    isLoading () {
      return this.$wait.is(['importFile'])
    }
  },

  methods: {
    ...mapActions('Global', [
      'importFile'
    ]),
    submitFile (url = this.url, file = this.file) {
      this.importFile({url, file})
        .then(result => {
          this.$emit('success', result)
        })
    },

    /*
      Handles a change on the file upload
    */
    handleFileUpload () {
      if (this.$refs.file.files.length > 0) {
        if (this.isEmitData) this.$emit('fileUpload', this.$refs.file.files)
        else {
          this.file = this.$refs.file.files[0]
          this.$refs.file.value = ''
        }
      }
    },

    clickFile () {
      this.$refs.file.click()
    }
  }
}
</script>
<style lang="scss" scoped>

$icon-width: 56px;

.import-excel {
  position: relative;

  &-hidden {
    position: absolute;
    display: none;
    width: 1px;
    height: 1px;
  }
  &-button {
    position: relative;
    width: 54px;
    height: 54px;
    background: white;
    padding: 10px;
    box-shadow: 0px 1px 1px 0px #eaeaeb;
    .doc-type-icon {
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
    }
    .arrow-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      top: 0;
    }
  }
}
</style>
