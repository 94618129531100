<template lang="pug">
.Search-input
  Icon.Search-input-icon(name="icon-search")

  Button.Search-input-close(
  type="button"
  variant="icon"
  :id="`btn-${componentName}-clear-search`"
  v-if="search",
  @click="clearSearch")
    Loading(v-if="isSuggestionLoading")
    Icon(id="search-input-close-icon" name="icon-popup-close" v-if="!isSuggestionLoading")
  input.txt.Search-input-txt(
  :id="`input-${componentName}-search`"
  autocomplete="off"
  :disabled="isInputDisable"
  type="text"
  v-model="search"
  :placeholder="inputPlaceholderText"
  @input="onChangeSearchValue")

  .Search-suggestion(
    v-if="!suggestionsIsEmpty && showSuggestionBox" v-click-outside="clearOutsideSearch"
    :id="`search-suggestion-list-${componentName}`"
  )
    h5.Search-suggestion-title(
      :id="`search-suggestion-list-title-${componentName}`"
    )
      | {{ suggestionTitle }}
    Button.Search-suggestion-item(
    size="small"
    :id="`btn-${componentName}-suggestion-item-${index}`"
    v-for="(suggestion, index) in suggestions",
    :key="suggestion.id",
    @click="onChangeQueryItem(suggestion)",
    type="button")
      strong.name {{ suggestion.name }}
      i.type(
        :id="`search-suggestion-list-item-type-${componentName}-${index}`"
        v-if="showSuggestionType") {{ $t(`OnHand.search_SuggestionType_${suggestion.typeKey}`) }}

</template>

<script>
import { debouncer } from '@/utils/baseOperations'

export default {
  props: {
    suggestions: {
      type: Array,
      default () {
        return []
      }
    },

    suggestionValue: {
      type: String,
      default: ''
    },

    componentName: {
      type: String,
      required: true
    },

    showSuggestionType: {
      type: Boolean,
      default: false
    },

    isSuggestionLoading: {
      type: Boolean,
      default: false
    },

    isInputDisable: {
      type: Boolean,
      default: false
    },

    customSuggestionTitle: {
      type: String,
      default: ''
    },

    customSuggestionPlaceHolder: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      search: '',
      showSuggestionBox: false
    }
  },

  computed: {
    suggestionsIsEmpty () {
      return this.suggestions.length === 0
    },
    suggestionTitle () {
      return this.customSuggestionTitle ? this.customSuggestionTitle : this.$t(`${this.componentName}.search_SuggestionTitle`)
    },
    inputPlaceholderText () {
      return this.customSuggestionPlaceHolder ? this.customSuggestionPlaceHolder : this.$t(`${this.componentName}.search_Placeholder`)
    }
  },

  methods: {
    clearSearch () {
      this.search = ''
      this.showSuggestionBox = false
      this.onChangeSearchValue()
      this.$emit('clearSearch')
    },

    clearOutsideSearch () {
      this.showSuggestionBox = false
    },

    onChangeQueryItem (suggestion) {
      if (this.suggestionValue) this.search = suggestion[this.suggestionValue]
      this.$emit('onChangeQueryItem', suggestion)
      this.showSuggestionBox = false
    },

    onChangeSearchValue: debouncer(function () {
      this.showSuggestionBox = true
      this.$emit('onChangeSearchValue', this.search)
    }, 300)
  }
}
</script>

<style lang="scss" scoped>

  .Search {
    margin-bottom: 27px;

    &-input {
      position: relative;
      z-index: 1;
      width: 450px !important;
      &-icon {
        position: absolute;
        left: 14.5px;
        right: 19.98px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-light;
      }

      &-close {
        position: absolute;
        right: 0;
        top: 0;
        height: 48px;

        .icon {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background-color: rgba($color-warning, 0.1);
        }
      }

      &-txt {
        padding-left: 50px;
        padding-right: 50px;
        min-height: 48px;
      }
    }

    &-suggestion {
      overflow-y: scroll;
      position: absolute;
      left: 6px;
      top: 100%;
      z-index: 1;
      margin-top: 10px;
      width: 100%;
      max-height: 300px;
      background-color: white;
      box-shadow: 0 10px 30px rgba(black, 0.1);
      border-radius: $border-radius;
      border: 1px solid $color-gray;

      &-title {
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 10px;
        color: $color-primary;
      }

      &-item {
        height: $finger-size;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        font-weight: $font-weight-normal;
        width: 100%;

        &.btn {
          justify-content: start !important;
        }

        &:hover {
          background-color: $color-ocean;
        }

        & + & {
          border-top: 1px solid $color-gray;
        }
        .type {
          color: $color-text-light;
          margin-left: 10px;
        }
      }
    }
  }
</style>
