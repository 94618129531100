<template lang="pug">
  .select-dropdown-custom(ref="selectDropdown" v-click-outside="closeSelectbox")
    .select-dropdown-custom-selected(
      :class="[isOpen ? 'open' : '', isDisabled ? 'disabled-button': '', errorClass ? 'is-danger' : '']"
    )
      span.select-dropdown-custom-name.selected-name(
        id="select-dropdown-selected-name"
        @click="openSelectbox"
        @mouseover="setTooltipItem(showSelectedData !== defaultText ? showSelectedData : null)"
        @mouseleave="unsetTooltipItem"
      ) {{ showSelectedData }}
      Tooltip(
        v-if="tooltipName && tooltipName.length > 30"
        id="select-dropdown-tooltip"
        variant="primary"
        right,
        :text="tooltipName"
      )
      .select-dropdown-custom-selected-actions(
        id="select-dropdown-actions"
        v-if="actions.length > 0 && showSelectedData !== defaultText"
      )
        ActionButton(
          :data="actions"
          :item="selectedData"
          @onItemDelete="delegateOnItemRemove(selectedData)"
          @onItemEdit="delegateOnItemEdit(selectedData)"
        )
      .select-dropdown-custom-selected-button(
        @click="openSelectbox"
      )
        Icon(name="icon-down-arrow")
    .select-dropdown-custom-options(
      :class="[isOpen ? 'open' : '', filteredItems.length > 7 ? 'scroll' : '', isOpeningUpside ? 'upside' : 'normal']"
      id="select-dropdown-custom-option"
    )
      .select-dropdown-custom-options-search(v-if="isSearchActive && optionData.length > 7")
        Icon.Search-icon(name="icon-search")
        Button.close(
          v-if="search",
          variant="icon",
          iconName="icon-popup-close",
          iconClass="icon-popup-close"
          :justIcon="true"
          @click="clearSearch")

        customTextInput.Search-txt(
          :id="`input-custom-select-search-${componentName}`"
          ref="searchField"
          v-model="search"
          :placeholder="$t('Global.Filter_SearchPlaceholder')"
        )
      .select-dropdown-custom-name(
        v-if="isDefaultTextActive"
        id="custom-select-box-default-name"
        @click="sendOptionData(null)"
      )
        span.option-name {{ defaultText }}
      .select-dropdown-custom-name(
        v-for="(option, index) in filteredItems"
        :key="optionTitle ? `${option[optionTitle]}-${index}` : `${option}-${index}`"
        :id="`select-dropdown-custom-name-list-${componentName}-${index}`"
        :class="{'disabled-button': selectedData === option}"
        @mouseover="setTooltipItem(optionTitle ? option[optionTitle]: option)"
        @mouseleave="unsetTooltipItem"
        @click="sendOptionData(option)"
      )
        span.option-name.tooltip-relative(v-if="optionTitle") {{ option[optionTitle] }}
        span.option-name(v-else) {{ option }}
</template>

<script>

import matchingSearch from '@/utils/matchingSearch.js'
export default {
  name: 'custom-select',

  props: {
    value: {
      default: null
    },
    optionData: {
      type: Array,
      required: true
    },
    optionTitle: {
      type: String,
      default: ''
    },
    defaultTitle: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    itemData: {
      type: Object,
      default: () => {}
    },
    componentName: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    selectedEmitValue: {
      type: String,
      default: ''
    },
    errorClass: {
      type: Boolean,
      default: false
    },
    isDefaultTextActive: {
      type: Boolean,
      default: true
    },
    isSearchActive: {
      type: Boolean,
      default: false
    },
    searchIn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      defaultText: null,
      search: '',
      isOpeningUpside: false,
      tooltipName: null
    }
  },
  mounted () {
    if (!this.defaultTitle) this.defaultText = this.$t('Global.Select_Default_Text')
    else this.defaultText = this.defaultTitle
  },
  computed: {
    selectedData () {
      if (this.selectedEmitValue) {
        return this.value !== null ? this.optionData.find(i => i[this.selectedEmitValue] === this.value) : null
      } else {
        return this.value !== null ? this.optionData.find(i => i === this.value) : null
      }
    },
    showSelectedData () {
      if (this.optionTitle) {
        return this.selectedData ? this.selectedData[this.optionTitle] : this.defaultText
      } else {
        return this.selectedData ? this.selectedData : this.defaultText
      }
    },
    filteredItems () {
      let searchKeyword = this.search
      if (this.searchIn) searchKeyword = `%${searchKeyword}`
      if (this.isOpen) {
        if (this.isSearchActive) {
        return (this.optionData).filter(item => {
          if (this.optionTitle) {
            return matchingSearch(item[this.optionTitle], searchKeyword)
          }
          return matchingSearch(item, searchKeyword)
        })
      }
      return this.optionData
      }
      return []
    }
  },
  methods: {
    calcElementOffset () {
      // Calc page height including scroll area
      let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      )
      // If component is close to the bottom add class to it so it can open upside down.
      return this.$refs.selectDropdown.getBoundingClientRect().bottom + window.pageYOffset + 400 > scrollHeight
    },
    setTooltipItem (option) {
      if (option) this.tooltipName = option
    },
    unsetTooltipItem () {
      this.tooltipName = null
    },
    calcBottomOfThePage () {
      this.isOpeningUpside = this.calcElementOffset()
    },
    openSelectbox () {
      this.isOpen = !this.isOpen
      if (this.isOpen) this.calcBottomOfThePage()
    },
    clearSearch () {
      this.search = ''
    },
    closeSelectbox () {
      if (this.isOpen) {
        this.isOpen = false
        this.clearSearch()
        this.unsetTooltipItem()
      }
    },
    formFocus () {
      this.$refs.searchField.focusCustomInput()
    },
    sendOptionData (option) {
      if (option !== null) {
        this.selectedEmitValue ? this.$emit('input', option[this.selectedEmitValue]) : this.$emit('input', option)
      } else {
        this.$emit('input', null)
      }
      this.$emit('inputSelected', option, this.itemData)
      this.closeSelectbox()
    },
    onClick (item, actionName) {
      this.$emit('onItemAction' + actionName, item)
    },
    delegateOnItemEdit (item) {
      this.$emit('onItemActionEdit', item)
    },
    delegateOnItemRemove (item) {
      this.$emit('onItemActionRemove', item)
    }
  }
}
</script>

<style scoped lang="scss">

  .select-dropdown-custom {
    position: relative;
    .is-danger {
      border-color: $color-warning;
      background-color: rgba($color-warning, 0.05);
    }
    &-selected {
      display: flex;
      align-items: center;
      width: 210px;
      height: 48px;
      padding: 10px;
      border: 1px solid $color-gray;
      background-color: $color-white;
      .icon-down-arrow {
        transition: all 0.5s;
        width: 12px;
        color: $color-success;
      }
      &.open {
        border: 1px solid $color-gray;
        .icon-down-arrow {
          transform: rotate(180deg);
        }
      }
      &-button {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      &-actions {
        margin: 6px 0 10px;
        width: 60px;
      }
    }
    &-options {
      position: absolute;
      display: grid;
      left: 0;
      right: 0;
      border: 1px solid $color-gray;
      background: $color-white;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      z-index: $z-index-xl;
      width: auto;
      &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
      &.scroll {
        overflow-y: auto;
        height: 355px;
      }
      &.normal {
        top: 100%;
        border-top: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
      &.upside {
        bottom: 100%;
        border-bottom: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
      &-search {
        position: relative;
        .icon {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: $color-light;
        }

        .close {
          position: absolute;
          right: 0;
          height: 45px;
          .icon {
            width: 18px;
            height: 18px;
            top: 20px;
            left: auto;
          }

          &:hover {
            background-color: rgba($color-warning, 0.1);
          }
        }

        .txt {
          height: 44px;
          min-height: 44px;
          padding-left: 40px;
          padding-right: 40px;
          border: none;
          border-bottom: 1px solid $color-gray;
          border-radius: 0;
        }
      }
    }
    .select-dropdown-custom-name {
      position: relative;
      height: 44px;
      line-height: 44px;
      transition: all 0.2s;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      &:hover {
        background: #F9FAFB;
      }
      // &:not(:last-child) {
      //   border-bottom: 1px solid $color-gray;
      // }
      &:first-child {
        border-bottom: none;
        background: none;
      }
    }
    .selected-name {
      font-size: $font-size-small;
      color: $color-light;
      white-space: nowrap;
    }
    .option-name {
      font-size: $font-size-small;
      color: $color-dark;
      position: absolute;
      left: 10px;
    }
    .disabled-button {
      pointer-events: none;
      background: #f5f5f5;
    }
  }
  :deep() .appTooltip {
    display: block;
  }
</style>
