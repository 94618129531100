<template lang="pug">
tbody.Tableee-body(:id="`table-body-${componentName}`")
    tr.Tableee-row(
    @keypress.self.prevent.space="onItemShow(row)"
    @keypress.self.prevent.enter="onItemShow(row)"
    :class="{ 'u-cPointer' : pointer, ['row-' + row.rowTextClass]: row.rowTextClass }"
    v-for="(row, rowIndex) in data"
    :key="uniqueIdentifier ? row[uniqueIdentifier] : rowIndex"
    :tabindex="tabindex"
    :id="`table-body-${componentName}-row-${rowIndex}`")

      td.Tableee-col.selectable-col.checkbox.fixed(v-if="selectable === 'checkbox'")
        CustomCheckbox.checkbox(
        v-if="row.isActive == null || row.isActive || (showCheckboxForPassives && !row.isActive) "
        :id="`checkbox-table-row-${rowIndex}`"
        :label="''"
        :checked="isSelected(row)"
        :disabled="row.disableSelection"
        @change="onItemSelect(row)")

      td.Tableee-col(
      @click="onItemShow(row, $event)"
      v-for="(field, fieldIndex) in fields"
      :id="`table-body-${componentName}-row-${rowIndex}-col-${fieldIndex}`"
      :class="[field.name, { 'mono': field.type === 'currency' }, { [row.rowTextClass]: row.rowTextClass }, { 'fixed': field.fixedColumnHeader}]"
      :style="field.style || ''"
      v-if="field.is_visible")
        template(v-if="field.type === 'currency'")
          span(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ UserInfo.showCurrencySymbol ? row.currencyDefinitionSymbol : '' }}{{ row[field.name] | formatCurrency2Digits }}
        template(v-else-if="field.type === 'portal'")
          portalTarget.portal(
            :name="field.name"
            :slot-props="row = setNewRow(row, rowIndex)"
          )
        template(v-else-if="field.type === 'mono'")
          span.ff-mono.u-textRight.full-width(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ row[field.name] !== null ? row[field.name] : '-' }}
        template(v-else-if="field.type === 'quantity'")
          span.ff-mono.u-textRight.full-width(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ row[field.name] | formatCurrency2Digits }}
        template(v-else-if="field.type === 'phone'")
          span.ff-mono(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ row[field.name] | formatPhone }}
        template(v-else-if="field.type === 'with-badge'")
          span.row-badge(:class="[field.typeData(row).className]" :id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`")
            | {{ field.typeData(row).badgeText }}
        template(v-else-if="field.type === 'percent' || field.type === 'percentage'")
          span.ff-mono.u-textRight.full-width(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ row[field.name] | percentFixed }}%
        template(v-else-if="field.type === 'date'")
          span(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ row[field.name] ? new Date(row[field.name]).toLocaleDateString($i18n.locale) : '-'}}
        template(v-else-if="field.type === 'spacer'")
          span(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`")
        template(v-else)
          span.tooltip-relative.show-MiniPopup(:id="`table-body-${componentName}-row-${rowIndex}-col-span-${fieldIndex}`") {{ field.no_tooltip ? row[field.name] : getFieldName(row[field.name]) || field.default || '-' }}
            div(v-if="!field.no_tooltip")
              Tooltip(
                v-if="row[field.name] && row[field.name].length > 30"
                white
                right
                :text="row[field.name]"
              )
              Tooltip.table-mini-popup(
                v-if="field.have_tooltip"
                dark
                right
                :text="getTooltipText(row[field.tooltip_name])"
              )

            div(
              v-if="field.mini_popup && (row[field.show_mini_popup] || field.mini_popup.visible)"
              :class="`MiniPopup-${field.mini_popup.direction}-${field.mini_popup.color}`"
            )
              div.Tableee-col-mini-popup
                span.Tableee-col-mini-popup-text(
                ) {{ row[field.mini_popup.text] || "-" }}

      td.Tableee-col.action(v-if="!readOnly && !row.isDeleted")
        ActionButton(
          :data="actions"
          :item="row"
          @onItemCopy="onItemCopy(row)"
          @onItemChangeActiveStatus="onItemChangeActiveStatus(row)"
          @onItemDelete="onItemDelete(row)"
          @onItemExport="onItemExport(row)"
          @onItemOverview="onItemOverview(row)"
          @onItemEdit="onItemEdit(row)"
        )
      td.Tableee-col.portal-action(v-if="usePortalAction")
        portalTarget(
          name="actionPortal"
          :slot-props="row"
        )

</template>


<script>

import { mapGetters } from 'vuex'

export default {
  name: 'TableBody',

  props: [
    'fields',
    'data',
    'pointer',
    'readOnly',
    'actions',
    'selectable',
    'selectedRows',
    'showCheckboxForPassives',
    'componentName',
    'uniqueIdentifier',
    'usePortalAction'
  ],

  data () {
    return {
      showActions: false
    }
  },

  computed: {
    ...mapGetters('Settings', ['UserInfo']),

    isSelected () {
      return row => {
        if (this.uniqueIdentifier) return this.selectedRows.some(item => item[this.uniqueIdentifier] === row[this.uniqueIdentifier])
        else return this.selectedRows.indexOf(row) > -1
      }
    },

    tabindex () {
      if (this.pointer || !this.readOnly) {
        return 0
      }
      return null
    }
  },

  methods: {
    setNewRow (row, index) {
      return {index: index, ...row}
    },

    getFieldName (name) {
      return (name && name.length > 30) ? name.substr(0, 30).concat('...') : name
    },

    onItemCopy (item) {
      this.$emit('delegateOnItemCopy', item)
    },

    onItemExport (item) {
      this.$emit('delegateOnItemExport', item)
    },

    onItemEdit (item) {
      this.$emit('delegateOnItemEdit', item)
    },

    onItemDelete (item) {
      this.$emit('delegateOnItemDelete', item)
    },

    onItemChangeActiveStatus (item) {
      this.$emit('onItemChangeActiveStatus', item)
    },

    onItemShow (item, event) {
      this.$emit('delegateOnItemShow', item)
      this.$emit('delegateOnItemShowCustom', [item, event])
    },

    onItemOverview (item) {
      this.$emit('delegateOnItemOverview', item)
    },

    onItemSelect (item) {
      this.$emit('delegateOnItemSelect', item)
    },

    getTooltipText (storeGroupList) { // its static we will make it generic while neccesarry its for storeGroup code
      if (storeGroupList.length === 0) return ''
      return storeGroupList.map(item => !item.code ? item.name : item.code).join(', ')
    },

    shouldShow (item) {
      return (
        !this.readOnly &&
          (!this.actions.length || this.actions.indexOf(item) > -1)
      )
    },

    showActionBar (item) {
      item.showActions = !item.showActions
    },

    closeActionBar () {
      this.showActions = false
    }
  }
}
</script>
