var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "label",
      attrs: { id: `${_vm.id}-label` },
      on: {
        mousedown: function ($event) {
          return _vm.active(true)
        },
        mouseup: function ($event) {
          return _vm.toggle(false)
        },
        mouseleave: function ($event) {
          return _vm.active(false)
        },
      },
    },
    [
      _vm.renderComponent
        ? _c("input", {
            staticClass: "input",
            attrs: {
              type: "radio",
              disabled: _vm.disabled,
              id: `${_vm.id}-input`,
            },
            domProps: { checked: _vm.checked },
          })
        : _vm._e(),
      _c("span", {
        staticClass: "indicator",
        class: _vm.indicatorClassObject,
        attrs: { id: `${_vm.id}-label-indicator`, tabindex: _vm.tabindex },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            )
              return null
            $event.preventDefault()
            return _vm.active(true)
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            )
              return null
            $event.preventDefault()
            return _vm.toggle(false)
          },
        },
      }),
      _c("span", { attrs: { id: `${_vm.id}-label-text` } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }