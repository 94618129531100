<template lang="pug">
.App
    Alertbox(ref="Alertbox")
    Icons
    Notify
    ErrorTemplate
    Sidebar(:showSalesWarning="suspendSalesExist")
    .Body(:class="[!MenuMinimalize ? 'Expand' : 'MiniBody']")
      router-view(v-if="hasPermission")
      NoPermission(v-else)
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Alertbox from '@/view/global/alertbox'
import Sidebar from '@/view/global/sidebar'
import NoPermission from '@/view/global/has-not-permission'
import Icons from '@/view/global/icons'
import ErrorTemplate from '@/view/global/error-template'

export default {
  name: 'LoginYes',

  components: {
    Alertbox,
    Sidebar,
    Icons,
    ErrorTemplate,
    NoPermission
  },

  async created () {
    this.$wait.start('setUserLang')
    await this.getCurrencyList()
    await this.updateUserLang()
    this.$wait.end('setUserLang')
  },

  async mounted () {
    await this.getUnitList()
    await this.getExportFilterSettings()
  },

  computed: {
    ...mapGetters('Global', [
      'suspendSalesExist',
      'Languages'
    ]),
    ...mapGetters('Global', [
      'MenuMinimalize'
    ]),
    ...mapGetters('Settings', [
      'permissions'
    ]),
    ...mapGetters('Auth', [
      'User'
    ]),
    hasPermission () {
      if (!this.$route.meta.permissionKey) { return true }
      let permissionItem = this.permissions.find(x => {
        return x.permissionKey === this.$route.meta.permissionKey
      })

      return permissionItem
    }
  },

  methods: {
    ...mapActions('Global', [
      'getCurrencyList'
    ]),

    ...mapActions('Units', [
      'getUnitList'
    ]),

    ...mapActions('i18n', [
      'langChange'
    ]),

    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('ExportSettings', ['getExportFilterSettings']),

    clearLanguages () {
      return this.Languages.map(lang => ({
        ...lang,
        isoCode: lang.isoCode.split('-')[0]
      }))
    },

    updateUserLang () {
      let user = this.User
      let clearLanguages = this.clearLanguages()
      let isoCode
      clearLanguages.map(lang => {
        if (lang.id === user.languageId) {
          isoCode = lang.isoCode
        }
      })
      this.langChange({
        lang: isoCode,
        app: this
      })
    }
  }
}
</script>

<style lang="scss">

  .Main {
    z-index: 1;
    position: relative;
    margin-left: 220px;
  }

  .Body {
    min-height: 100vh;
    transition: all .5s ease;
    &.MiniBody {
      margin-left: $sidebar-mini-size
    }
    &.Expand {
      margin-left: $sidebar-size;
    }
  }

  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 600px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
</style>
