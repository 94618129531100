var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeSelectbox,
          expression: "closeSelectbox",
        },
      ],
      ref: "selectDropdown",
      staticClass: "select-dropdown-custom",
    },
    [
      _c(
        "div",
        {
          staticClass: "select-dropdown-custom-selected",
          class: [
            _vm.isOpen ? "open" : "",
            _vm.isDisabled ? "disabled-button" : "",
            _vm.errorClass ? "is-danger" : "",
          ],
        },
        [
          _c(
            "span",
            {
              staticClass: "select-dropdown-custom-name selected-name",
              attrs: { id: "select-dropdown-selected-name" },
              on: {
                click: _vm.openSelectbox,
                mouseover: function ($event) {
                  return _vm.setTooltipItem(
                    _vm.showSelectedData !== _vm.defaultText
                      ? _vm.showSelectedData
                      : null
                  )
                },
                mouseleave: _vm.unsetTooltipItem,
              },
            },
            [_vm._v(_vm._s(_vm.showSelectedData))]
          ),
          _vm.tooltipName && _vm.tooltipName.length > 30
            ? _c("Tooltip", {
                attrs: {
                  id: "select-dropdown-tooltip",
                  variant: "primary",
                  right: "",
                  text: _vm.tooltipName,
                },
              })
            : _vm._e(),
          _vm.actions.length > 0 && _vm.showSelectedData !== _vm.defaultText
            ? _c(
                "div",
                {
                  staticClass: "select-dropdown-custom-selected-actions",
                  attrs: { id: "select-dropdown-actions" },
                },
                [
                  _c("ActionButton", {
                    attrs: { data: _vm.actions, item: _vm.selectedData },
                    on: {
                      onItemDelete: function ($event) {
                        return _vm.delegateOnItemRemove(_vm.selectedData)
                      },
                      onItemEdit: function ($event) {
                        return _vm.delegateOnItemEdit(_vm.selectedData)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "select-dropdown-custom-selected-button",
              on: { click: _vm.openSelectbox },
            },
            [_c("Icon", { attrs: { name: "icon-down-arrow" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "select-dropdown-custom-options",
          class: [
            _vm.isOpen ? "open" : "",
            _vm.filteredItems.length > 7 ? "scroll" : "",
            _vm.isOpeningUpside ? "upside" : "normal",
          ],
          attrs: { id: "select-dropdown-custom-option" },
        },
        [
          _vm.isSearchActive && _vm.optionData.length > 7
            ? _c(
                "div",
                { staticClass: "select-dropdown-custom-options-search" },
                [
                  _c("Icon", {
                    staticClass: "Search-icon",
                    attrs: { name: "icon-search" },
                  }),
                  _vm.search
                    ? _c("Button", {
                        staticClass: "close",
                        attrs: {
                          variant: "icon",
                          iconName: "icon-popup-close",
                          iconClass: "icon-popup-close",
                          justIcon: true,
                        },
                        on: { click: _vm.clearSearch },
                      })
                    : _vm._e(),
                  _c("customTextInput", {
                    ref: "searchField",
                    staticClass: "Search-txt",
                    attrs: {
                      id: `input-custom-select-search-${_vm.componentName}`,
                      placeholder: _vm.$t("Global.Filter_SearchPlaceholder"),
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isDefaultTextActive
            ? _c(
                "div",
                {
                  staticClass: "select-dropdown-custom-name",
                  attrs: { id: "custom-select-box-default-name" },
                  on: {
                    click: function ($event) {
                      return _vm.sendOptionData(null)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "option-name" }, [
                    _vm._v(_vm._s(_vm.defaultText)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.filteredItems, function (option, index) {
            return _c(
              "div",
              {
                key: _vm.optionTitle
                  ? `${option[_vm.optionTitle]}-${index}`
                  : `${option}-${index}`,
                staticClass: "select-dropdown-custom-name",
                class: { "disabled-button": _vm.selectedData === option },
                attrs: {
                  id: `select-dropdown-custom-name-list-${_vm.componentName}-${index}`,
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.setTooltipItem(
                      _vm.optionTitle ? option[_vm.optionTitle] : option
                    )
                  },
                  mouseleave: _vm.unsetTooltipItem,
                  click: function ($event) {
                    return _vm.sendOptionData(option)
                  },
                },
              },
              [
                _vm.optionTitle
                  ? _c(
                      "span",
                      { staticClass: "option-name tooltip-relative" },
                      [_vm._v(_vm._s(option[_vm.optionTitle]))]
                    )
                  : _c("span", { staticClass: "option-name" }, [
                      _vm._v(_vm._s(option)),
                    ]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }