<template lang="pug">
.search-input
    Icon(name="icon-search")
    input.txt(
      type="txt",
      v-bind="$attrs",
      @input="onSearchAction"
      :placeholder="searchPlaceholder"
    )
</template>
<script>

export default {
  name: 'SearchInput',

  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },

  computed: {
    searchPlaceholder () {
      return !this.placeholder ? this.$t('Global.search_Placeholder') : this.placeholder
    }
  },

  methods: {
    onSearchAction (e) {
      const value = e.target.value
      this.$emit('input', value)
      this.$emit('onSearch', value)
    }
  }
}
</script>

<style lang="scss" scoped>

  .search-input {
    display: flex;
    align-items: center;
    border: 1px solid $color-gray;
    padding-left: 15px;
    .icon-search {
      color: $color-light;
    }
    .txt {
      border: none;
    }
  }
</style>
