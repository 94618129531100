var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "aside",
        {
          directives: [
            {
              name: "shortkey",
              rawName: "v-shortkey",
              value: ["esc"],
              expression: "['esc']",
            },
          ],
          staticClass: "Modal",
          on: { shortkey: _vm.close },
        },
        [
          _c("div", { staticClass: "Modal-Overlay", on: { click: _vm.close } }),
          _c("div", { staticClass: "Modal-Body" }, [
            _c(
              "div",
              { staticClass: "Modal-Body-header" },
              [
                _c("h3", { staticClass: "title" }, [_vm._t("title")], 2),
                _vm._t("description"),
                _vm.showCloseButton
                  ? _c("Button", {
                      staticClass: "modal-close-button",
                      attrs: { justIcon: true, iconName: "icon-popup-close" },
                      on: { click: _vm.close },
                    })
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "Modal-Body-content" },
              [_vm._t("content")],
              2
            ),
            _c(
              "div",
              { staticClass: "Modal-Body-footer" },
              [_vm._t("footer")],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }