<template lang="pug">
.custom-multiselect(:id="id ? id : componentName")
  .Form-item.dropdown-handle
    span(ref='dropdownHandleLabel')
    .select.dropdown-handle-button(
      :class="[(hasValues ? 'has-values' : ''), (isDisabled ? 'disabled-button' : '')]"
      :id="`custom-multiselect-show-button-${componentName || 'random'}`"
      @keydown.enter="togglePopup"
      @keydown.prevent.space="togglePopup"
      @click="togglePopup"
      :aria-controls="uniquePopupId"
      :aria-expanded="isPopupVisible"
      tabindex="0"
    )
      div(:class="showCountBadge ? 'dropdown-handle-button-right' : ''")
        Icon.dropdown-handle-button-right-icon(:class="{'open': isPopupVisible}" name="icon-down-arrow")
        .dropdown-handle-button-right-badge(v-if="showCountBadge") ({{selected.length === items.length ? ($t('Global.Select_Default_Text')) : selectedDataCounter}})

      .dropdown-handle-button-values(v-if="!customEmptyState")
        | {{showSelectedName ? selected[0]?.name : label}}
      .dropdown-handle-button-values(v-if="useCustomEmptyState && customEmptyState")
        span(v-if="useCustomEmptyState && customEmptyState") {{customEmptyState}}
  .MiniPopup.popup(
    v-if="isPopupVisible"
    :id="uniquePopupId"
    v-click-outside="hidePopup"
    v-shortkey="['esc']"
    @shortkey="hidePopup"
    :class="useCustomLocation ? 'custom' : 'left'"
    :style="popupStyle"
  )
    Loading(v-if="isLoadingData")
    div(v-if="!isLoadingData")

      h2.popup-header {{ popupHeader }}

      .Search(v-if="getSearch")
        Icon.Search-icon(name="icon-search")
        Button.Search-close(
        variant="icon"
        iconName="icon-popup-close"
        iconClass="icon-popup-close"
        :id="`btn-clear-${id}`"
        v-if="search",
        @click="clearSearch")

        input.txt.Search-txt(
          autocomplete="off"
          type="text"
          :id="`input-search-${id}`"
          v-model="search"
          :placeholder="$t('Global.Filter_SearchPlaceholder')"
        )

      .popup-content

        .MiniPopup-tr.popup-row(v-if="showAll && type === 'checkbox' && !search")
          CustomCheckbox.popup-item-check(
            v-if="items.length !== 0"
            :id="`checkbox-multiselect-all-${id}`"
            :checked="isAllSelected"
            :label="allLabel"
            @change="toggleAll"
          )
          .d-flex.m-left-10(v-else)
            Icon(name="icon-global-warning")

            span.c-warning.m-left-10 {{ $t('Global.Filter_Empty_State') }}
        .MiniPopup-tr.popup-row(v-else-if="showAll && type === 'radio'")
          CustomRadio.popup-item-check(
          :id="`radio-multiselect-all-${id}`"
          :checked="isAllSelected"
          :label="allLabel"
          @change="toggleAll")
        .MiniPopup-tr.popup-row(v-for="(item, index) in searchItems")
          div.popup-item-check(
          :is="inputType"
          :id="`${type}-multiselect-item-${id}-${index}`"
          :checked="selected.indexOf(item) > -1 || selected.some(selectedItem => selectedItem[itemUniqKey] === item[itemUniqKey])"
          :label="item.name",
          :badged="item[badgedTextField]",
          @change="isChecked => selectItem(item, isChecked)")
          span.badged(v-if="withBadgedText") {{ item[badgedTextField] }}
      .popup-footer(v-if="showFooter")
        Button(
        id="btn-custom-multiselect-submit"
        variant="full"
        primary
        size="small"
        :disabled="isBtnDisabled"
        @click="submitPopup")
          svg.icon.icon-left(
          v-if="buttonIconLeft"
          :class="{ [`icon-${buttonIconLeft}`]: true }")
            use(:xlink:href="`#icon-${buttonIconLeft}`")
          | {{ buttonText }}
          svg.icon.icon-left(
          v-if="buttonIconRight"
          :class="{ [`icon-${buttonIconRight}`]: true }")
            use(:xlink:href="`#icon-${buttonIconRight}`")
</template>

<script>
import { mapActions } from 'vuex'
import matchingSearch from '@/utils/matchingSearch'

export default {
  name: 'CustomMultiselect',

  props: {
    id: {
      type: String,
      required: true
    },
    showSelectedName: {
      type: Boolean,
      default: false
    },
    isLoadingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    useCustomEmptyState: {
      type: Boolean,
      default: false
    },
    customEmptyState: {
      type: String,
      default: null
    },
    useCustomLocation: {
      type: Boolean,
      default: false
    },
    isCustomRadioFilter: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    badgedTextField: {
      type: String,
      default: null
    },
    withBadgedText: {
      type: Boolean,
      default: false
    },
    haveDefaultStore: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selected: {
      type: Array,
      default () {
        return []
      }
    },
    label: {
      type: String,
      default: ''
    },
    unselectedText: {
      type: String,
      default: ''
    },
    popupHeader: {
      type: String,
      default: ''
    },
    allLabel: {
      type: String,
      default: ''
    },
    showAll: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonIconLeft: {
      type: String,
      default: ''
    },
    numberOfShowSearch: {
      type: Number,
      default: 6
    },
    buttonIconRight: {
      type: String,
      default: ''
    },
    sortSelected: {
      type: Object,
      default () {
        return {
          by: 'name',
          order: 1
        }
      }
    },
    updateDropDownLabelFromParent: {
      type: Boolean,
      default: false
    },
    componentName: {
      type: String,
      default: ''
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    itemUniqKey: { // this props for uniq paramater in array
      type: String,
      default: 'id'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    dontDisableButton: {
      type: Boolean,
      default: false
    },
    showCountBadge: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isPopupVisible: false,
      search: '',
      hasValues: true,
      dropdownLabel: this.label,
      selectedDataCounter: null
    }
  },

  watch: {
    selected () {
      if (this.type === 'radio' && this.haveDefaultStore && this.dropdownLabel === '') {
      } else if (!this.showFooter) this.beforeSubmit()
    },
    updateDropDownLabelFromParent () {
      this.beforeSubmit()
    },
  },

  mounted () {
    this.selectedDataCounter = this.items.length
  },

  computed: {
    getSearch () {
      return this.items.length > this.numberOfShowSearch
    },

    isAllSelected () {
      if (this.type === 'radio')
        return this.selected.length === 0

      return this.selected.length === this.searchItems.length
    },

    isListDataEmpty () {
      return this.items.length === 0 || this.selected.length === 0
    },

    searchItems () {
      return this.items.filter(item =>
        matchingSearch(item.name, this.search)
      )
    },

    isSearchEmpty () {
      return this.searchItems.length === 0
    },

    isBtnDisabled () {
      return this.dontDisableButton ? false : this.selected.length === 0 || this.isSearchEmpty
    },
    sortedSelectedItems () {
      const sort = this.sortSelected
      return this.selected.sort((itemA, itemB) => {
        const asc = itemA[sort.by] > itemB[sort.by]
        return sort.order === 1 ? asc : !asc
      })
    },

    uniquePopupId () {
      const number = performance.now().toString().split('.').join('-')
      return `custom-multiselect-popup-${number}`
    },

    popupStyle () {
      const $label = this.$refs.dropdownHandleLabel
      const labelWidth = $label.getBoundingClientRect().width
      const labelMarginLeft = parseInt(getComputedStyle($label).marginLeft, 10)
      const labelMarginRight = parseInt(getComputedStyle($label).marginRight, 10)
      return {
        left: `${labelWidth + labelMarginLeft + labelMarginRight}px`
      }
    },

    inputType () {
      return this.type === 'radio' ? 'custom-radio' : 'custom-checkbox'
    }
  },

  methods: {
    ...mapActions('Settings', [
      'fetchUser'
    ]),

    togglePopup () {
      this.isPopupVisible = !this.isPopupVisible
      this.$emit('onClick')
    },

    clearSearch () {
      this.search = ''
    },

    hidePopup () {
      this.clearSearch()
      this.isPopupVisible = false
    },

    showAllSelectedLabel () {
      this.selected = this.searchItems
      this.beforeSubmit()
    },

    beforeSubmit () {
    },

    submitPopup () {
      this.clearSearch()
      this.beforeSubmit()
      this.$emit('delegateSubmitItems')
      this.hidePopup()
      this.selectedDataCounter = this.selected.length
    },

    toggleAll (isChecked) {
      this.$emit('delegateCheckAll', {
        item: { all: true },
        isChecked
      })
    },

    selectItem (item, isChecked) {
      this.$emit('delegateCheckItem', { item, isChecked })
    }
  }
}
</script>

<style scoped lang="scss">

  @import "~@/stylesheet/config/mixin";

  .Search {
    position: relative;
    margin: 3px;
    margin-bottom: 0;
    &-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $color-light;
    }

    &-close {
      position: absolute;
      right: 0;
      top: 0;

      .icon {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: rgba($color-warning, 0.1);
      }
    }

    &-txt {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .custom-multiselect {
    position: relative;
  }

  .dropdown-handle {
    display: flex;
    align-items: center;
    font-size: $font-size-micro;
    user-select: none;
    border: 1px solid $color-gray;
    border-radius: $border-high-radius;

    .disabled-button {
      pointer-events: none;
      opacity: 0.6;
    }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      position: relative;
      padding-right: 25px;
      height: 35px;
      color: inherit;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &-right {
        display: flex;
        align-items: flex-end;
        flex-direction: row-reverse;
        color: $color-success;
        &-badge {
          position: relative;
          left: 2px;
          font-weight: $font-weight-semi-bold;
        }
        &-icon.icon.icon-down-arrow {
          position: relative;
          top: 5px;
          transform: translateY(-50%);
          right: -5px;
          width: 10px;
          transition: all 0.5s;
          color: $color-success;
          &.open {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }

      &:hover {
        transition: 0.5s ease-in-out;
        border: 1px solid $color-success-dark;
        box-shadow: 0 0 2px $color-success-dark;
      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        color: $color-success;
      }

      &.has-values {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
  }

  .popup {
    z-index: $z-index-xxl;
    width: 250px;

    &-content {
      position: relative;
      max-height: 290px;
      overflow: auto;
    }

    &-header {
      padding: 20px 10px 10px;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
    }

    &-row {
      padding: 0;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;
      &:hover{
        background-color: $color-gray;
        transition: 0.5s ease-in-out;
      }
      label {
        display: block;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500 !important;
        font-size: $font-size-small !important;
        color: $color-dark;
      }
    }

    &-item-check {
      margin-right: 10px;
    }

    &-footer {
      padding: 10px;
    }
    .badged {
      margin-top: 4px;
      font-size: $font-size-small;
      word-break: break-all;
      margin-left: 30px;
      padding-left: 10px;
      padding-right: 10px;
      color: $color-light;
      font-weight: normal;
    }
  }
  .left {
    top: 40px !important;
  }
  .custom {
    width: 267px !important;
    bottom: 118% !important;
  }
</style>
