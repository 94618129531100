var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      staticClass: "Popover",
      class: _vm.variants,
    },
    [
      _vm.isTooltip
        ? _c("span", { ref: "tooltip" })
        : _c(
            "div",
            { ref: "toggle", staticClass: "Popover-toggle" },
            [
              _c(
                "Button",
                _vm._b(
                  {
                    attrs: {
                      id: _vm.buttonId,
                      variant: _vm.toggleVariant,
                      nostyle: !_vm.toggleVariant,
                      outline: _vm.toggleOutline,
                      size: _vm.toggleSize,
                    },
                    on: {
                      click: function ($event) {
                        _vm.shown = !_vm.shown
                      },
                    },
                  },
                  "Button",
                  _vm.toggleAttr,
                  false
                ),
                [
                  _vm.$slots.label
                    ? _vm._t("label")
                    : [_vm._v(_vm._s(_vm.label))],
                  _c("Icon", {
                    staticClass: "toggleIcon",
                    attrs: { name: _vm.toggleIcon },
                  }),
                ],
                2
              ),
            ],
            1
          ),
      _c("transition", { attrs: { name: "popover-shown" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShow,
                expression: "isShow",
              },
            ],
            ref: "content",
            staticClass: "Popover-wrapper",
            class: _vm.popoverVariants,
          },
          [
            _c(
              "div",
              { staticClass: "Popover-wrapper-content" },
              [_vm._t("header"), _vm._t("content"), _vm._t("footer")],
              2
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }