<template lang="pug">
transition(name="zoom")
    .Alert
      .Alert-body

        figure.Alert-icon
          img(src="./assets/icon-delete.svg")
         
        h3.Alert-title {{ title || $t('Global.deleteAlert_WillDelete_Title') }}
        p.Alert-description
          template(v-if="tableName") {{ $t('Global.deleteAlert_WillDelete', { name: tableName }) }}
          template(v-else) {{ description || $t('Global.deleteAlert_WillDelete_Description') }}

        .Alert-item(v-if="fields.length > 0")

          Loading.data-loading(v-if="isGetDataLoading" , theme="disable")
          table.Tableee-root(id="delete-alert-table")
            thead.Tableee-head(id="delete-alert-table-header")
              tr.Tableee-row(id="delete-alert-table-header-row")
                th.Tableee-col(
                v-for="(field, index) in fields",
                :key="field.name"
                v-if="field.is_visible",
                :id="`delete-alert-table-header-col-${field.name}-${index}`"
                ) {{ field.short_title }}

            tbody.Tableee-body(v-if="!isGetDataLoading" id="delete-alert-table-body")
              tr.Tableee-row(id="delete-alert-table-body-row")
                td.Tableee-col.tooltip-relative(
                  v-for="(field, index) in fields",
                  :key="field.name"
                  v-if="field.is_visible",
                  :id="`delete-alert-table-body-col-${field.name}-${index}`"
                )
                  template(v-if="field.is_currency") {{ getFieldValue(field.name) | formatCurrency }}
                  template(v-else) {{ getFieldName(getFieldValue(field.name)) }}

                  Tooltip(
                    v-if="getFieldValue(field.name).length > 30 "
                    white
                    bottom
                    :text="getFieldValue(field.name)"
                  )

        .Alert-actions
          Button(
          id="btn-delete-alert-cancel"
          type="button",
          ref="focusedButton"
          secondary
          size="medium"
          @click="cancel"
          ) {{ $t('Global.deleteAlert_CancelButton') }}
          Button(
          id="btn-delete-alert-ok"
          type="button",
          primary,
          variant="full"
          size="medium"
          @click="ok",
          :disabled="isLoading")
            span(v-show="!isLoading") {{ $t('Global.deleteAlert_DeleteButton') }}
            Loading(theme="disable", v-show="isLoading")
</template>

<script>

import { getObjectValue } from '@/utils/baseOperations'

export default {
  name: 'DeleteAlert',

  props: {
    title: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    tableName: {
      type: String,
      default: null
    },

    item: {
      type: Object
    },

    fields: {
      type: Array,
      default: []
    },

    loading: {
      type: String,
      default: ''
    },

    isGetDataLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isLoading () {
      if (this.loading === '') return false
      return this.$wait.is([this.loading])
    }
  },

  // mounted () {
  //   this.$refs.focusedButton.focus()
  // },

  methods: {
    getFieldValue (fieldName) {
      return getObjectValue(fieldName, this.item)
    },

    getFieldName (name) {
      return (name && name.length > 30) ? name.substr(0, 30).concat('...') : name
    },

    cancel () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    ok () {
      this.$emit('delegateOnAlertRemoveOk')
    }
  }
}
</script>

<style lang="scss" scoped>

  .zoom-enter-active,
  .zoom-leave-active {
    transition: 0.3s;
    opacity: 0;
    transform: scale(1.4);
  }

  .zoom-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .zoom-leave-to {
    transform: scale(0.6);
  }

  .Alert {
    z-index: $z-index-md;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    &-body {
      text-align: center;
    }

    &-icon {
      margin-bottom: 30px;
    }

    &-title {
      font-size: 1.6em;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 7px;
      color: $color-text-light;
    }

    &-item {
      margin-top: 40px;
      max-width: 900px;
      box-shadow: 0 2px 6px rgba(black, 0.05);
      background-color: white;
      display: flex;
      flex-direction: column-reverse;
    }

    .data-loading {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 40px;
      display: flex;
      align-items: center;

      .btn {
        min-width: 180px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
</style>
