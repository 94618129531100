var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "error-icon" },
        [_c("Icon", { attrs: { name: "icon-error" } })],
        1
      ),
      _c("p", [_vm._v(_vm._s(_vm.$t("Global.has_Not_Permission")))]),
      _c(
        "router-link",
        {
          staticClass: "back-to-home-link",
          attrs: { id: "back-to-home-page-link", to: "/" },
        },
        [_vm._v(_vm._s(_vm.$t("Global.back_To_Home")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }