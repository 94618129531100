<template lang="pug">
.EmptyState
    .EmptyState-inner
      Icon.empty-icon(:name="iconName" :useIconClass='false')
      h3.title(id="empty-state-title") {{ title }}
      p.description(id="empty-state-description") {{ description }}
      span.optional-text(v-if="optionalText" id="empty-state-optional-text")
        Icon.optional-text-icon(:name="optionalIcon" :useIconClass='false')
        h4.optional-text-title {{ optionalText }}
      Button(:class="`btn ${buttonType}`"
      size="large"
      id="btn-empty-state-action"
      v-if="buttonText"
      type="button"
      @click="add")
        Icon.icon.icon-left(v-if="showIcon" :name="buttonIcon" :useIconClass='false')
        span {{ buttonText }}
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: 'empty-state',
    },
    description: {
      type: String,
      default: ''
    },
    optionalIcon: {
      type: String,
      default: 'icon-global-info'
    },
    optionalText: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: 'btn-success'
    }, 
    buttonIcon: {
      type: String,
      default: 'icon-global-add'
    },
    buttonText: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    add () {
      this.$emit('emptyAddButton')
    }
  }
}
</script>
