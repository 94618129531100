var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "total-counts" },
    _vm._l(_vm.totalCountArray, function (countData, index) {
      return _c(
        "div",
        { staticClass: "total-count", class: countData.extraClass },
        [
          _c(
            "div",
            {
              staticClass: "total-count-label",
              attrs: { id: `total-count-list-label-${index}` },
            },
            [_vm._v(_vm._s(countData.totalCountLabel) + ":")]
          ),
          _c(
            "div",
            {
              staticClass: "total-count-value ff-mono",
              attrs: { id: `total-count-list-value-${index}` },
            },
            [_vm._v(_vm._s(countData.totalCountValue))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }