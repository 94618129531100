<template lang="pug">
.icon-container
    Loading(v-if="isDataLoading", theme="disable")
    .icon-container-box(v-else)
      span(v-if="data.length === 0") {{ $t('Global.Search_No_Result') }}
      .icon-box(v-else)
        .icon-info(v-for="i in data")
          .box-icon
            Icon(:name="i.iconName")
          .title
            span(:id="`${i.name}-title`") {{ i.name }}

</template>

<script>

export default {
  name: 'IconBox',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    isDataLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

  .icon-container{
    display: flex;
    justify-content: center;
  }
  .icon-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .icon-info {
    max-width: 33.333333%;
    padding-bottom: 10px;
    flex: 33.333333%;
    text-align: center;
    margin: 5px 0;
    &:nth-child(1){
      border-top: none;
    }
    .box-icon{
      width: 25px;
      height: 25px;
      border: 1px solid $color-light-primary;
      border-radius: 100%;
      margin: auto;
      background-color: #f6f6f6;
    }
    span{
      word-break: break-word;
      white-space: normal;
    }
  }
</style>
