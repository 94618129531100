var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filterList" }, [
    _c("div", { staticClass: "search" }, [
      _c(
        "div",
        { staticClass: "inputSearch" },
        [
          _c("Icon", { attrs: { name: "icon-search" } }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchText,
                expression: "searchText",
              },
            ],
            staticClass: "txt",
            attrs: {
              id: `input-general-filter-${_vm.id}`,
              placeholder: _vm.$t("Global.GlobalFilterSearchTitle", {
                field: _vm.title,
              }),
            },
            domProps: { value: _vm.searchText },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchText = $event.target.value
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "listItems" },
      [
        _c("h4", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        !_vm.searchText
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c("CustomCheckbox", {
                  attrs: {
                    id: `checkbox-general-filter-${_vm.id}-all-selected`,
                    label: _vm.$t("Global.toolbar_Filters_AllLabel"),
                    checked: _vm.isAllSelected,
                  },
                  on: {
                    change: (isChecked) => _vm.toggleAllSelected(isChecked),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.searchingList, function (item, index) {
          return _c(
            "div",
            { key: item.id, staticClass: "item" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: `checkbox-general-filter-${_vm.id}-${index}`,
                  label: item.name,
                  checked: _vm.selectedList.some((i) => i.id === item.id),
                },
                on: {
                  change: (isChecked) => _vm.changeList({ isChecked, item }),
                },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }