var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "import-btn",
          attrs: {
            secondary: "",
            size: "small",
            disabled: _vm.isDisabled,
            id: `btn-import-export-${_vm.componentName}`,
            justIcon: true,
            iconName: "icon-down-arrow",
            iconClass: "icon-right",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleOtherOptions.apply(null, arguments)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("Global.importExport_Header")))]),
          _c("a", {
            ref: "downloadButton",
            staticClass: "display-none-download-link",
            attrs: { download: _vm.downloadFileExportName },
          }),
          _c("input", {
            ref: "file",
            staticClass: "import-excel-hidden",
            attrs: {
              id: "file",
              type: "file",
              accept: _vm.importIsEmitData ? "text/xml" : "all",
              multiple: _vm.importIsEmitData ? "multiple" : false,
            },
            on: { change: _vm.handleFileUpload },
          }),
          _vm.showOtherOptions
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeOtherOptions,
                      expression: "closeOtherOptions",
                    },
                    {
                      name: "shortkey",
                      rawName: "v-shortkey",
                      value: ["esc"],
                      expression: "['esc']",
                    },
                  ],
                  staticClass: "MiniPopup right import-export",
                },
                [
                  _vm.isTemplateDownloadActive
                    ? _c(
                        "Button",
                        {
                          attrs: {
                            size: "small",
                            secondary: "",
                            disabled: _vm.templateDisabled,
                            variant: "full",
                            id: `import-export-template-download-${_vm.componentName}`,
                            iconName: "icon-import-export-template",
                          },
                          on: { click: _vm.downloadTemplate },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("Global.importExport_Template"))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isImportActive
                    ? _c(
                        "Button",
                        {
                          attrs: {
                            size: "small",
                            variant: "full",
                            secondary: "",
                            id: `import-export-import-${_vm.componentName}`,
                            iconName: "icon-import-export-import-xls",
                          },
                          on: { click: _vm.clickFile },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.getImportText))])]
                      )
                    : _vm._e(),
                  _vm.isExportActive
                    ? _c(
                        "Button",
                        {
                          attrs: {
                            size: "small",
                            secondary: "",
                            disabled: _vm.downloadDisabled,
                            variant: "full",
                            id: `import-export-download-${_vm.componentName}`,
                            iconName: "icon-import-export-download-xls",
                          },
                          on: { click: _vm.download },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.getExportText))])]
                      )
                    : _vm._e(),
                  _vm.isUpdateActive
                    ? _c(
                        "Button",
                        {
                          attrs: {
                            size: "small",
                            variant: "full",
                            secondary: "",
                            id: `import-export-update-${_vm.componentName}`,
                            iconName: "icon-xls",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("Global.importExport_Update"))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }