<template lang="pug">
.selected-list
    Button(
      v-for="(item,index) in value",
      :key="index",
      seashell,
      size="medium"
      :id="`selected-${moduleName}-${index}`"
      @click="removeListItem(item)"
    )
      span {{ item[label] }}
      Icon.m-left-5(
        name="icon-popup-close"
        :id="`selected-${moduleName}-item-delete-${index}`"
      )
</template>

<script>

export default {
  name: 'SelectedList',
  props: {
    value: {},
    label: {
      type: String,
      default: 'name'
    },
    moduleName: {
      type: String,
      default: ''
    }
  },

  methods: {
    removeListItem (item) {
      this.value.splice(this.value.indexOf(item), 1)
      this.$emit('input', this.value)
    }
  },
}
</script>

<style lang="scss" scoped>

 .selected-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>
