<template lang="pug">
.Tableee-foot
    .info(v-html="footNote")

    .pagination
      .pagination-system(v-if="showPageSize" id="pagination-system-selection")
        label {{ $t('Global.goPaginationNumberSizeLabel') }}
        customSelect(
          v-model="page.size"
          :optionData="numberData"
          componentName="table-foot-select"
          :isDefaultTextActive="false"
          @inputSelected="onPageSizeChange"
        )
      // prev button
      .pagination-system(v-if="showPagination" id="pagination-system")
        label.seperator
        Button(
          id="btn-table-pagination-prev"
          type="button"
          primary
          size="xsmall"
          v-show="page.number > 1"
          :class="{ 'is-disable': !(page.number > 1) }"
          @click="onChangePage('prev')"
        ) {{ "<" }}

        Button.btn(
          type="button"
          primary
          size="xsmall"
          id="btn-table-pagination-number-1"
          v-if="showNumberFirst && !isPageLastUnder5"
          @click="onChangePage(1)"
        ) 1

        span.dot-Pagination(v-show="showNumberFirst && page.number - 1 !== 2 && !isPageLastUnder5") ...

        button.btn(type="button"
        :id="`btn-table-pagination-number-${p}`"
        v-for="p in pageLoop"
        :class="{ 'is-active': p === page.number }"
        @click="onChangePage(p)")
          | {{ p }}

        span.dot-Pagination(v-show="showNumberLast && page.number !== page.last - 2 && !isPageLastUnder5") ...

        Button(type="button"
        primary
        size="xsmall"
        :id="`btn-table-pagination-number-${1}`"
        v-if="showNumberLast && !isPageLastUnder5"
        @click="onChangePage(page.last)")
          | {{ page.last }}

        Button(
          type="button"
          primary
          size="xsmall"
          id="btn-table-pagination-next"
          v-show="page.number < page.last "
          :class="{ 'is-disable': page.number >= page.last }"
          @click="onChangePage('next')"
        ) {{ ">" }}

        label.seperator(for="inputValue") {{ $t('Global.goPaginationNumberLabel') }}

        input.no-spinners(type="number" step="any" name="inputValue" v-model="inputValue")

        Button(
          type="button"
          primary
          size="xsmall"
          id="btn-table-pagination-next"
          iconName="icon-table-next"
          iconClass="icon-table-next"
          :disabled="(inputValue < 1) || (+inputValue === +page.number) || (inputValue > page.last)"
          @click="onChangePage(inputValue)"
        )

</template>

<script>

import customSelect from '@/view/global/custom-select'

export default {
  name: 'TableFoot',
  props: {
    footNote: {
      type: String
    },
    page: {
      type: Object,
      default: {
        number: 1,
        size: 10,
        total: 0,
        last: 0
      }
    },
    numberData: {
      type: Array,
      default: () => [20, 50, 100]
    }
  },

  components: {
    customSelect
  },

  data () {
    return {
      inputValue: ''
    }
  },

  computed: {
    isPageLastUnder5 () {
      return this.page.last <= 5
    },
    pageLoop () {
      if (this.isPageLastUnder5) {
        return this.page.last
      } else {
        if (this.page.number === 1 || this.page.number === 2) {
          return [1, 2, 3]
        } else if (this.page.number === this.page.last - 1 || this.page.number === this.page.last) {
          return [this.page.last - 2, this.page.last - 1, this.page.last]
        } else {
          return [this.page.number - 1, this.page.number, this.page.number + 1]
        }
      }
    },

    showPagination () {
      return this.page.total > this.page.size
    },

    showPageSize () {
      return this.page.total > 20
    },

    showNumberFirst () {
      return this.page.number > 2
    },

    showNumberLast () {
      return this.page.last - this.page.number >= 2
    }
  },
  methods: {
    onChangePage (page) {
      this.$emit('delegateOnChangePage', page)
    },
    onPageSizeChange (pageSize) {
      this.$emit('delegateOnChangePageSize', pageSize)
    }
  }
}
</script>

<style scoped lang="scss">

  :deep() .select-dropdown-custom-selected {
    width: 80px!important;
    height: 40px!important;
  }
  :deep() .select-dropdown-custom-options {
    width: 80px!important;
  }
</style>
