<template lang="pug">
label.label(
  @mousedown="active(true)"
  @mouseup="toggle(false)"
  @mouseleave="active(false)"
  :id="`${id}-label`")

    input.input(
    type="radio"
    v-if="renderComponent"
    :checked="checked"
    :disabled="disabled"
    :id="`${id}-input`")

    span.indicator(
    :class="indicatorClassObject"
    @keydown.space.prevent="active(true)"
    @keyup.space.prevent="toggle(false)"
    :id="`${id}-label-indicator`"
    :tabindex="tabindex")
    span(
    :id="`${id}-label-text`"
    ) {{ label }}
</template>

<script>
export default {
  name: 'CustomRadio',

  model: {
    prop: 'checked',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isActive: false,
      renderComponent: true
    }
  },

  computed: {
    tabindex () {
      return this.disabled ? -1 : 0
    },

    indicatorClassObject () {
      return {
        active: this.isActive,
        bare: this.label === '',
        large: this.size === 'large'
      }
    }
  },

  methods: {
    active (isActive) {
      if (!this.disabled) {
        this.isActive = isActive
      }
    },

    toggle (isActive) {
      if (!this.disabled && this.isActive) {
        this.active(!!isActive)
        this.$emit('change', true)
        this.forceRerender()
      }
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    }
  }
}
</script>

<style scoped lang="scss">

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $color-light;
  }

  .input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    display: none;

    &:checked + .indicator {
      border: 1px solid $color-success;
      &::before {
        background: $color-success;
      }
    }

    &:disabled + .indicator,
    &:disabled:checked + .indicator {
      outline: none;
      background: $color-gray;
      border-color: $color-gray;
    }
  }

  .indicator {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    border: 2px solid $color-success;
    outline-color: $color-success !important;
    border-radius: 100%;
    background: #fff;

    &::before {
      content: "";
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &.active {
      background: $color-gray;
    }

    &:hover {
      background: #1ecf713f;
    }

    &.bare {
      margin: 0;
    }

    &.large {
      width: 27px;
      height: 27px;
    }

    &:focus {
      outline-width: 5px;
      outline-style: auto;
      outline-color: $color-success;
    }
  }

  .icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 8px;
    transform: translate(-50%, -50%);
    color: $color-success;
  }
</style>
