var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "Alert" }, [
          _c("div", { staticClass: "Alert-body" }, [
            _c(
              "figure",
              { staticClass: "Alert-icon" },
              [_c("Icon", { attrs: { name: "icon-global-warning" } })],
              1
            ),
            _c("h3", { staticClass: "Alert-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("p", {
              staticClass: "Alert-description",
              domProps: { innerHTML: _vm._s(_vm.errorMessage) },
            }),
            _vm.errorFields.length > 0
              ? _c(
                  "div",
                  { staticClass: "Alert-item" },
                  [
                    _c("TableView", {
                      staticClass: "Alert-table-view",
                      attrs: {
                        fields: _vm.errorFields,
                        data: _vm.errorData,
                        readOnly: true,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "Alert-item" },
              [
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      id: "btn-alert-ok",
                      size: "medium",
                      type: "button",
                    },
                    on: { click: _vm.closeTemplate },
                  },
                  [_vm._v(_vm._s(_vm.$t("Global.global_okey")))]
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }