<template lang="pug">
transition(name="slide")
    .Popup
      .Popup-overlay(@click="close")
      .Popup-body

        .Popup-header
          .content
            h3.Popup-header-title.word-break-word
              slot(name="title")
            slot(name="detail")
          Button.Popup-header-close(
          :disabled="disableCloseButton"
          id="btn-popup-close"
          variant="icon"
          type="button"
          iconName="icon-popup-close"
          iconClass="icon-popup-close"
          @click="close")

        .Popup-content
          slot(name="content")
        .Popup-footer
          slot(name="footer")
</template>

<script>

export default {
  name: 'Popup',
  props: ['status'],
  data () {
    return {
      disableCloseButton: false
    }
  },

  methods: {
    close () {
      this.disableCloseButton = true
      if (this.$route.matched.length > 0) {
        this.$router.push({
          name: this.$route.matched[0].name
        })
      }
      this.$emit('close', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .Popup-header-title{
    padding-right: 35px;
  }
</style>
