<template lang="pug">
.Logo
    router-link.Logo-link(:class="{'Mini-link' : MenuMinimalize}"
    to="/")
      Icon(name="simpra-logo" v-if="MenuMinimalize")
      img(
        v-else,
        src="./assets/logo.svg")
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Logo',
  computed: {
    ...mapGetters('Global', [
      'MenuMinimalize'
    ])
  }
}
</script>

<style lang="scss" scoped>

  .Logo {
    margin-bottom: 35px;
    height: 80px;
    &-link {
      display: block;
      text-align: center;
      padding: 40px 15px 6px 0;
      img {
        width: 164.4px;
        height: 46px;
      }
      &.Mini-link {
        padding: 24px 0px 6px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }
  .mini-logo-prtotel {
    width: max-content !important;
    height: auto !important;
  }
</style>
