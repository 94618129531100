<template lang="pug">
select.select(
    :class="{ 'is-danger': error }"
    id="custom-form-select-input"
    @change="submitSelect($event)"
    @click="getOptions"
  )
    option(
      value="-1"
      v-if="!hideDefault"
      :disabled="isDefaultDisable"
      :id="`${optionIdName}-select-input-unselected`") {{  (existingData && optionData.length === 0) ? existingData : defaultText }}

    option(
      v-for="data in optionData"
      :key="data[optionIdKey]"
      :value="data[optionKey]"
      :selected="selectedOption(data)"
      :id="`${optionIdName}-${data[optionIdKey]}`"
    )
      span {{ data[optionName] }}
</template>

<script>

export default {
  name: 'custom-select-input',
  data () {
    return {
      showOptions: false
    }
  },
  props: {
    value: {
      default: null
    },
    existingData: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDefault: {
      type: Boolean,
      default: false
    },
    optionData: {
      type: Array,
      default: () => []
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    optionName: {
      type: String,
      default: 'name'
    },
    optionIdName: {
      type: String,
      default: 'option-group'
    },
    optionIdKey: {
      type: String,
      default: 'id'
    },
    defaultText: {
      type: String,
      default () {
        return this.$t('Global.Multiselect_Unselected_Text')
      }
    },
    isValueNumber: {
      type: Boolean,
      default: false
    },
    isDefaultDisable: {
      type: Boolean,
      default: false
    },
    selectedDataRequired: {
      type: Boolean,
      default: false
    },
    extraDataEmit: {
      type: [Object, Array],
      default: null
    },
    isChangeValue: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    selectedOption (option) {
      return (this.value && this.value !== '-1') ? this.value === option[this.optionKey] : false
    },
    getOptions () {
      this.$emit('getOptions')
    },
    submitSelect (event) {
      const emitInput = event.target.value && event.target.value !== '-1'
        ? this.isValueNumber
          ? Number(event.target.value) : event.target.value
        : null

      if (this.isChangeValue) this.$emit('input', emitInput)
      if (this.selectedDataRequired) this.selectedDataEmit(emitInput)
      else this.$emit('change', emitInput)
    },
    selectedDataEmit (emitInput) {
      const findItem = this.findSelectedData(emitInput)
      let payload = { selectedItem: findItem }
      if (this.extraDataEmit) payload.extraDataEmit = this.extraDataEmit
      this.$emit('change', payload)
    },
    findSelectedData (emitInput) {
      return this.optionData.find(i => i[this.optionKey] === emitInput)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
