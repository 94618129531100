<template lang="pug">
input.txt(
    ref="customInputField"
    autocomplete="off"
    :class="{ 'is-danger': error }"
    :data-vv-delay="isDelayInput ? $getConst('INPUT_DELAY_TIME') : 0"
    type="text"
    :value="value"
    :disabled="disabled"
    @click="selectInput($event)"
    @input.stop="submitText($event)"
    @blur="submitInput($event, 'blur')"
  )
</template>

<script>

export default {
  name: 'custom-input',
  props: {
    value: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    isDelayInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // Bu fonksiyon proje içerisinde referans ile çağrılıyor.
    focusCustomInput () {
      this.$refs.customInputField.focus()
    },
    submitText (event) {
      this.$emit('input', event.target.value)
    },
    submitInput (event) {
      this.$emit('blur', event.target.value)
    },
    selectInput (event) {
      this.$emit('selectInput', event.target.value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
