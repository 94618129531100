var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pairs-popupOver" }, [
    _c(
      "ul",
      { staticClass: "pairs-list" },
      _vm._l(_vm.list, function (item, index) {
        return !item.dontShowRow
          ? _c("li", { key: index, staticClass: "pairs-list-item" }, [
              _c("span", { staticClass: "item-key" }, [
                _vm._v(_vm._s(item[_vm.itemKey])),
              ]),
              item.isCurrency
                ? _c("span", { staticClass: "item-value" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatCurrency")(item[_vm.itemValue]))
                    ),
                  ])
                : _c("span", { staticClass: "item-value" }, [
                    _vm._v(_vm._s(item[_vm.itemValue])),
                  ]),
            ])
          : _vm._e()
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }