<template lang="pug">
transition(name="zoom")
  .full-blur-background(
    v-bind="$attrs"
    v-on="$listeners"
  )
    slot
</template>
<script>

export default {

}
</script>

<style lang="scss">

  .zoom-enter-active,
  .zoom-leave-active {
    transition: 0.3s;
    opacity: 0;
    transform: scale(1.4);
  }

  .zoom-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .zoom-leave-to {
    transform: scale(0.6);
  }

  .full-blur-background {
    z-index: $z-index-md;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

  }

</style>
