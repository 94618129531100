<template lang="pug">
.box-content(:class="extraClass")
  .box-row.box-border-bottom(v-for="(field, index) in filteredField"
    v-if="field.headerTitle !== false && field.is_visible")
    span.title(:id="`box-list-${componentName}-${rowIndex}-title-${index}`") {{ field.short_title || '' }}
    template(v-if="field.type === 'currency'")
      span.ff-mono.u-textRight(:id="`box-list-${componentName}-${rowIndex}-currency-${index}`") {{ data[field.name] | formatCurrency }}
    template(v-else-if="field.type === 'phone'")
      span.ff-mono.u-textRight(:id="`box-list-${componentName}-${rowIndex}-phone-${index}`") {{ data[field.name] | formatPhone }}
    template(v-else-if="field.type === 'percent'")
      span.ff-mono.u-textRight(style="display: inline-block; width: 100%;" :id="`box-list-${componentName}-${rowIndex}-percent-${index}`") %{{ data[field.name] | percentFixed }}
    template(v-else-if="field.type === 'date'")
        span(:id="`box-list-${componentName}-row-${rowIndex}-date-${index}`") {{ data[field.name] ? new Date(data[field.name]).toLocaleDateString($i18n.locale) : '-'}}
    template(v-else-if="field.subname")
      span.value.mw-70(:id="`box-list-${componentName}-${rowIndex}-sub-names-${index}`") {{ getSubNames() || '-' }}
    template(v-else-if="field.type === 'portal'")
      portalTarget.portal(
        :name="field.name"
        :slot-props="row = setNewRow(data, rowIndex)"
      )
    template(v-else)
      span.value.m-left-5(:id="`box-list-${componentName}-${rowIndex}-value-${index}`") {{ data[field.name] || field.defaultValue || '-' }}

</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      default: []
    },
    data: {
      type: Object
    },
    componentName: {
      type: String,
      required: true
    },
    extraClass: {
      type: String,
      default: ''
    },
    rowIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    filteredField () {
      return this.fields.filter(field => !(field.headerSubTitle || field.headerTitle))
    }

  },
  methods: {
    getSubNames () {
      const field = this.fields.find(field => field.subname)
      return this.data[field.name].map(nl => nl[field.subname]).join(', ')
    },
    setNewRow (row, index) {
      return {index: index, ...row}
    }
  }
}
</script>

<style lang="scss" scoped>

  .box-flex-row{
    & .box-row{
      display: flex !important;
      justify-content: space-between !important;
      align-items: center;
      & .value{
        margin-top: 0px !important;
      }
    }
  }
  .m-auto{
    margin: auto;
  }

  .mw-70{
    max-width: 70%;
  }

  .box-tax{
    margin: auto;
    & .box-row{
      font-weight: $font-weight-bold;
      & .value{
        color: $color-primary !important;
        font-size: 55px !important;
        font-weight: $font-weight-bold;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        &::before{
          content: '%';
          font-size: 40px;
        }
      }
    }
  }
  .box-table .box-row{
    width: 50% !important;
    display: inline-grid !important;
    & span{
      text-align: right;
      padding-right: 25px;
      padding-top: 10px;
    }
    &:nth-child(2n+1){
      border-right: 1px solid $color-gray !important;
    }
    &:nth-child(5){
      border-bottom:0px !important;
    }
    &:not(:last-child){
      margin-bottom: 0px !important;
      padding-bottom: 7px !important;
    }
  }
  .box-row:not(:last-child){
    padding-bottom: 10px;
    margin-bottom: 7px;
  }
  .box-border-bottom:not(:last-child){
    border-bottom: 1px solid $color-gray;
  }
</style>
