<template lang="pug">
.pairs-popupOver
    ul.pairs-list
      li.pairs-list-item(
        v-for="(item, index) in list"
        v-if="!item.dontShowRow"
        :key="index")
        span.item-key {{ item[itemKey] }}
        span.item-value(v-if="item.isCurrency") {{ item[itemValue] | formatCurrency }}
        span.item-value(v-else) {{ item[itemValue] }}
</template>

<script>
/**
 * @param list = [] List
 * @param itemKey = key
 * @param itemValue = value
 */

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">

@import '../../../stylesheet/config/variables';

.pairs-popupOver {
  display: none;
  position: absolute;
  margin-top: 10px;
  z-index: 3;
  font-size: $font-size-small;
  box-shadow: 0 0 10px rgba(black, 0.2);
  background-color: white;
  border: 1px solid $color-gray;
  border-radius: $border-radius;
  min-width: 230px;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);

  .pairs-list {
    max-height: 270px;
    overflow-y: auto;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    pointer-events: none;
    left: 50%;
  }
  &::before {
    border: 8px solid;
    border-color: transparent transparent $color-primary transparent;
  }

  &::after {
    border: 7px solid;
    border-color: transparent transparent white transparent;
  }
  .pairs-list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .item {
      &-key {
        color: $color-success;
        white-space: nowrap;
        margin-right: 20px;
      }

      &-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;
        width: 50%;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray;
    }
  }
}

.relative-pairs-tooltip:hover {
  position: relative;

  .pairs-popupOver {
    display: block;
  }
}
</style>
