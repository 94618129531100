var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fields.length > 0 && _vm.renderComponent
    ? _c("div", [
        _c(
          "div",
          { staticClass: "NestedTable" },
          [
            _c(
              "div",
              { staticClass: "NestedTable-head", style: _vm.gridTemplate },
              _vm._l(_vm.fields, function (field, index) {
                return field.is_visible
                  ? _c(
                      "div",
                      {
                        staticClass: "NestedTable-head-col",
                        class: [
                          _vm.columnHeaderClassObject(field),
                          { "mono u-textRight": field.type === "currency" },
                        ],
                        style: field.style || "",
                        attrs: { id: `Nested-table-header-${index}` },
                      },
                      [
                        field.titlePortalName
                          ? [
                              _c("portalTarget", {
                                attrs: { name: field.titlePortalName },
                              }),
                            ]
                          : [
                              _c("span", [
                                _vm._v(_vm._s(field.short_title || "")),
                              ]),
                              field.withIcon
                                ? _c("Icon", {
                                    class: field.iconClass,
                                    attrs: { name: field.iconName },
                                  })
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  : _vm._e()
              }),
              0
            ),
            _vm._l(_vm.data, function (row, rowIndex) {
              return _c(
                "div",
                {
                  staticClass: "NestedTable-item",
                  class: [
                    { isOpened: row.showDetail },
                    { disabledRow: row.disabledRow },
                  ],
                  attrs: { id: `${_vm.moduleName}Table` },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "NestedTable-row",
                      class: { ["row-" + row.rowTextClass]: row.rowTextClass },
                      style: _vm.gridTemplate,
                      attrs: {
                        id: `${_vm.componentName}-nested-table-header-row-${rowIndex}`,
                      },
                    },
                    [
                      !row.dontShowSwitchIcon
                        ? _c("div", {
                            staticClass: "NestedTableHeaderLink",
                            attrs: {
                              id: `btn-nested-table-open-header-${rowIndex}`,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(row)
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "NestedTable-status-div" },
                        [
                          _vm.statusIcon && row.status === 1
                            ? _c("StatusTooltip", {
                                attrs: {
                                  title: _vm.statusText,
                                  icon: "icon-row-status",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.fields, function (field, fieldIndex) {
                        return field.is_visible
                          ? _c(
                              "div",
                              {
                                staticClass: "NestedTable-row-col",
                                class: [
                                  field.name,
                                  {
                                    "ff-mono u-textRight":
                                      field.type === "currency",
                                    "tooltip-relative":
                                      field.type === "supplier",
                                  },
                                  { [row.rowTextClass]: row.rowTextClass },
                                ],
                                style: field.style || "",
                                attrs: {
                                  id: `${_vm.moduleName}RowCol-${rowIndex}-${fieldIndex}`,
                                },
                              },
                              [
                                field.isEditable && field.isEditable(row)
                                  ? [
                                      field.type === "dropdown"
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.headerForm[
                                                            rowIndex
                                                          ][field.selectValue],
                                                        expression:
                                                          "headerForm[rowIndex][field.selectValue]",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          _vm.generateValidationRules(
                                                            field.validationRules,
                                                            row
                                                          ),
                                                        expression:
                                                          "generateValidationRules(field.validationRules, row)",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "txt table-input",
                                                    class: {
                                                      "is-danger":
                                                        _vm.veeErrors.has(
                                                          `select-header-${rowIndex}`
                                                        ),
                                                    },
                                                    attrs: {
                                                      tabindex: `${
                                                        rowIndex * fieldIndex
                                                      }`,
                                                      id: `select-nested-table-header-row-${rowIndex}`,
                                                      name: `select-header-${rowIndex}`,
                                                      disabled:
                                                        field.disabledStatus(
                                                          row
                                                        ),
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.headerForm[
                                                              rowIndex
                                                            ],
                                                            field.selectValue,
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.changeFieldValue(
                                                            $event.target.value,
                                                            field.selectValue,
                                                            row
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  _vm._l(
                                                    field.selectList.options,
                                                    function (item) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: item[
                                                            field.selectList
                                                              .value
                                                          ],
                                                          attrs: {
                                                            id: `option-nested-table-select-${rowIndex}`,
                                                          },
                                                          domProps: {
                                                            value:
                                                              item[
                                                                field.selectList
                                                                  .value
                                                              ],
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                field.selectList
                                                                  .label
                                                              ]
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c("Icon", {
                                                  staticClass:
                                                    "icon-arrow-down",
                                                  attrs: {
                                                    name: "icon-arrow-down",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : field.type === "currency"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-currency`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatCurrency2Digits")(
                                                row[field.name]
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : field.type === "status"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-statusText`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(field.getStatusText(row))
                                          ),
                                        ]
                                      ),
                                    ]
                                  : field.type === "mono"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ff-mono u-textRight",
                                          staticStyle: {
                                            display: "inline-block",
                                            width: "100%",
                                          },
                                          attrs: {
                                            id: `${
                                              _vm.moduleName
                                            }-${rowIndex}-${fieldIndex}-${
                                              row[field.name]
                                            }`,
                                          },
                                        },
                                        [_vm._v(_vm._s(row[field.name]))]
                                      ),
                                    ]
                                  : field.type === "portal"
                                  ? [
                                      _c("portalTarget", {
                                        attrs: {
                                          name: field.name,
                                          "slot-props": (_vm.header = {
                                            row,
                                            rowIndex,
                                          }),
                                        },
                                      }),
                                    ]
                                  : field.type === "phone"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ff-mono",
                                          attrs: {
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-phonr`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatPhone")(
                                                row[field.name]
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : field.type === "with-badge"
                                  ? [
                                      _vm._v(_vm._s(row[field.name])),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "row-badge",
                                          class: [
                                            field.typeData(row).className,
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-badgeText`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  field.typeData(row).badgeText
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : field.type === "percent"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ff-mono u-textRight",
                                          staticStyle: {
                                            display: "inline-block",
                                            width: "100%",
                                          },
                                          attrs: {
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-mono`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "%" +
                                              _vm._s(
                                                _vm._f("percentFixed")(
                                                  row[field.name]
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : field.type === "date"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-dateValue`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              !row[field.name]
                                                ? "-"
                                                : new Date(
                                                    row[field.name]
                                                  ).toLocaleDateString(
                                                    _vm.$i18n.locale
                                                  )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : field.type === "supplier"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-tooltip`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getSupplierName(
                                                row[field.name]
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      row[field.name] &&
                                      row[field.name].length > 25
                                        ? _c("Tooltip", {
                                            attrs: {
                                              white: "",
                                              right: "",
                                              text: row[field.name],
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : field.type === "spacer"
                                  ? [_c("span")]
                                  : [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            id: `${
                                              _vm.moduleName
                                            }-${rowIndex}-${fieldIndex}-${
                                              row[field.name]
                                            }`,
                                          },
                                        },
                                        [_vm._v(_vm._s(row[field.name] || "-"))]
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                      _c("div", { staticClass: "NestedTable-row-col action" }, [
                        _c(
                          "div",
                          { staticClass: "body" },
                          [
                            _vm.actions.indexOf("single-action-portal-start") >
                            -1
                              ? [
                                  _c("portalTarget", {
                                    attrs: {
                                      name: "single-action-portal-start",
                                      "slot-props": (_vm.header = {
                                        row,
                                        rowIndex,
                                      }),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            !row.dontShowSwitchIcon
                              ? _c("Button", {
                                  attrs: {
                                    size: "small",
                                    variant: "icon c-success btn-switch",
                                    justIcon: true,
                                    iconName: "icon-arrow-down",
                                    iconClass: "icon-arrow-down",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetail(row)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.actions.indexOf("inspect") > -1
                              ? _c("Button", {
                                  attrs: {
                                    size: "small",
                                    variant: "icon bgc-gray c-light",
                                    justIcon: true,
                                    id: `btn-nested-table-inspect-header-${rowIndex}`,
                                    iconName: "icon-global-inspect",
                                    iconClass: "icon-global-inspect",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.inspectHeader(row)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.useHeaderPortalAction
                              ? _c(
                                  "div",
                                  [
                                    _c("portalTarget", {
                                      attrs: {
                                        name: "actionPortal",
                                        "slot-props": (_vm.rows = { row }),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.readOnly && !_vm.useHeaderPortalAction
                              ? _c("ActionButton", {
                                  attrs: {
                                    data: _vm.actions,
                                    item: row,
                                    cannotBeViewed: row.docType === 2,
                                    showExportOnly:
                                      row.status !== 1 &&
                                      _vm.actions.indexOf("export") > -1,
                                    customActionName: _vm.customActionName,
                                    customActionIconName:
                                      _vm.customActionIconName,
                                    customActionIconClass:
                                      _vm.customActionIconClass,
                                  },
                                  on: {
                                    onItemCustomAction: function ($event) {
                                      return _vm.onItemCustomAction(row)
                                    },
                                    onItemDelete: function ($event) {
                                      return _vm.deleteHeader(row)
                                    },
                                    onItemExport: function ($event) {
                                      return _vm.showExportPopup(row)
                                    },
                                    onItemEdit: function ($event) {
                                      return _vm.editHeader(row)
                                    },
                                    onItemOverview: function ($event) {
                                      return _vm.showItem(row)
                                    },
                                    onItemChangeActiveStatus: function (
                                      $event
                                    ) {
                                      return _vm.changeActiveStatus(row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("nested-detail", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: row.showDetail,
                        expression: "row.showDetail",
                      },
                    ],
                    ref: "nestedDetail",
                    refInFor: true,
                    attrs: {
                      tableKey: _vm.tableKey,
                      rowData: row,
                      isFinalizeAction: _vm.isFinalizeAction,
                      isLoading: _vm.isDetailLoading,
                      detailFields: _vm.detailFields,
                      addButtonText: _vm.addButtonText,
                      finalizeButtonText: _vm.finalizeButtonText,
                      readOnly: _vm.readOnly,
                      readOnlyForDetail: _vm.readOnlyForDetail,
                      canAddNewDetail: _vm.canAddNewDetail,
                      detailActions: _vm.detailActions,
                      newRow: _vm.newRow,
                      isDetailDataLoading: _vm.isDetailDataLoading,
                      activateNewRow: _vm.activateNewRow,
                      extraButtonTexts: _vm.extraButtonTexts,
                      selectedRows: _vm.selectedRows,
                      selectableAll: _vm.selectableAll,
                      selectable: _vm.selectable,
                      showDetailWithEmptyData: _vm.showDetailWithEmptyData,
                      usePortalFooter: _vm.usePortalFooter,
                      usePortalAction: _vm.usePortalAction,
                      dontUseScroll: _vm.dontUseScroll,
                      openStatus: row.showDetail,
                      generateValidationRules: _vm.generateValidationRules,
                      isLoadingFinalize: _vm.isLoadingFinalize,
                      headerRowIndex: rowIndex,
                      isActionButtonsVisible: _vm.isActionButtonsVisible,
                      disableNewDetailButton: _vm.disableNewDetailButton,
                      disableFinalizeButton: _vm.disableFinalizeButton(row),
                    },
                    on: {
                      addDetailAction: _vm.addNewDetail,
                      finalizeAction: _vm.makeFinalize,
                      detailExtraAction: _vm.detailExtraAction,
                      addNewData: _vm.addNewData,
                      detailExtraActionSecound: _vm.detailExtraActionSecound,
                      editDetailAction: _vm.editDetail,
                      deleteDetailAction: _vm.deleteDetail,
                      showDetailExportPopup: _vm.showDetailExportPopup,
                      detailChangeAction: _vm.detailChangeAction,
                      configureRow: _vm.configureRow,
                      selectAllItems: _vm.selectAllItems,
                      selectItem: _vm.selectItem,
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
        _vm.page
          ? _c(
              "div",
              { staticClass: "NestedTable-footer m-top-20" },
              [
                _c("TableFoot", {
                  attrs: { page: _vm.page },
                  on: {
                    delegateOnChangePage: _vm.onChangePage,
                    delegateOnChangePageSize: _vm.onChangePageSize,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }