<template lang="pug">
.multiselect-radio-field
    .inputfield
      CustomRadio.popup-item-check(
        v-for="(radioItem, index) in data",
        :key="`radio-item-${radioItem.value}`",
        :disabled="radioItem.disabled || disableAll",
        :id="`radio-${componentName}-item-${index}`",
        :label="radioItem.text",
        :checked="radioItem.value === value",
        @change="changeRadioButton(radioItem.value)")

</template>
<script>


export default {
  name: 'MultiselectCustomRadio',

  props: {
    value: {},
    disableAll: {
      type: Boolean,
      default: false
    },
    data: { type: Array }, //  items => text, value, disabled (optional) object

    componentName: {
      type: String,
      default: 'global'
    }
  },
  data () {
    return {

    }
  },

  methods: {
    changeRadioButton (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">

  .multiselect-radio-field {
    .inputfield {
      display: flex;
      label {
        margin-right: 20px;
        color: $color-dark;
        font-weight: 500;
        .indicator {
          width: 20px !important;
          height: 20px !important;
        }
        .icon {
          width: 11px;
          height: 8px;
        }
      }
    }
  }
</style>
