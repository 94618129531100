var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeTotalCountsDropdownStatus,
          expression: "closeTotalCountsDropdownStatus",
        },
      ],
      staticClass: "total-counts",
    },
    [
      _c(
        "button",
        {
          staticClass: "total-counts-button",
          attrs: { id: "button-total-counts-popup" },
          on: { click: _vm.changeTotalCountsDropdownStatus },
        },
        [
          _vm.isLoading
            ? [_c("Loading")]
            : [
                _c("span", { staticClass: "total-counts-button-title" }, [
                  _vm._v(_vm._s(_vm.$t(_vm.title))),
                ]),
                _c("Icon", { attrs: { name: "icon-down-arrow" } }),
              ],
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "total-counts-items",
          class: { "show-total-counts": _vm.showTotalCountPopup },
        },
        _vm._l(_vm.totalData, function (item) {
          return _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "item-key" }, [
                _vm._v(_vm._s(item.key)),
              ]),
              [
                item.type === 0
                  ? _c("div", { staticClass: "item-value ff-mono" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatCurrency2Digits")(item.value))
                      ),
                    ])
                  : _c("div", { staticClass: "item-value ff-mono" }, [
                      _vm._v("%" + _vm._s(_vm._f("percentFixed")(item.value))),
                    ]),
              ],
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }