var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.hasMessage && !_vm.error
      ? _c(
          "div",
          { staticClass: "Notify" },
          [
            _c(
              "span",
              { staticClass: "Notify-icon" },
              [_c("Icon", { attrs: { name: "icon-notify-icon" } })],
              1
            ),
            _c("span", {
              staticClass: "Notify-messages",
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
            _c("Button", {
              staticClass: "Notify-close",
              attrs: {
                id: "btn-notify-hide",
                type: "button",
                variant: "icon",
                iconName: "icon-notify-close",
                iconClass: "icon-notify-close",
                justIcon: true,
              },
              on: { click: _vm.notifyHide },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }