<template lang="pug">
businessMultiselect(
    componentName="multiselect-over-group-filter"
    :multiselectItems="groupList"
    :label="groupLabel"
    :useUnselectedList="useUnselectedList"
    :useCustomEmptyState="useCustomEmptyState"
    :customEmptyState="customEmptyState"
    :popupHeader="$t('Global.toolbar_Group_Label')"
    :unselectedText="$t('Global.toolbar_Filters_Unselected')"
    ref="businessGroupFilter"
    @submitFilter="submitFilter")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {

  data () {
    return {
      groupFilter: [],
      groupList: [],
      groupLabel: ''
    }
  },

  props: {
    groupType: {
      type: String,
      default: 'overGroup'
    },
    useUnselectedList: {
      type: Boolean,
      default: false
    },
    useCustomEmptyState: {
      type: Boolean,
      default: false
    },
    customEmptyState: {
      type: String,
      default: ''
    },
  },

  components: {
    businessMultiselect
  },

  async beforeMount () {
    if (this.groupType === 'overGroup') {
      this.groupLabel = this.$t('Global.toolbar_Over_Group_Label')
      await this.getOverGroupAll().then(res => { this.groupList = res.data.overGroupList })
    }
    if (this.groupType === 'majorGroup') {
      this.groupLabel = this.$t('Global.toolbar_Major_Group_Label')
      await this.getMajorGroupAll().then(res => { this.groupList = res.data.majorGroupList })
    }
    if (this.groupType === 'itemGroup') {
      this.groupLabel = this.$t('Global.toolbar_Item_Group_Label')
      await this.getItemGroupAll().then(res => { this.groupList = res.data.itemGroupList })
    }
    this.useUnselectedList ? this.groupFilter = [] : this.groupFilter = [...this.groupList]
  },

  beforeDestroy () {
    this.RESET()
  },

  methods: {
    ...mapMutations('Group', ['RESET']),
    ...mapActions('Group', ['getMajorGroupAll', 'getItemGroupAll', 'getOverGroupAll']),

    submitFilter ({ list, dontSubmit }) {
      this.$emit('submitFilter', {list, dontSubmit})
    },

    clearGroupFilter (dontSubmit = true) {
      this.$refs.businessGroupFilter.clearAllFilter(dontSubmit)
    }
  }
}
</script>
