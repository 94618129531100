<template lang="pug">
.dual-radio-status
    .dual-radio-status-text {{ labelForHeader }}
    .dual-radio-status-radio
      CustomRadio.popup-item-check(
        :disabled="disabled",
        :id="`radio-${componentName}-user-status-${labelForIsActive}`",
        :label="labelForIsActive",
        :checked="isActive",
        @change="changeRadioButton(true)")

      CustomRadio.popup-item-check(
        :disabled="disabled",
        :id="`radio-${componentName}-user-status-${labelForIsPassive}`",
        :label="labelForIsPassive",
        :checked="!isActive",
        @change="changeRadioButton(false)")
</template>
<script>

export default {
  name: 'DualCustomRadio',

  props: {
    headerLabel: {
      type: String,
      default: ''
    },

    componentName: {
      type: String,
      default: 'global'
    },

    isActive: {
      type: Boolean,
      required: true
    },

    isPassiveLabel: {
      type: String,
      default: ''
    },

    isActiveLabel: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },

  computed: {
    labelForHeader () {
      return this.headerLabel || this.$t('Global.DualRadioButtonsHeaderLabel')
    },

    labelForIsPassive () {
      return this.isPassiveLabel || this.$t('Global.DualRadioButtonsIsPassive')
    },

    labelForIsActive () {
      return this.isActiveLabel || this.$t('Global.DualRadioButtonsIsActive')
    }
  },

  methods: {
    changeRadioButton (booleanValue) {
      this.$emit('delegateIsActiveValue', booleanValue)
    }
  }
}
</script>

<style lang="scss" scoped>

.dual-radio-status {
  display: flex;
  width: 100%;
  height: 49px;
  margin-top: 49px;
  align-items: center;
  justify-content: space-between;
  background-color: white!important;
  border: 1px solid $color-gray;
  border-radius: $border-radius;

  &-text {
    font-family: $font-family;
    font-size: $font-size-micro;
    font-weight: $font-weight-bold;
    color: $color-success;
    margin-left: 10px;
  }

  &-radio {
    display: flex;

    label {
      font: $font-size-small $font-family;
      margin-right: 10px;
    }
  }
}
</style>
