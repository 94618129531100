var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "App" },
    [
      _c("Alertbox", { ref: "Alertbox" }),
      _c("Icons"),
      _c("Notify"),
      _c("ErrorTemplate"),
      _c("Sidebar", { attrs: { showSalesWarning: _vm.suspendSalesExist } }),
      _c(
        "div",
        {
          staticClass: "Body",
          class: [!_vm.MenuMinimalize ? "Expand" : "MiniBody"],
        },
        [_vm.hasPermission ? _c("router-view") : _c("NoPermission")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }