var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("businessMultiselect", {
    ref: "businessGroupFilter",
    attrs: {
      componentName: "multiselect-over-group-filter",
      multiselectItems: _vm.groupList,
      label: _vm.groupLabel,
      useUnselectedList: _vm.useUnselectedList,
      useCustomEmptyState: _vm.useCustomEmptyState,
      customEmptyState: _vm.customEmptyState,
      popupHeader: _vm.$t("Global.toolbar_Group_Label"),
      unselectedText: _vm.$t("Global.toolbar_Filters_Unselected"),
    },
    on: { submitFilter: _vm.submitFilter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }