var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notification" },
    [
      _c(
        "button",
        {
          staticClass: "notification-button",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.changePopupStatus()
            },
          },
        },
        [
          _c("Icon", {
            staticClass: "notification-icon",
            attrs: { name: "icon-global-bell" },
          }),
        ],
        1
      ),
      _vm.isPopupOpen
        ? _c("notificationDetail", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.hideNotificationPopup,
                expression: "hideNotificationPopup",
              },
            ],
            attrs: {
              notiData: _vm.notiData,
              isNotificationLoading: _vm.isNotificationLoading,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }