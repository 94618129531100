var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-type" }, [
    _c(
      "div",
      {
        staticClass: "firstNode",
        class: { "c-primary": _vm.firstSelected },
        on: { click: _vm.firstNodeClick },
      },
      [
        _c("Icon", {
          staticClass: "m-right-10",
          attrs: { name: _vm.firstNodeIcon },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "divider" }),
    _c(
      "div",
      {
        staticClass: "secondNode",
        class: { "c-primary": !_vm.firstSelected },
        on: { click: _vm.secondNodeClick },
      },
      [
        _c("Icon", {
          staticClass: "m-left-10",
          attrs: { name: _vm.secondNodeIcon },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }