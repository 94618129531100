var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.text
    ? _c("div", { staticClass: "appTooltip", class: _vm.variants }, [
        _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }