var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "VehicleLoading" }, [
    _c("img", {
      attrs: { src: _vm.loading, width: _vm.size, height: _vm.size },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }