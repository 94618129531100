<template lang="pug">
transition(
    name ="zoom"
  )
    .Alert
      .Alert-body
        figure.Alert-icon
          Icon(:name ="icon")
        h3.Alert-title(id="alert-title") {{ title }}
        p.Alert-description(id="alert-description")
          template(v-if ="tableName")
            | {{ tableName }}
          template(v-else)
            | {{ description || $t('Global.deleteAlert_WillDelete_Description') }}

        Loading(v-if="isGetDataLoading")

        .Alert-item(v-if="fields.length > 0 || !isGetDataLoading")
          table.Tableee-root(id="table-root")
            thead.Tableee-head(id="table-head")
              tr.Tableee-row
                th.Tableee-col(
                    v-for="(field, index) in fields"
                    :key="field.name"
                    v-if="field.is_visible"
                    :id="`table-head-col-${index}`"
                ) {{ field.short_title }}
            tbody.Tableee-body(id="table-body")
              tr.Tableee-row
                td.Tableee-col(
                  v-for="(field, index) in fields"
                  :key="field.name"
                  v-if="field.is_visible"
                  :id="`table-body-col-${index}`"
                )
                  template(v-if="field.is_currency") {{ getFieldValue(field.name) | formatCurrency }}
                  template(v-else) {{ getFieldValue(field.name) }}

        .Alert-actions
            Button.m-right-10(
              secondary
              v-if="hasCancelButton"
              id="btn-delete-alert-cancel"
              type="button",
              ref="focusedButton"
              size="medium"
              variant="full"
              @click="handleCancel"
            )
              span {{ cancelButton || $t('Global.alert_CancelButton') }}

            Button.m-left-10(
              primary
              id="btn-delete-alert-ok"
              type="button",
              size="medium"
              variant="full"
              @click="handleOk",
              :disabled="isLoading"
            )
              span(
                v-show="!isLoading"
              ) {{ okButton || $t('Global.alert_OkButton') }}
              Loading(
                theme="disable"
                v-show ="isLoading"
              )
</template>

<script>

import { getObjectValue } from '@/utils/baseOperations'

export default {
  name: 'Alert',

  props: {
    title: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    tableName: {
      type: String,
      default: null
    },

    item: {
      type: Object
    },

    fields: {
      type: Array,
      default: () => []
    },

    loading: {
      type: String,
      default: ''
    },

    isGetDataLoading: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: ''
    },

    okButton: {
      type: String,
      default: ''
    },

    cancelButton: {
      type: String,
      default: ''
    },
    hasCancelButton: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isLoading () {
      if (this.loading === '') return false
      return this.$wait.is([this.loading])
    }
  },

  mounted () {
    this.$refs.focusedButton.focus()
  },

  methods: {
    getFieldValue (fieldName) {
      return getObjectValue(fieldName, this.item)
    },

    handleCancel () {
      this.$emit('delegateOnAlertCancel')
    },

    handleOk () {
      this.$emit('delegateOnAlertOk')
    }
  }
}
</script>

<style lang="scss" scoped>

  .zoom-enter-active,
  .zoom-leave-active {
    transition: 0.3s;
    opacity: 0;
    transform: scale(1.4);
  }

  .zoom-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .zoom-leave-to {
    transform: scale(0.6);
  }

  .Alert {
    z-index: $z-index-md;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    &-body {
      text-align: center;
    }

    &-icon {
      margin-bottom: 30px;

      svg {
          width: 56px;
          height: 56px;
          color: #FE5A37;
      }
    }

    &-title {
      font-size: 1.6em;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 7px;
      color: $color-text-light;
    }

    &-item {
      margin-top: 40px;
      max-width: 900px;
      box-shadow: 0 2px 6px rgba(black, 0.05);
      background-color: white;
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
