var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dual-radio-status" }, [
    _c("div", { staticClass: "dual-radio-status-text" }, [
      _vm._v(_vm._s(_vm.labelForHeader)),
    ]),
    _c(
      "div",
      { staticClass: "dual-radio-status-radio" },
      [
        _c("CustomRadio", {
          staticClass: "popup-item-check",
          attrs: {
            disabled: _vm.disabled,
            id: `radio-${_vm.componentName}-user-status-${_vm.labelForIsActive}`,
            label: _vm.labelForIsActive,
            checked: _vm.isActive,
          },
          on: {
            change: function ($event) {
              return _vm.changeRadioButton(true)
            },
          },
        }),
        _c("CustomRadio", {
          staticClass: "popup-item-check",
          attrs: {
            disabled: _vm.disabled,
            id: `radio-${_vm.componentName}-user-status-${_vm.labelForIsPassive}`,
            label: _vm.labelForIsPassive,
            checked: !_vm.isActive,
          },
          on: {
            change: function ($event) {
              return _vm.changeRadioButton(false)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }