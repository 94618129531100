var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("textarea", {
    ref: "customTextareaField",
    staticClass: "txt",
    class: { "is-danger": _vm.error },
    attrs: { autocomplete: "off", rows: "5" },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        $event.stopPropagation()
        return _vm.submitText($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }