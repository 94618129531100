var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-multiselect",
      attrs: { id: _vm.id ? _vm.id : _vm.componentName },
    },
    [
      _c("div", { staticClass: "Form-item dropdown-handle" }, [
        _c("span", { ref: "dropdownHandleLabel" }),
        _c(
          "div",
          {
            staticClass: "select dropdown-handle-button",
            class: [
              _vm.hasValues ? "has-values" : "",
              _vm.isDisabled ? "disabled-button" : "",
            ],
            attrs: {
              id: `custom-multiselect-show-button-${
                _vm.componentName || "random"
              }`,
              "aria-controls": _vm.uniquePopupId,
              "aria-expanded": _vm.isPopupVisible,
              tabindex: "0",
            },
            on: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.togglePopup.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _vm.togglePopup.apply(null, arguments)
                },
              ],
              click: _vm.togglePopup,
            },
          },
          [
            _c(
              "div",
              {
                class: _vm.showCountBadge ? "dropdown-handle-button-right" : "",
              },
              [
                _c("Icon", {
                  staticClass: "dropdown-handle-button-right-icon",
                  class: { open: _vm.isPopupVisible },
                  attrs: { name: "icon-down-arrow" },
                }),
                _vm.showCountBadge
                  ? _c(
                      "div",
                      { staticClass: "dropdown-handle-button-right-badge" },
                      [
                        _vm._v(
                          "(" +
                            _vm._s(
                              _vm.selected.length === _vm.items.length
                                ? _vm.$t("Global.Select_Default_Text")
                                : _vm.selectedDataCounter
                            ) +
                            ")"
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            !_vm.customEmptyState
              ? _c("div", { staticClass: "dropdown-handle-button-values" }, [
                  _vm._v(
                    _vm._s(
                      _vm.showSelectedName ? _vm.selected[0]?.name : _vm.label
                    )
                  ),
                ])
              : _vm._e(),
            _vm.useCustomEmptyState && _vm.customEmptyState
              ? _c("div", { staticClass: "dropdown-handle-button-values" }, [
                  _vm.useCustomEmptyState && _vm.customEmptyState
                    ? _c("span", [_vm._v(_vm._s(_vm.customEmptyState))])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.isPopupVisible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hidePopup,
                  expression: "hidePopup",
                },
                {
                  name: "shortkey",
                  rawName: "v-shortkey",
                  value: ["esc"],
                  expression: "['esc']",
                },
              ],
              staticClass: "MiniPopup popup",
              class: _vm.useCustomLocation ? "custom" : "left",
              style: _vm.popupStyle,
              attrs: { id: _vm.uniquePopupId },
              on: { shortkey: _vm.hidePopup },
            },
            [
              _vm.isLoadingData ? _c("Loading") : _vm._e(),
              !_vm.isLoadingData
                ? _c("div", [
                    _c("h2", { staticClass: "popup-header" }, [
                      _vm._v(_vm._s(_vm.popupHeader)),
                    ]),
                    _vm.getSearch
                      ? _c(
                          "div",
                          { staticClass: "Search" },
                          [
                            _c("Icon", {
                              staticClass: "Search-icon",
                              attrs: { name: "icon-search" },
                            }),
                            _vm.search
                              ? _c("Button", {
                                  staticClass: "Search-close",
                                  attrs: {
                                    variant: "icon",
                                    iconName: "icon-popup-close",
                                    iconClass: "icon-popup-close",
                                    id: `btn-clear-${_vm.id}`,
                                  },
                                  on: { click: _vm.clearSearch },
                                })
                              : _vm._e(),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.search,
                                  expression: "search",
                                },
                              ],
                              staticClass: "txt Search-txt",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: `input-search-${_vm.id}`,
                                placeholder: _vm.$t(
                                  "Global.Filter_SearchPlaceholder"
                                ),
                              },
                              domProps: { value: _vm.search },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.search = $event.target.value
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "popup-content" },
                      [
                        _vm.showAll && _vm.type === "checkbox" && !_vm.search
                          ? _c(
                              "div",
                              { staticClass: "MiniPopup-tr popup-row" },
                              [
                                _vm.items.length !== 0
                                  ? _c("CustomCheckbox", {
                                      staticClass: "popup-item-check",
                                      attrs: {
                                        id: `checkbox-multiselect-all-${_vm.id}`,
                                        checked: _vm.isAllSelected,
                                        label: _vm.allLabel,
                                      },
                                      on: { change: _vm.toggleAll },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "d-flex m-left-10" },
                                      [
                                        _c("Icon", {
                                          attrs: {
                                            name: "icon-global-warning",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "c-warning m-left-10",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Global.Filter_Empty_State"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _vm.showAll && _vm.type === "radio"
                          ? _c(
                              "div",
                              { staticClass: "MiniPopup-tr popup-row" },
                              [
                                _c("CustomRadio", {
                                  staticClass: "popup-item-check",
                                  attrs: {
                                    id: `radio-multiselect-all-${_vm.id}`,
                                    checked: _vm.isAllSelected,
                                    label: _vm.allLabel,
                                  },
                                  on: { change: _vm.toggleAll },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(_vm.searchItems, function (item, index) {
                          return _c(
                            "div",
                            { staticClass: "MiniPopup-tr popup-row" },
                            [
                              _c(_vm.inputType, {
                                tag: "div",
                                staticClass: "popup-item-check",
                                attrs: {
                                  id: `${_vm.type}-multiselect-item-${_vm.id}-${index}`,
                                  checked:
                                    _vm.selected.indexOf(item) > -1 ||
                                    _vm.selected.some(
                                      (selectedItem) =>
                                        selectedItem[_vm.itemUniqKey] ===
                                        item[_vm.itemUniqKey]
                                    ),
                                  label: item.name,
                                  badged: item[_vm.badgedTextField],
                                },
                                on: {
                                  change: (isChecked) =>
                                    _vm.selectItem(item, isChecked),
                                },
                              }),
                              _vm.withBadgedText
                                ? _c("span", { staticClass: "badged" }, [
                                    _vm._v(_vm._s(item[_vm.badgedTextField])),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _vm.showFooter
                      ? _c(
                          "div",
                          { staticClass: "popup-footer" },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  id: "btn-custom-multiselect-submit",
                                  variant: "full",
                                  primary: "",
                                  size: "small",
                                  disabled: _vm.isBtnDisabled,
                                },
                                on: { click: _vm.submitPopup },
                              },
                              [
                                _vm.buttonIconLeft
                                  ? _c(
                                      "svg",
                                      {
                                        staticClass: "icon icon-left",
                                        class: {
                                          [`icon-${_vm.buttonIconLeft}`]: true,
                                        },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": `#icon-${_vm.buttonIconLeft}`,
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(_vm._s(_vm.buttonText)),
                                _vm.buttonIconRight
                                  ? _c(
                                      "svg",
                                      {
                                        staticClass: "icon icon-left",
                                        class: {
                                          [`icon-${_vm.buttonIconRight}`]: true,
                                        },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": `#icon-${_vm.buttonIconRight}`,
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }