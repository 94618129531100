<template lang="pug">
p.Form-item-help.is-danger {{ errorName }}
</template>

<script>

export default {
  name: 'error-container',
  props: {
    errorName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">

</style>
