<template lang="pug">
.approval-levels-with-history
    .approval-levels(@click="showHistoryPopup" v-click-outside="hideHistoryPopup")
      .levels(v-for="(level, index) in maxApprovalLevel" :class="getClass(currentApprovalLevel, level)")
        span {{level}}
    .history-popup(v-if="showHistory")
      Loading(v-if="isLoading")
      TableView.table(
        :data="historyList"
        v-if="historyList.length > 0 && !isLoading"
        :fields="historyFields"
        :readOnly="true"
      )
</template>

<script>
export default {
  name: 'ApprovalLevels',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    currentApprovalLevel: {
      type: Number,
      default: 0
    },
    maxApprovalLevel: {
      type: Number,
      default: 0
    },
    approvalStatus: {
      type: Number,
      default: 0
    },
    historyList: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      showHistory: false
    }
  },

  computed: {
    historyFields () {
      return [
        {
          name: 'createdBy',
          is_visible: true,
          short_title: this.$t('ApproveOperations.historyPopup_createdBy'),
          title: this.$t('ApproveOperations.historyPopup_createdBy')
        },
        {
          name: 'createdOn',
          type: 'date',
          is_visible: true,
          short_title: this.$t('ApproveOperations.historyPopup_createdOn'),
          title: this.$t('ApproveOperations.historyPopup_createdOn')
        },
        {
          name: 'approvalLevel',
          is_visible: true,
          short_title: this.$t('ApproveOperations.historyPopup_approvalLevel'),
          title: this.$t('ApproveOperations.historyPopup_approvalLevel')
        },
        {
          name: 'isApproved',
          is_visible: true,
          short_title: this.$t('ApproveOperations.historyPopup_approvalStatus'),
          title: this.$t('ApproveOperations.historyPopup_approvalStatus')
        },
        {
          name: 'description',
          is_visible: true,
          short_title: this.$t('ApproveOperations.historyPopup_rejectionDescription'),
          title: this.$t('ApproveOperations.historyPopup_rejectionDescription')
        },
        {
          name: 'isOutOfOffice',
          is_visible: true,
          short_title: this.$t('ApproveOperations.historyPopup_workingStatus'),
          title: this.$t('ApproveOperations.historyPopup_workingStatus')
        }
      ]
    }
  },

  methods: {
    getClass (current, level) {
      switch (this.approvalStatus) {
        case 1:
          if (level <= current) return 'levels-success'
          break
        case 2:
          if (level <= current) return 'levels-success'
          break
        case 3:
          if (level < current) return 'levels-success'
          if (level === current) return 'levels-danger'
          break
      }
    },
    showHistoryPopup () {
      if (this.showHistory === false) this.$emit('showHistoryPopup')
      this.showHistory = !this.showHistory
    },
    hideHistoryPopup () {
      this.showHistory = false
    }
  }
}
</script>

<style lang="scss" scoped>

  .approval-levels-with-history {
    position: relative;
  }
  .approval-levels{
    display: flex;
    align-content: flex-start;
    width: 100%;
    z-index: $z-index-xxl !important;
    cursor: pointer;
  }
  .levels{
    &:nth-child(1) {
      padding: 5px 7px;
    }
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    margin: 1px;
    padding: 5px 6px;
    color: $color-op-gray;
    font-size: $font-size-micro;
    font-family: $font-family;
    background: $color-light-gray;
    &-success{
      color: $color-success;
      border: 1px solid $color-success;
      background: rgba(30, 207, 114, 0.2);
    }
    &-danger{
      background: $color-warning;
      border: 1px solid $color-red;
      color: $color-red;
    }
  }

  .history-popup{
    max-width: auto;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: $z-index-xxxl;
  }
</style>
