<template lang="pug">
.accordion-list
    slot
</template>

<script>
export default {
  data () {
    return {
      AccordionListData: {
        count: 0,
        active: null
      }
    }
  },

  provide () {
    return { AccordionListData: this.AccordionListData }
  }

}
</script>

<style lang="scss">

</style>
