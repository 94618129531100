var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "approval-levels-with-history" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.hideHistoryPopup,
            expression: "hideHistoryPopup",
          },
        ],
        staticClass: "approval-levels",
        on: { click: _vm.showHistoryPopup },
      },
      _vm._l(_vm.maxApprovalLevel, function (level, index) {
        return _c(
          "div",
          {
            staticClass: "levels",
            class: _vm.getClass(_vm.currentApprovalLevel, level),
          },
          [_c("span", [_vm._v(_vm._s(level))])]
        )
      }),
      0
    ),
    _vm.showHistory
      ? _c(
          "div",
          { staticClass: "history-popup" },
          [
            _vm.isLoading ? _c("Loading") : _vm._e(),
            _vm.historyList.length > 0 && !_vm.isLoading
              ? _c("TableView", {
                  staticClass: "table",
                  attrs: {
                    data: _vm.historyList,
                    fields: _vm.historyFields,
                    readOnly: true,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }