<template lang="pug">
aside.Modal(v-if="isOpen",
  v-shortkey="['esc']",
  @shortkey="close")
    .Modal-Overlay(@click="close")
    .Modal-Body
      .Modal-Body-header
        h3.title
          slot(name="title")
        slot(name="description")
        Button.modal-close-button(
          :justIcon="true",
          v-if="showCloseButton"
          iconName="icon-popup-close",
          @click="close"
        )
      .Modal-Body-content
        slot(name="content")
      .Modal-Body-footer
        slot(name="footer")
</template>
<script>
export default {
  name: 'Modal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close () {
      this.$emit('closeModal', this.isOpen)
    }
  }
}
</script>
<style lang="scss" scoped>

.Modal {
  &, &-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $z-index-md;
  }
  &-Overlay {
    background-color: #000;
    transition-duration: .4s;
    animation: modal-fade .4s forwards;
    opacity: .5;
  }
  &-Body {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: $z-index-md;
    transform: translateY(-50%) translateX(-50%);
    background: white;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    transition-duration: .4s;
    animation: slide-body-top .4s forwards;
    border-radius: $border-radius;
    padding: 20px;
    &-header {
      position: relative;
      margin-bottom: 20px;
      .title {
        font-size: 26px;
        font-weight: bold;
      }
      .modal-close-button {
        position: absolute;
        right: 0;
        top:0;
        height: 1em;
        width: 1em;
        padding: 0;
        border-radius: 0;
        font-size: 26px;
      }
    }
    &-content {
      height: 50vh;
      width: 50vw;
      overflow-y: scroll;
    }
    &-footer {
      box-shadow: 0 -5px 16px 0 rgba(0, 0, 0, 0.02);
      padding-top: 18px;
    }
  }
}

@keyframes modal-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.53;
  }
}

@keyframes slide-body-top {
  0% {
   transform: translateX(-50%) translateY(-100%);
  }
  100% {
     transform: translateX(-50%)  translateY(-50%);
  }
}

</style>
