var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multiselect-radio-field" }, [
    _c(
      "div",
      { staticClass: "inputfield" },
      _vm._l(_vm.data, function (radioItem, index) {
        return _c("CustomRadio", {
          key: `radio-item-${radioItem.value}`,
          staticClass: "popup-item-check",
          attrs: {
            disabled: radioItem.disabled || _vm.disableAll,
            id: `radio-${_vm.componentName}-item-${index}`,
            label: radioItem.text,
            checked: radioItem.value === _vm.value,
          },
          on: {
            change: function ($event) {
              return _vm.changeRadioButton(radioItem.value)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }