<template lang="pug">
.help-button-area
  button.help-button.m-right-10(
    id="btn-help",
    type="button"
    size="xsmall"
    @click="goHelpCenter"
  )
    Icon.help-icon(name="icon-help")
    span.m-left-10(id="show-help-center" v-show="showHelpCenterText") {{$t('Page.header_helpcenter')}}
</template>

<script>

export default {
  name: 'Help',

   props: {
    showHelpCenterText: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    goHelpCenter () {
      if (this.$route.name === 'HelpCenter') return
      this.$router.push({
        name: 'HelpCenter',
        params: {
          routeName: this.currentRouteName
        }
      })
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.meta.page
    }
  }
}
</script>

<style lang="scss" scoped>
  .help-button-area {
    &:hover {
      .help-button {
        color: $color-white;
      }
    }
  }

  .help-icon {
    margin-bottom: 4px;
    width: 16px;
    height: 16px;
  }
  .help-button {
    color: $color-text-light;
    font-weight: $font-weight-normal;
  }

</style>
