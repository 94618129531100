var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "title-box",
      class: { active: _vm.active },
      attrs: { id: `title-box-${_vm.componentName}-${_vm.boxIndex}` },
      on: {
        click: function ($event) {
          return _vm.onClick(_vm.data, "View")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "title-box-top tooltip-relative" },
        [
          _c(
            "div",
            {
              staticClass: "title",
              attrs: { id: `title-list-${_vm.componentName}-${_vm.boxIndex}` },
            },
            [
              _c(
                "div",
                { staticClass: "icon-container" },
                [
                  _vm.icon
                    ? _c("Icon", {
                        staticClass: "mr-5",
                        attrs: { name: _vm.icon },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "p",
                { attrs: { id: `title-${_vm.componentName}-${_vm.boxIndex}` } },
                [_vm._v(_vm._s(_vm.getTitle(_vm.title)) + "       ")]
              ),
            ]
          ),
          _vm.title.length >= _vm.tooltipCharacterSize && _vm.showTooltip
            ? _c("Tooltip", {
                staticClass: "m-top-25",
                attrs: {
                  id: `title-tooltips-${_vm.componentName}`,
                  white: "",
                  text: _vm.title,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("ActionButton", {
            attrs: { data: _vm.actions, item: _vm.data },
            on: {
              onItemDelete: function ($event) {
                return _vm.delegateOnItemRemove(_vm.data)
              },
              onItemEdit: function ($event) {
                return _vm.delegateOnItemEdit(_vm.data)
              },
            },
          }),
        ],
        1
      ),
      _vm.description
        ? _c("div", { staticClass: "title-box-bottom m-top-10" }, [
            _c("div", { staticClass: "description" }, [
              _c(
                "p",
                {
                  staticClass: "box-description",
                  attrs: {
                    id: `description-${_vm.componentName}-${_vm.boxIndex}`,
                  },
                },
                [_vm._v(_vm._s(_vm.description))]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }