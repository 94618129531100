var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "label tooltip-relative",
      attrs: { id: "row-" + _vm.id },
      on: {
        mousedown: function ($event) {
          return _vm.active(true)
        },
        mouseup: function ($event) {
          return _vm.toggle(false)
        },
        mouseleave: function ($event) {
          return _vm.active(false)
        },
      },
    },
    [
      _c("input", {
        staticClass: "input",
        attrs: {
          type: "checkbox",
          disabled: _vm.disabled,
          id: `${_vm.id}-input`,
        },
        domProps: { checked: _vm.checked },
      }),
      _c(
        "span",
        {
          staticClass: "indicator",
          class: _vm.indicatorClassObject,
          attrs: { tabindex: _vm.tabindex, id: `${_vm.id}-indicator` },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.active(true)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.toggle(false)
            },
          },
        },
        [_c("Icon", { attrs: { name: "icon-custom-checkbox-tick" } })],
        1
      ),
      _vm._v(_vm._s(_vm.getFieldName(_vm.label))),
      _vm.label.length > 30 && _vm.useTooltipForLabel
        ? _c("Tooltip", { attrs: { white: "", bottom: "", text: _vm.label } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }