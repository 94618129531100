<template lang="pug">
iframe.secret-iframe(
    ref="pdfIframe"
    type="application/pdf"
    frameborder="0"
    :src="printPdf"
    @load="takePrint"
    v-if="checkPdfLoad"
  )
</template>
<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
export default {
  name: 'export-popup',
  props: {
    templateId: {
      type: String,
      required: true
    },
    templateType: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      printPdf: null,
      checkPdfLoad: false
    }
  },
  mounted () {
    this.printTable()
  },
  methods: {
    ...mapActions('Global', [
      'getTemplateExport'
    ]),
    printTable: vueWaitLoader(async function () {
      const payload = {
        templateType: this.templateType,
        id: this.templateId,
        timeOffset: Math.abs(new Date().getTimezoneOffset())
      }
      await this.getTemplateExport(payload).then(result => {
        this.checkPdfLoad = true
        this.printPdf = window.URL.createObjectURL(result.data, { type: 'application/pdf' })
      })
    }, 'getTemplateExportPdf'),
    takePrint () {
      if (this.checkPdfLoad) {
        this.$refs.pdfIframe.contentWindow.print()
      }
    }
  },
  watch: {
    templateId () {
      this.printTable()
    }
  }
}
</script>
<style lang="scss" scoped>

  .secret-iframe {
    display: none;
    align-items: stretch;
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>
