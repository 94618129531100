<template lang="pug">
div(v-if="renderComponent && data.length > 0")
    Button.action-button.p-bottom-15(
      variant="icon small c-light"
      id="btn-table-show-actions-header"
      @click.stop="showActionBar(item)"
    )
      span ...
        .action-box(v-if="item?.showActions && showActions" v-click-outside="() => closeActionBar(item)")
          Button(
            v-if="(shouldShow('overview') && !showExportOnly && !cannotBeViewed)"
            id="`btn-table-overview`",
            size="small"
            @click.stop="onItemOverview",
            iconName="icon-login-eye",
            iconClass="icon-login-eye"
          )
            span {{ $t('Global.overview')}}

          Button(
            v-if="(shouldShow('separateInvoice') && !showExportOnly)"
            id="`btn-table-separate-invoice`",
            size="small"
            @click.stop="onItemSeparateInvoice",
            iconName="icon-separate-invoice",
            iconClass="icon-separate-invoice"
          )
            span {{ $t('Global.separateInvoice')}}

          Button(
            v-if="shouldShow('export')"
            id="btn-export-action"
            type="button"
            size="small"
            iconName="icon-export",
            iconClass="icon-export"
            @click.stop="onItemExport"
          )
            span {{ $t('Global.export')}}

          Button(
            v-if="shouldShow('copy') && !showExportOnly"
            id="`btn-nested-table-copy`",
            size="small"
            @click.stop="onItemCopy",
            iconName="icon-global-copy",
            iconClass="icon-global-copy"
          )
            span {{ $t('Global.copy')}}

          Button(
            v-if="shouldShow('edit') && !showExportOnly"
            id="`btn-nested-table-edit`",
            size="small"
            @click.stop="onItemEdit",
            iconName="icon-global-edit",
            iconClass="icon-global-edit"
          )
            span {{ $t('Global.edit')}}

          Button(
            v-if="shouldShow('active') && !showExportOnly"
            id="`btn-table-activate`",
            size="small"
            @click.stop="onItemChangeActiveStatus",
            :iconName="!item.isActive ? `icon-global-active-recipe` : `icon-global-passive-recipe`"
            :iconClass="!item.isActive ? `icon-global-active-recipe` : `icon-global-passive-recipe`"
          )
            span(v-if="item.isActive") {{ $t('Global.passive')}}
            span(v-else) {{ $t('Global.activate')}}

            
          Button(
            v-if="(shouldShow('remove') || shouldShow('delete')) && !showExportOnly"
            id="`btn-table-delete`",
            size="small"
            @click.stop="onItemDelete",
            iconName="icon-global-remove",
            iconClass="icon-global-remove"
          )
            span {{ $t('Global.delete')}}

          Button(
            v-if="shouldShow('customAction') && !showExportOnly"
            id="`btn-table-custom-action`",
            size="small"
            @click.stop="onItemCustomAction",
            :iconName="customActionIconName"
            :iconClass="customActionIconClass"
          )
            span {{ customActionName }}


</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ActionButton',

  data () {
    return {
      renderComponent: true
    }
  },
  computed: {
    ...mapGetters('Global', ['showActions','prevActionValue'])
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    item: {
      type: Object
    },
    showExportOnly: {
      type: Boolean,
      defaul: false
    },
    cannotBeViewed: {
      type: Boolean,
      default: false
    },
    customActionIconName: {
      type: String,
      default: 'icon-navigation-transfer'
    },
    customActionIconClass: {
      type: String,
      default: 'icon-navigation-transfer'
    },
    customActionName: {
      type: String,
      default: ''
    }
  },
  mounted (){
    this.SET_SHOW_ACTION_STATUS({prevActionValue:null, value:false})
  },

  methods: {
    ...mapMutations('Global', ['SET_SHOW_ACTION_STATUS']),
    shouldShow (item) {
      return (
        !this.data.length || this.data.indexOf(item) > -1
      )
    },
    showActionBar (item) {
      if (this.prevActionValue && this.prevActionValue !== item || !this.prevActionValue) {
        this.closeActionBar(this.prevActionValue)
        item.showActions = !item.showActions
        this.SET_SHOW_ACTION_STATUS({prevActionValue:item, value:true})
        this.forceRerender()
      } else {
        item.showActions = !item.showActions
        this.SET_SHOW_ACTION_STATUS({prevActionValue:item, value:true})
        this.forceRerender()
      }
    },
    closeActionBar (item) {
      this.SET_SHOW_ACTION_STATUS({prevActionValue:item, value:false})
      if (item) {
        item.showActions = false
      }
      this.forceRerender()
    },
    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    onItemCopy (item) {
      this.$emit('onItemCopy', item)
    },

    onItemExport (item) {
      this.$emit('onItemExport', item)
    },

    onItemOverview (item) {
      this.$emit('onItemOverview', item)
    },

    onItemSeparateInvoice (item) {
      this.$emit('onItemSeparateInvoice', item)
    },

    onItemEdit (item) {
      this.$emit('onItemEdit', item)
    },

    onItemDelete (item) {
      this.$emit('onItemDelete', item)
    },

    onItemChangeActiveStatus (item) {
      this.$emit('onItemChangeActiveStatus', item)
    },

    onItemCustomAction (item) {
      this.$emit('onItemCustomAction', item)
    },

    onItemShow (item) {
      this.$emit('onItemShow', item)
    }

  }

}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";
  .action-button {
    font-size: 25px;
    color: #00171f;
  }
  :deep() .icon-export {
    margin-right: 4px !important;
    width: 20px !important;
    height: 20px !important;
  }
  :deep() .icon-separate-invoice {
    height: 20px !important;
    width: 19px !important;
  }
  :deep() .icon-navigation-transfer {
    height: 20px !important;
    width: 20px !important;
    margin-right: 6px !important;
  }
  .action-box {
    @include MiniPopupMixin(
      $max-width: 100px,
      $margin: 5px 0 0 0,
      $triangle-direction: 'right',
      $padding-hor: 0,
      $padding-ver: 0,
      $border-color: #d6dddf
    );
    max-width: 200px;
    width: 150px;
    height: auto;
    position: absolute;
    background: white;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: none;
    border: solid 1px #d6dddf;

    Button {
      font-size: 14px;
      color: #636261;
      font-weight: $font-weight-normal;
      width: 100%;
      padding: 15px 10px;
      justify-content: start;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d6dddf;
      &:hover {
        background-color: #cacaca2a;
      }
    }
}
</style>
