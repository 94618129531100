<template lang="pug">
section.datepicker-ui(v-click-outside="close", :class="{'range': range}" ref="datepickerCalendar")
    button.input-field(:disabled="disabled" @click='open()', type="button", id="datepicker-main-button")
      .input(:class='[inputClass]')
        Icon.icon-left(:name="inputIcon", v-if="inputIcon")
        span(v-if="!isLoading && calendarType==='month'") {{ formattedValue}}
        span(v-if="!isLoading && calendarType!=='month'") {{ useFilterReset && !this.selectedDate ? $t('Global.selectDate') : formattedValue }}
        Loading(v-if="isLoading")
      .icon-right
        Icon.arrow(:name="inputIconRight", v-if="inputIconRight")

    .calendar(v-if="isShowPicker", :class="[calcBottomOfThePage() ? 'top' : position]")
      .pickers
        .picker
          Button.filter-reset(
            v-if="useFilterReset"
            iconName="icon-navigation-transfer"
            :justIcon="true"
            :clearVariant="true"
            :class="!selectedDate ? 'disabled' : '' "
            :disabled="!selectedDate"
            @click="resetDateFilter"
          )
          .header(v-if="calendarType !== 'month'")
            button.prevDateButton(
            type="button",
            id="datepicker-prev-button",
            @click="prevMount('start')")
              Icon(name="icon-arrow-left")

            button(type="button")
                | {{ this.calendar.months[currentDate.month].name }}
                | {{ this.currentDate.year }}
            button.nextDateButton(
            type="button",
            id="datepicker-next-button",
            @click="nextMount('start')")
              Icon(name="icon-arrow-right")
          .header(v-else)
            button.prevDateButton(
            type="button",
            id="datepicker-prev-button",
            @click="prevYear")
              Icon(name="icon-arrow-left")

            button(type="button")
                | {{ this.calendar.years[currentDate.year] }}
                | {{ this.currentDate.year }}
            button.nextDateButton(
            type="button",
            id="datepicker-next-button",
            @click="nextYear")
              Icon(name="icon-arrow-right")


          .content(v-if="calendarType==='day'")
            .days#datepicker-start-days
              .day.name(v-for='day in calendar.days' :key='`${day.dayNumber}-day`' :id='`${day.dayNumber}-day`') {{ day.name }}
              button.day.square(
              :class="[{'disabledDate': !mDay.isDayInMouth || !mDay.isUsable}, {'selectedDate': range ? formatDate(selectedDate[0]) === $options.filters.dateFormat(mDay.fullDate, 'DD.MM.YYYY') : formatDate(selectedDate) === $options.filters.dateFormat(mDay.fullDate, 'DD.MM.YYYY') }, {'selectedRange': isInSelectedDate(mDay.fullDate)}]",
              v-for='(mDay,index) in calendar.daysOfMonth'
              :key='`${index}-mounthday`'
              :id='`mounthday-${index}`'
              @click="handlerDate(mDay.fullDate,'start')"
              type="button")
                span.number {{ mDay.day }}
        
          .month-picker(v-if="calendarType==='month'")
            .month-name(v-for="month in calendar.months")
              Button.month(
                :clearVariant="true",
                :class="customSelectedDate?.month === month.index ? 'month-selected' : ''"
                @click="selectCustomDate(month)"
              )
                span {{month.name}}

        .picker(v-if="range")

          .header
            button.prevDateButton(
            type="button",
            id="datepicker-two-prev-button",
            @click="prevMount('end')")
              Icon(name="icon-arrow-left")
            button(type="button", @click="calendarEndView = 'months'")
                | {{ this.calendarEnd.months[currentDateEnd.month].name }}
                | {{ this.currentDateEnd.year }}
            button.nextDateButton(
            type="button",
            id="datepicker-two-next-button"
            @click="nextMount('end')")
              Icon(name="icon-arrow-right")

          .content
            .days#datepicker-end-days
              .day.name(v-for='day in calendarEnd.days' :key='`${day.dayNumber}-day-end`' :id='`${day.dayNumber}-day-end`') {{ day.name }}
              button.day.square(
              :class="[{'disabledDate': !mDay.isDayInMouth || !mDay.isUsable}, {'selectedDate': formatDate(selectedDate[1]) === $options.filters.dateFormat(mDay.fullDate, 'DD.MM.YYYY') }, {'selectedRange': isInSelectedDate(mDay.fullDate)}]",
              v-for='(mDay,index) in calendarEnd.daysOfMonth'
              :key='`${index}-mounthday-end`',
              :id='`mounthday-end-${index}`'
              @click="handlerDate(mDay.fullDate,'end')"
              type="button")
                span.number {{ mDay.day }}

      .calendar-footer(v-if="isFilterPicker")
        .area
          .value
            | {{ formattedValue }}
          .action
            Button(
              primary,
              size="medium"
              id="datepicker-action-button"
              @click="emitInputAction"
              iconName="icon-btn-success"
            )
              span {{ $t('Global.apply') }}
</template>

<script>
import Calendar from './datepicker'
import moment from 'moment'

export default {
  name: 'Calendar',
  data () {
    return {
      isShowPicker: false,
      currentDate: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate(),
        firstDayOfWeek: this.firstDayOfWeek
      },
      currentDateEnd: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate(),
        firstDayOfWeek: this.firstDayOfWeek
      },
      textFormat: 'short',
      dateFormat: { day: '2-digit', month: 'short', year: 'numeric' },
      selectedDate: this.range ? [null, null] : null,
      customSelectedDate: null,
      calendarView: 'days',
      calendarEndView: 'days'
    }
  },
  props: {
    value: {},
    isLoading: {
      type: Boolean,
      default: false
    },
    inputIcon: {
      type: String,
      default: 'icon-datepicker'
    },
    inputIconRight: {
      type: String,
      default: 'icon-down-arrow'
    },
    useFilterReset: {
      type: Boolean,
      default: false
    },
    calendarType: {
      type: String,
      default: 'day'
    },
    range: {
      type: Boolean,
      default: false
    },
    pickerType: {
      type: String,
      default: 'filter',
      validator: (value) => {
        return ['filter', 'manuel'].indexOf(value) > -1
      }
    },
    inputClass: {
      type: String,
      default: 'txt'
    },
    disabledStartDate: {
      type: Object,
      default () {
        return {
          from: null,
          to: null
        }
      }
    },
    disabledEndDate: {
      type: Object,
      default () {
        return {
          from: null,
          to: null
        }
      }
    },
    firstDayOfWeek: {
      type: String,
      default: 'monday'
    },
    position: {
      type: String,
      default: 'right'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isCheckingPosition: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    calendar () {
      return new Calendar(
        this.currentDate,
        this.$i18n.locale,
        this.textFormat,
        { ...this.dateFormat },
        this.range ? this.disabledStartDateCalc : this.disabledStartDate
      )
    },
    calendarEnd () {
      if (!this.range) return false
      return new Calendar(
        this.currentDateEnd,
        this.$i18n.locale,
        this.textFormat,
        { ...this.dateFormat },
        this.disabledEndDateCalc
      )
    },

    isFilterPicker () {
      return this.pickerType === 'filter'
    },

    disabledStartDateCalc () {
      let unSelectedDate = {
        from: null,
        to: null
      }
      if (this.range) {
        let disabledDate = new Date(this.selectedDate[1])
        disabledDate = (!this.disabledStartDate.from || disabledDate.getTime() < this.disabledStartDate.from.getTime()) ? disabledDate : this.disabledStartDate.from
        unSelectedDate.from = disabledDate
        unSelectedDate.to = this.disabledStartDate.from
      }
      return unSelectedDate
    },
    disabledEndDateCalc () {
      let unSelectedDate = {
        from: null,
        to: null
      }
      if (this.range) {
        let disabledDate = new Date(this.selectedDate[0])
        disabledDate = (!this.disabledEndDate.to || disabledDate.getTime() > this.disabledEndDate.to.getTime()) ? disabledDate : this.disabledEndDate.to
        unSelectedDate.to = disabledDate
        unSelectedDate.from = this.disabledEndDate.from
      }
      return unSelectedDate
    },
    formattedValue () {
      if (this.calendarType === 'month') {
        return this.formatDate(this.customSelectedDate)
      }
      if (!this.range) {
        return this.formatDate(this.selectedDate)
      }
      return `${this.formatDate(this.selectedDate[0])} ~ ${this.formatDate(this.selectedDate[1])}`
    }
  },
  methods: {
    calcElementOffset () {
      let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      )
      // If component is close to the bottom add class to it so it can open upside down.
      // Take element offset, relative to the document with sum it with window.pageYOffset
      return this.$refs.datepickerCalendar.getBoundingClientRect().bottom + window.pageYOffset + 400 > scrollHeight
    },
    calcBottomOfThePage () {
      if (!this.isCheckingPosition) return false
      // Calc page height including scroll area
      return this.calcElementOffset()
    },
    selectCustomDate (date) {
      this.calendar.currentDate.month = date.index
      this.calendar.currentDate.monthName = date.name
      this.calendar.currentDate.date = 1
      this.customSelectedDate = this.calendar.currentDate
      this.$emit('getDate', this.customSelectedDate)
    },
    formatDate (value) {
      return this.calendarType === 'month' ? moment(value).format('MMM YYYY') : moment(value).format('DD.MM.YYYY')
    },
    prevMount (picker) {
      const currentDate = picker === 'start' ? this.currentDate : this.currentDateEnd
      currentDate.month = currentDate.month - 1
      if (currentDate.month === -1) {
        currentDate.year = currentDate.year - 1
        currentDate.month = 11
      }
    },
    nextMount (picker) {
      const currentDate = picker === 'start' ? this.currentDate : this.currentDateEnd
      currentDate.month = currentDate.month + 1
      if (currentDate.month === 12) {
        currentDate.year = currentDate.year + 1
        currentDate.month = 0
      }
    },
    prevYear () {
      const currentDate = this.currentDate
      currentDate.year = currentDate.year - 1
    },
    nextYear () {
      const currentDate = this.currentDate
      currentDate.year = currentDate.year + 1
    },
    resetDateFilter () {
      this.$emit('resetDateFilter')
    },
    handlerDate (fullDate, picker = null) {
      if (!this.range) {
        this.setDate(fullDate)
        return
      }
      const selectedDates = [
        picker === 'start' ? fullDate : this.selectedDate[0],
        picker === 'end' ? fullDate : this.selectedDate[1]
      ]
      this.setDate(selectedDates)
    },
    setDate (selectedDates) {
      this.selectedDate = selectedDates
      if (!this.isFilterPicker) this.emitInputAction()
    },

    emitInputAction () {
      this.$emit('input', this.selectedDate)
      this.close()
    },

    isInSelectedDate (date) {
      return this.selectedDate ? new Date(this.selectedDate[0]).getTime() <= date.getTime() && new Date(this.selectedDate[1]).getTime() >= date.getTime() : new Date().getTime() <= date.getTime() && new Date().getTime() >= date.getTime()
    },
    open () {
      this.isShowPicker = !this.isShowPicker
    },
    close () {
      this.isShowPicker = false
    },
    setYears (route, picker) {
      if (picker === 'start') {
        const year = route === 'prev' ? this.calendar.years[0] - 11 : route === 'next' ? this.calendar.years[10] + 1 : ''
        this.currentDate.year = year
      } else if (picker === 'end') {
        const year = route === 'prev' ? this.calendarEnd.years[0] - 11 : route === 'next' ? this.calendarEnd.years[10] + 1 : ''
        this.currentDateEnd.year = year
      }
    },
    setMouth (route, picker) {
    },
    selectYear (year, picker) {
      if (picker === 'start') {
        this.currentDate.year = year
        this.calendarView = 'months'
      } else if (picker === 'end') {
        this.currentDateEnd.year = year
        this.calendarEndView = 'months'
      }
    },
    selectSpecialDate (route, rangeDate) {
      if (route === 'next') {
        this.currentDate.year = new Date().getFullYear()
        this.currentDate.month = new Date().getMonth()
        this.currentDate.date = new Date().getDate()
        const nextDay = new Date(this.currentDate.year, this.currentDate.month, this.currentDate.date + rangeDate)
        this.currentDateEnd.year = new Date(nextDay).getFullYear()
        this.currentDateEnd.month = new Date(nextDay).getMonth()
        this.currentDateEnd.date = new Date(nextDay).getDate()
        const startDate = new Date()
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + rangeDate)
        this.handlerDate(startDate, 'start')
        this.handlerDate(endDate, 'end')
      }
    },
    setCurrents () {
      if (this.range) {
        this.currentDate.year = new Date(this.value[0]).getFullYear()
        this.currentDate.month = new Date(this.value[0]).getMonth()
        this.currentDate.date = new Date(this.value[0]).getDate()
        this.currentDateEnd.year = new Date(this.value[1]).getFullYear()
        this.currentDateEnd.month = new Date(this.value[1]).getMonth()
        this.currentDateEnd.date = new Date(this.value[1]).getDate()
      } else {
        if (this.useFilterReset) this.selectedDate = null
        this.selectedDate ? this.currentDate.year = new Date(this.value).getFullYear() : this.currentDate.year = new Date().getFullYear()
        this.selectedDate ? this.currentDate.month = new Date(this.value).getMonth() : this.currentDate.month = new Date().getMonth()
        this.selectedDate ? this.currentDate.date = new Date(this.value).getDate() : this.currentDate.date = new Date().getDate()
      }
    }
  },
  mounted () {
    if (this.calendarType === 'month') {
      this.customSelectedDate = this.currentDate
    }
    this.setCurrents()
    this.setDate(this.value)
    this.$watch('value', () => {
      this.setCurrents()
      this.setDate(this.value)
    })

    this.$watch('selectedDate', (value) => {
      if (!value && this.value === value) return
      this.$emit('change', value)
    })
  }
}
</script>

<style lang="scss" scoped>

  $picker-item-text-color: $color-dark;
  $item-font-weight: $font-weight-normal;
  $picker-content-bg: #fff;
  $picker-content-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  $selectedDate: $color-light-blue;
  $item-size: 40px;
  $picker-content-padding: 20px;
  $disabledColor: $color-light;
  $dayNameColor: $color-light;
  $selectedRange: #edf8ff;

  .datepicker-ui {
    color: $picker-item-text-color;
    position: relative;

    .txt{
      min-height: 48px;
    }

    &.is-danger {
      .input-field {
        border-color: $color-warning;
        &:focus {
          border-color: $color-warning;
        }
      }
    }

    .input-field {
      position: relative;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      min-width: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      background: $color-white;
      flex: 1;
      width: 100%;
      &:focus {
        border-color: $color-success;
      }
      // .datepickerIcon {
      //   width: 16px;
      //   height: 16px;
      //   margin-right: 5px;
      //   margin-left: 10px;
      // }
      .icon-left {
        margin-right: 10px;
      }
      .icon-datepicker {
        width: 16px;
        height: 16px;
      }

      .icon-right {
        margin-left: 10px;
        color: $color-success;
        .icon-down-arrow {
          width: 10px;
          height: 10px;
        }
      }
      .input {
        border: none;
        display: flex;
        align-items: center;
        flex-grow: 0;
        color: inherit;
        font-weight: 500;
      }
    }

    .calendar {
      z-index: $z-index-xs;
      position: absolute;
      background: $picker-content-bg;
      box-shadow: $picker-content-shadow;
      padding: $picker-content-padding;
      border: 1px solid $color-gray;
      border-radius: 4px;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        background: inherit;
      }

      &.right {
        top: calc(100% + 10px);
        right:0;
        &::before {
          border: 1px solid $color-gray;
          border-width: 1px 0 0 1px;
          top: -5px;
          right: 20px;
          transform: rotate(45deg);
        }
      }

      &.top {
        bottom: calc(100% + 10px);
        right: 0;
         &::before {
          border: 1px solid $color-gray;
          border-width: 1px 0 0 1px;
          bottom: -5px;
          right: 20px;
          transform: rotate(225deg);
        }
      }

      &.center {
        top: 0;
        left: calc(100% + 20px);
        &::before {
          border: 1px solid $color-gray;
          border-width: 1px 0 0 1px;
          left: -5px;
          transform: rotate(-45deg);
        }
      }

      &.left {
        top: calc(100% + 10px);
        &::before {
          border: 1px solid $color-gray;
          border-width: 1px 0 0 1px;
          top: -5px;
          left: 20px;
          transform: rotate(45deg);
        }
      }
      &.top-left {
        bottom: calc(100% + 10px);
         &::before {
          border: 1px solid $color-gray;
          border-width: 1px 0 0 1px;
          bottom: -5px;
          left: 20px;
          transform: rotate(225deg);
        }
      }

      &-footer {
        border-top: 1px solid $color-gray;
        margin-top: 20px;
        padding-top: 20px;
        .area {
          height: 64px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $color-gray;
          border-radius: $border-radius;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .pickers {
        display: flex;
        button {
          color: inherit;
          font-weight: $item-font-weight;
        }
      }
      .picker {
        width: $item-size * 7;
        position: relative;
        .filter-reset {
          position: absolute;
          right: -6px;
          top: 0;
          color: $color-light;
        }

        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 49px;
          .nextDateButton,
          .prevDateButton {
            padding: 0;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-light;
            .icon {
              width: 7px;
              height: 13px;
            }
          }
          .nextDateButton {
            margin-left: 15px;
          }
          .prevDateButton {
            margin-right: 15px;
          }
        }
        .content {
          .days {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .day {
              width: $item-size;
              text-align: center;
              font-size: .95em;
              font-weight: inherit;
              &.name {
                color: $dayNameColor;
                padding-top: 10px;
                padding-bottom: 10px;
              }
              &.square {
                height: $item-size;
                .number {
                  display: flex;
                  height: inherit;
                  align-items: center;
                  justify-content: center;
                }
              }

              &.selectedDate {
               .number {
                  background: $selectedDate;
                  border-radius: 50%;
                  color: #fff;
                }
              }

              &.selectedRange {
                &:not(.selectedDate) {
                  background: $selectedRange;
                }
                &.selectedDate {
                  position: relative;
                  background: $selectedRange;
                  .number {
                    z-index: $z-index-xs;
                  }
                }
              }
              &.disabledDate {
                color: $disabledColor;
                cursor: not-allowed;
                pointer-events: none;
                &.selectedRange {
                  background: $selectedRange;
                }
                &.selectedDate {
                  .number {
                    background: $selectedDate;
                    border-radius: 50%;
                  }
                }
              }
              &:hover {
                &:not(.selectedDate) {
                  .number {
                    background: $color-light-warning;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.range {
      .input-field {
        .input {
          font-size: $font-size-micro;
          font-weight: 300;
          color: $color-dark;
        }
      }
      .picker {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  :deep() .icon-navigation-transfer {
    width: 20px !important;
    height: 20px !important;
  }
  .disabled {
    color: $color-gray !important;
    cursor: unset !important;
  }
  .month-picker {
    display: flex;
    flex-wrap: wrap;
  }
  .month-name {
    width: 33%;
    margin-top: 5px;
    text-align: center;
  }
  .month {
    width: 50%;
    padding: 10px;
    &:hover {
      background: $color-light-warning;
      border-radius: 50%;
    }
    &-selected {
      background: $selectedDate;
      border-radius: 50%;
    }
  }
</style>
