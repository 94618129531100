<template lang="pug">
.notification-menu.right
    TableLoading(v-if="isNotificationLoading")
    .notification-menu-container(v-else)
      .notification-menu-header
        span Bildirimler
      //- .notification-menu-body(@scroll="dataScrollLoading" ref="notBodyTop")
      .notification-menu-body(ref="notBodyTop")
        .notification-menu-body-list.m-top-15(v-for="data in notiData")
          .notification-menu-body-title.m-top-5
            h2(:id="`notifiyDetail-title-${data.title}`") {{ data.title }}
          .notification-menu-body-desc.m-top-5
            span.notification-desc(:id="`notifiyDetail-desc-${data.desc}`") {{ data.desc }}
          .notification-menu-body-link.m-top-5
            span(:id="`notifiyDetail-link-${data.link}`") {{ data.link }}
</template>

<script>

export default {
  name: 'notification-details',
  props: {
    notiData: {
      type: Array,
      default: () => []
    },
    isNotificationLoading: {
      type: Boolean,
      default: false
    }
  },
  // methods: {
  //   dataScrollLoading () {
  //     /* if (this.$refs.notBodyTop.scrollHeight - Math.abs(this.$refs.notBodyTop.scrollTop) === this.$refs.notBodyTop.clientHeight) {
  //         this.$emit('getData', 2)
  //       } */
  //   }
  // }
}
</script>

<style scoped lang="scss">

  .notification {
    &-menu {
      width: 540px;
      height: calc(100vh - 100px);
      user-select: none;
      position: absolute;
      margin-top: 15px;
      background-color: white;
      box-shadow: 0 10px 20px rgba(black, .1);
      border: 1px solid $color-gray;
      white-space: nowrap;
      border-radius: $border-radius;
      z-index: $z-index-xxl;
      padding: 15px 15px;
      &.right {
        transform: translate3d(-100%, 10px, 10px);
      }
      &.left {
        transform: translate3d(0%, 10px, 10px);
      }

      &-body {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
        min-height: 0;
        height: calc(100vh - 150px);
        overflow-x: hidden;
        overflow-y: auto;
        &-desc {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>
