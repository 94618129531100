var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "zoom" } }, [
    _c("div", { staticClass: "Alert" }, [
      _c("div", { staticClass: "Alert-body" }, [
        _c("figure", { staticClass: "Alert-icon" }, [
          _c("img", { attrs: { src: require("./assets/icon-delete.svg") } }),
        ]),
        _c("h3", { staticClass: "Alert-title" }, [
          _vm._v(
            _vm._s(_vm.title || _vm.$t("Global.deleteAlert_WillDelete_Title"))
          ),
        ]),
        _c(
          "p",
          { staticClass: "Alert-description" },
          [
            _vm.tableName
              ? [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Global.deleteAlert_WillDelete", {
                        name: _vm.tableName,
                      })
                    )
                  ),
                ]
              : [
                  _vm._v(
                    _vm._s(
                      _vm.description ||
                        _vm.$t("Global.deleteAlert_WillDelete_Description")
                    )
                  ),
                ],
          ],
          2
        ),
        _vm.fields.length > 0
          ? _c(
              "div",
              { staticClass: "Alert-item" },
              [
                _vm.isGetDataLoading
                  ? _c("Loading", {
                      staticClass: "data-loading",
                      attrs: { theme: "disable" },
                    })
                  : _vm._e(),
                _c(
                  "table",
                  {
                    staticClass: "Tableee-root",
                    attrs: { id: "delete-alert-table" },
                  },
                  [
                    _c(
                      "thead",
                      {
                        staticClass: "Tableee-head",
                        attrs: { id: "delete-alert-table-header" },
                      },
                      [
                        _c(
                          "tr",
                          {
                            staticClass: "Tableee-row",
                            attrs: { id: "delete-alert-table-header-row" },
                          },
                          _vm._l(_vm.fields, function (field, index) {
                            return field.is_visible
                              ? _c(
                                  "th",
                                  {
                                    key: field.name,
                                    staticClass: "Tableee-col",
                                    attrs: {
                                      id: `delete-alert-table-header-col-${field.name}-${index}`,
                                    },
                                  },
                                  [_vm._v(_vm._s(field.short_title))]
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                      ]
                    ),
                    !_vm.isGetDataLoading
                      ? _c(
                          "tbody",
                          {
                            staticClass: "Tableee-body",
                            attrs: { id: "delete-alert-table-body" },
                          },
                          [
                            _c(
                              "tr",
                              {
                                staticClass: "Tableee-row",
                                attrs: { id: "delete-alert-table-body-row" },
                              },
                              _vm._l(_vm.fields, function (field, index) {
                                return field.is_visible
                                  ? _c(
                                      "td",
                                      {
                                        key: field.name,
                                        staticClass:
                                          "Tableee-col tooltip-relative",
                                        attrs: {
                                          id: `delete-alert-table-body-col-${field.name}-${index}`,
                                        },
                                      },
                                      [
                                        field.is_currency
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatCurrency")(
                                                    _vm.getFieldValue(
                                                      field.name
                                                    )
                                                  )
                                                )
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getFieldName(
                                                    _vm.getFieldValue(
                                                      field.name
                                                    )
                                                  )
                                                )
                                              ),
                                            ],
                                        _vm.getFieldValue(field.name).length >
                                        30
                                          ? _c("Tooltip", {
                                              attrs: {
                                                white: "",
                                                bottom: "",
                                                text: _vm.getFieldValue(
                                                  field.name
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "Alert-actions" },
          [
            _c(
              "Button",
              {
                ref: "focusedButton",
                attrs: {
                  id: "btn-delete-alert-cancel",
                  type: "button",
                  secondary: "",
                  size: "medium",
                },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("Global.deleteAlert_CancelButton")))]
            ),
            _c(
              "Button",
              {
                attrs: {
                  id: "btn-delete-alert-ok",
                  type: "button",
                  primary: "",
                  variant: "full",
                  size: "medium",
                  disabled: _vm.isLoading,
                },
                on: { click: _vm.ok },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isLoading,
                        expression: "!isLoading",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t("Global.deleteAlert_DeleteButton")))]
                ),
                _c("Loading", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  attrs: { theme: "disable" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }