var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    {
      staticClass: "Tableee-body",
      attrs: { id: `table-body-${_vm.componentName}` },
    },
    _vm._l(_vm.data, function (row, rowIndex) {
      return _c(
        "tr",
        {
          key: _vm.uniqueIdentifier ? row[_vm.uniqueIdentifier] : rowIndex,
          staticClass: "Tableee-row",
          class: {
            "u-cPointer": _vm.pointer,
            ["row-" + row.rowTextClass]: row.rowTextClass,
          },
          attrs: {
            tabindex: _vm.tabindex,
            id: `table-body-${_vm.componentName}-row-${rowIndex}`,
          },
          on: {
            keypress: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                if ($event.target !== $event.currentTarget) return null
                $event.preventDefault()
                return _vm.onItemShow(row)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                if ($event.target !== $event.currentTarget) return null
                $event.preventDefault()
                return _vm.onItemShow(row)
              },
            ],
          },
        },
        [
          _vm.selectable === "checkbox"
            ? _c(
                "td",
                { staticClass: "Tableee-col selectable-col checkbox fixed" },
                [
                  row.isActive == null ||
                  row.isActive ||
                  (_vm.showCheckboxForPassives && !row.isActive)
                    ? _c("CustomCheckbox", {
                        staticClass: "checkbox",
                        attrs: {
                          id: `checkbox-table-row-${rowIndex}`,
                          label: "",
                          checked: _vm.isSelected(row),
                          disabled: row.disableSelection,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onItemSelect(row)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.fields, function (field, fieldIndex) {
            return field.is_visible
              ? _c(
                  "td",
                  {
                    staticClass: "Tableee-col",
                    class: [
                      field.name,
                      { mono: field.type === "currency" },
                      { [row.rowTextClass]: row.rowTextClass },
                      { fixed: field.fixedColumnHeader },
                    ],
                    style: field.style || "",
                    attrs: {
                      id: `table-body-${_vm.componentName}-row-${rowIndex}-col-${fieldIndex}`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onItemShow(row, $event)
                      },
                    },
                  },
                  [
                    field.type === "currency"
                      ? [
                          _c(
                            "span",
                            {
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.UserInfo.showCurrencySymbol
                                    ? row.currencyDefinitionSymbol
                                    : ""
                                ) +
                                  _vm._s(
                                    _vm._f("formatCurrency2Digits")(
                                      row[field.name]
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]
                      : field.type === "portal"
                      ? [
                          _c("portalTarget", {
                            staticClass: "portal",
                            attrs: {
                              name: field.name,
                              "slot-props": (row = _vm.setNewRow(
                                row,
                                rowIndex
                              )),
                            },
                          }),
                        ]
                      : field.type === "mono"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "ff-mono u-textRight full-width",
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row[field.name] !== null
                                    ? row[field.name]
                                    : "-"
                                )
                              ),
                            ]
                          ),
                        ]
                      : field.type === "quantity"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "ff-mono u-textRight full-width",
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency2Digits")(
                                    row[field.name]
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      : field.type === "phone"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "ff-mono",
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("formatPhone")(row[field.name]))
                              ),
                            ]
                          ),
                        ]
                      : field.type === "with-badge"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "row-badge",
                              class: [field.typeData(row).className],
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [_vm._v(_vm._s(field.typeData(row).badgeText))]
                          ),
                        ]
                      : field.type === "percent" || field.type === "percentage"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "ff-mono u-textRight full-width",
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("percentFixed")(row[field.name])
                                ) + "%"
                              ),
                            ]
                          ),
                        ]
                      : field.type === "date"
                      ? [
                          _c(
                            "span",
                            {
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row[field.name]
                                    ? new Date(
                                        row[field.name]
                                      ).toLocaleDateString(_vm.$i18n.locale)
                                    : "-"
                                )
                              ),
                            ]
                          ),
                        ]
                      : field.type === "spacer"
                      ? [
                          _c("span", {
                            attrs: {
                              id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                            },
                          }),
                        ]
                      : [
                          _c(
                            "span",
                            {
                              staticClass: "tooltip-relative show-MiniPopup",
                              attrs: {
                                id: `table-body-${_vm.componentName}-row-${rowIndex}-col-span-${fieldIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  field.no_tooltip
                                    ? row[field.name]
                                    : _vm.getFieldName(row[field.name]) ||
                                        field.default ||
                                        "-"
                                )
                              ),
                              !field.no_tooltip
                                ? _c(
                                    "div",
                                    [
                                      row[field.name] &&
                                      row[field.name].length > 30
                                        ? _c("Tooltip", {
                                            attrs: {
                                              white: "",
                                              right: "",
                                              text: row[field.name],
                                            },
                                          })
                                        : _vm._e(),
                                      field.have_tooltip
                                        ? _c("Tooltip", {
                                            staticClass: "table-mini-popup",
                                            attrs: {
                                              dark: "",
                                              right: "",
                                              text: _vm.getTooltipText(
                                                row[field.tooltip_name]
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              field.mini_popup &&
                              (row[field.show_mini_popup] ||
                                field.mini_popup.visible)
                                ? _c(
                                    "div",
                                    {
                                      class: `MiniPopup-${field.mini_popup.direction}-${field.mini_popup.color}`,
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "Tableee-col-mini-popup",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "Tableee-col-mini-popup-text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  row[field.mini_popup.text] ||
                                                    "-"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                  ],
                  2
                )
              : _vm._e()
          }),
          !_vm.readOnly && !row.isDeleted
            ? _c(
                "td",
                { staticClass: "Tableee-col action" },
                [
                  _c("ActionButton", {
                    attrs: { data: _vm.actions, item: row },
                    on: {
                      onItemCopy: function ($event) {
                        return _vm.onItemCopy(row)
                      },
                      onItemChangeActiveStatus: function ($event) {
                        return _vm.onItemChangeActiveStatus(row)
                      },
                      onItemDelete: function ($event) {
                        return _vm.onItemDelete(row)
                      },
                      onItemExport: function ($event) {
                        return _vm.onItemExport(row)
                      },
                      onItemOverview: function ($event) {
                        return _vm.onItemOverview(row)
                      },
                      onItemEdit: function ($event) {
                        return _vm.onItemEdit(row)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.usePortalAction
            ? _c(
                "td",
                { staticClass: "Tableee-col portal-action" },
                [
                  _c("portalTarget", {
                    attrs: { name: "actionPortal", "slot-props": row },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }