var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _vm.hasChild
        ? _c(
            "a",
            {
              staticClass: "dropLink",
              class: {
                isSelected: _vm.showChildren,
                "combo-router-active": _vm.hasActiveChild,
              },
              attrs: { id: _vm.link.id },
              on: { click: _vm.toggleDropdown },
            },
            [
              _c(
                "span",
                { staticClass: "icon-body tooltip-relative" },
                [
                  _c("Icon", { attrs: { name: _vm.link.icon } }),
                  _vm.MenuMinimalize
                    ? _c("Tooltip", {
                        staticClass: "small",
                        attrs: { white: "", right: "", text: _vm.name },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.MenuMinimalize
                ? _c("span", { staticClass: "homeName" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ])
                : _vm._e(),
              !_vm.MenuMinimalize
                ? _c(
                    "span",
                    { staticClass: "arrow-down-icon" },
                    [_c("Icon", { attrs: { name: "icon-arrow-down" } })],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "router-link",
            {
              staticClass: "dropLink",
              class: { selfLink: _vm.link.role === "MainLink" },
              attrs: {
                id: `button-sidebar-${_vm.link.route}-link`,
                to: { name: _vm.link.route },
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.toggleDropdown.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-body tooltip-relative" },
                [
                  _c("Icon", { attrs: { name: _vm.link.icon } }),
                  _vm.MenuMinimalize
                    ? _c("Tooltip", {
                        staticClass: "small",
                        attrs: { white: "", right: "", text: _vm.name },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.MenuMinimalize
                ? _c("span", [_vm._v(_vm._s(_vm.name))])
                : _vm._e(),
            ]
          ),
      _vm.hasChild && !_vm.MenuMinimalize
        ? [
            _vm.showChildren
              ? _c(
                  "ul",
                  _vm._l(_vm.link.children, function (child, index) {
                    return _c(
                      "li",
                      { key: index },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "childLink",
                            attrs: {
                              id: `button-sidebar-${child.name}-link`,
                              to: { name: child.name },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(child.linkName))]),
                            _vm.afterIcon(child)
                              ? _c(
                                  "svg",
                                  {
                                    staticClass: "icon after-icon",
                                    class: `icon-${_vm.afterIcon(child)}`,
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": `#icon-${_vm.afterIcon(
                                          child
                                        )}`,
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }