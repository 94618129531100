var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("thead", { staticClass: "Tableee-head" }, [
    _c(
      "tr",
      { staticClass: "Tableee-row" },
      [
        _vm.selectableAll
          ? _c(
              "th",
              { staticClass: "Tableee-col selectable-col fixed" },
              [
                _c("CustomCheckbox", {
                  attrs: {
                    id: `checkbox-table-row`,
                    label: "",
                    checked: _vm.isAllSelected(),
                  },
                  on: { change: _vm.allSelected },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.fields, function (field, index) {
          return [
            field.headerPortal
              ? _c(
                  "th",
                  {
                    staticClass: "Tableee-col",
                    class: _vm.columnHeaderClassObject(field),
                    style: field.style || "",
                    attrs: {
                      id: `table-head-${_vm.componentName}-col-${index}`,
                    },
                  },
                  [
                    _c("portalTarget", {
                      class: _vm.columnHeaderClassObject(field),
                      attrs: {
                        name: field.headerPortal,
                        id: `table-head-${_vm.componentName}-col-${index}`,
                        "slot-props": field,
                      },
                    }),
                  ],
                  1
                )
              : field.is_visible
              ? _c(
                  "th",
                  {
                    staticClass: "Tableee-col",
                    class: _vm.columnHeaderClassObject(field),
                    attrs: {
                      id: `table-head-${_vm.componentName}-col-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flexbox" },
                      [
                        _c("span", { style: field.style || "" }, [
                          _vm._v(_vm._s(field.short_title || "")),
                        ]),
                        _vm.infoData && _vm.infoData.length && field.infoHeader
                          ? _c("div", { staticClass: "info" }, [
                              _c(
                                "span",
                                { staticClass: "info-button" },
                                [
                                  _c("Icon", {
                                    staticClass:
                                      "show-MiniPopup info-button-icon",
                                    attrs: { name: "icon-global-info-filled" },
                                  }),
                                  _c(
                                    "ul",
                                    { staticClass: "info-popup" },
                                    _vm._l(_vm.infoData, function (item) {
                                      return _c(
                                        "li",
                                        { staticClass: "info-popup-row" },
                                        [
                                          _c("span", {
                                            staticClass: "info-popup-value",
                                            class: `bgc-${item.value}`,
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "info-popup-text" },
                                            [_vm._v(_vm._s(item.text))]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        field.sortingObject && field.is_sortable
                          ? _c("toggle-sorting", {
                              staticClass: "m-left-15",
                              attrs: { item: field.sortingObject },
                              on: {
                                sort: function ($event) {
                                  return _vm.$emit("sort", field)
                                },
                              },
                            })
                          : _vm._e(),
                        field.filter
                          ? _c(
                              "span",
                              {
                                ref: `searchFilterButton${index}`,
                                refInFor: true,
                                staticClass: "m-left-15 filter-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.openPopup(field)
                                  },
                                },
                              },
                              [
                                _c("Icon", {
                                  attrs: { name: "icon-global-settings" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        field.filter && field.filter.showFilter
                          ? _c("popup-filter", {
                              directives: [
                                {
                                  name: "click-outside",
                                  rawName: "v-click-outside",
                                  value: () => _vm.closeFilterPopup(field),
                                  expression: "() => closeFilterPopup(field)",
                                },
                              ],
                              key: field.name,
                              attrs: {
                                label: "name",
                                "popup-position": _vm.position(
                                  `searchFilterButton${index}`
                                ),
                                headerLabel: field.filter.headerLabel,
                                unselectText: _vm.$t("Global.Toggle_Unselect"),
                                list: field.filter.list,
                                isClearFilterAvailable:
                                  field.filter.isClearFilterAvailable,
                                "selected-list": field.filter.selectedList,
                                "block-scroll": false,
                              },
                              on: {
                                onChangeCheckbox: ({ item, isChecked }) =>
                                  _vm.onChangePopupFilterCheckbox({
                                    item,
                                    isChecked,
                                    field,
                                  }),
                                submitFilter: function ($event) {
                                  return _vm.submitFilter(field)
                                },
                                toggleAll: (isSelectedAll) =>
                                  _vm.toggleAll(isSelectedAll, field),
                                clearFilter: function ($event) {
                                  return _vm.clearFilter(field)
                                },
                                changeSearch: (search) =>
                                  _vm.changePopupFilterSearch(search, field),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        }),
        !_vm.readOnly
          ? _c("th", { staticClass: "Tableee-col action" })
          : _vm._e(),
        _vm.usePortalAction
          ? _c("th", { staticClass: "Tableee-col portal-action" })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }