<template lang="pug">
.Popover(:class="variants", v-click-outside="hide")
    span(v-if='isTooltip', ref='tooltip')
    .Popover-toggle(ref='toggle', v-else)
      Button(
        :id="buttonId",
        :variant="toggleVariant",
        :nostyle="!toggleVariant",
        :outline="toggleOutline",
        :size="toggleSize",
        v-bind="toggleAttr",
        @click="shown=!shown")
        slot(v-if="$slots.label", name="label")
        template(v-else) {{ label }}
        Icon.toggleIcon(:name="toggleIcon")

    transition(name="popover-shown")
      .Popover-wrapper(ref="content", :class="popoverVariants" v-show="isShow")
        .Popover-wrapper-content
          slot.Popover-wrapper-contentHeader(name="header")
          slot(name="content")
          slot.Popover-wrapper-Footer(name="footer")
</template>

<script>

export default {
  props: {
    label: { type: String },
    buttonId: { type: String },
    isTooltip: { type: Boolean },
    hover: { type: Boolean },
    size: { type: String },
    position: {
      type: String,
      default: 'center',
      validator: (value) => {
        return ['left', 'center', 'right', 'top', 'top left', 'top right'].indexOf(value) > -1
      }
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    nopadding: { type: Boolean },
    toggleVariant: { type: String },
    toggleIcon: { type: String },
    toggleOutline: { type: Boolean },
    toggleSize: { type: String },
    popoverVariants: { type: String }
  },
  data () {
    return {
      shown: false
    }
  },
  computed: {
    toggleAttr () {
      let obj = {}
      Object.keys(this.$attrs).filter(key => /^toggle-/.test(key)).forEach(key => {
        obj[key.replace(/^toggle-/, '')] = this.$attrs[key]
      })
      return obj
    },
    isShow () {
      return this.hover || this.isTooltip || this.shown
    },
    variants () {
      return {
        [this.position]: !!this.position,
        [this.size]: this.size,
        open: this.shown,
        isTooltip: this.isTooltip,
        hover: this.hover
      }
    }
  },
  watch: {
    isShow () {
      if (this.isShow) {
        this.$emit('show', this.hide)
      } else {
        this.$emit('close')
      }
    }
  },
  methods: {
    hide () {
      this.shown = false
    }
  }
}
</script>

<style lang="scss" scoped>

  $caretWidth: 14px;
  $fgWidth: $caretWidth - 2px;

  .Popover{
    position: relative;
    display: inline-block;

    &-toggle{
      align-items: center;
      display: flex;
      background: $color-white;
      .toggleIcon {
        height: 10px;
        width: 7px;
        margin-left: 10px;
      }
    }

    &-wrapper{
      position: absolute;
      left: 50%;
      top: 100%;
      z-index: 20;
      transform: translateX(-50%);
      min-width: 200px;
      margin-top: 10px;

      &-Footer {
        height: 59px;
        position: fixed;
        bottom: 0;
        background: inherit;
      }

      @each $variant, $color in $theme  {
        &.#{$variant} {
          background: $color-white;
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 100%;
            width: $caretWidth;
            height: $caretWidth;
            background: inherit;
            transform: rotate(45deg) translateY($caretWidth - 4px);
            border-style: solid;
            border-width: 1px;
            border-top-left-radius: $border-radius;
            border-color: $color transparent transparent $color;
          }

          .Popover-wrapper-content {
            background-color: $color-white;
            border-radius: $border-radius;
            border: 1px solid $color;
            box-shadow: 0 2px 15px 0 rgba( $color, 0.28);

            &Header{
              padding: 10px;
            }
          }
        }
      }
    }

    &.isTooltip &{
      &-wrapper {
        &-content {
          padding: 16px;
          color: $color-dark;
          font-size: $font-size-small;
        }
      }
    }

    &.hover{
      .Popover-wrapper{display: none;}

      &:hover {
        .Popover-wrapper{display: block;}
      }
    }

    &.top &{
      &-wrapper{
        top: inherit;
        bottom: calc(100% + 6px);
        transform: translateX(-50%) !important;

        &:before{
          right: $caretWidth;
          bottom: -6px;
          transform: rotateZ(-135deg);
        }
        &:after{
          right: $fgWidth;
          bottom: -6px;
          transform: rotateZ(-135deg);
        }
      }
    }

    &.top.left &,
    &.top.right &{
      &-wrapper  {
        transform: translateX(0) !important;
      }
    }

    &.right &{
      &-wrapper{
        left: auto;
        transform: translateX(0);
        right: -$fgWidth;

        &:before,
        &:after{left: auto;}

        &:before{right: $caretWidth;}
        &:after{right: $fgWidth;}
      }
    }

    &.left &{
      &-wrapper{
        left: -$fgWidth;
        transform: translateX(0);
        &:before{left: ($caretWidth * 2);}
        &:after{left: ($caretWidth * 2);}
      }
    }

    &.lg &{
      &-wrapper {
        min-width: 275px;
      }
    }

    &.isTooltip &{
      &-wrapper {
        display: none;
      }
    }
    &.isTooltip:hover &{
      &-wrapper {
        display: block;
      }
    }
  }
</style>

<style lang="scss">

  .Popover{
    &-wrapper{
      &-content{
        > .btn{
          border-radius: 0;

          &:first-child{border-radius: $border-radius $border-radius 0 0;}
          &:last-child{
            border-radius: 0 0 $border-radius $border-radius;
            border-bottom: 0;
          }
        }

        > .InputGroup{
          padding: 12px;
          margin-bottom: 0;
        }

        > .Dropdown{
          &:first-child{
            .Dropdown-toggle{border-radius: $border-radius $border-radius 0 0;}
          }
          &:last-child{
            .Dropdown-toggle{border-radius: 0 0 $border-radius $border-radius;}
          }
        }
      }
    }
  }
</style>
