<template lang="pug">
.custom-number
    input.txt.no-spinners.u-textRight(
      ref="customInputField"
      autocomplete="off"
      :class="{ 'is-danger': error, 'right': isTypePercentage, 'disabled': disabled }"
      type="number"
      step="any"
      :placeholder="placeholder"
      :data-vv-delay="isDelayInput ? $getConst('INPUT_DELAY_TIME') : 0"
      :value="value"
      :disabled="disabled"
      @blur="submitInput($event, 'blur')"
      @input="submitInput($event ,'input')"
    )
    .percentage(v-show="isTypePercentage" :class="disabled ? 'disabled' : ''") %
</template>

<script>

export default {
  name: 'custom-number-input',
  props: {
    value: {
      type: Number,
      default: ''
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isTypePercentage: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    isDelayInput: {
      type: Boolean,
      required: false
    },
    isChangeValue: {
      type: Boolean,
      default: true
    },
    extraDataEmit: {
      type: [Object, Array],
      default: null
    }
  },
  methods: {
    // Bu fonksiyon proje içerisinde referans ile çağrılıyor.
    focusCustomInput () {
      this.$refs.customInputField.focus()
    },

    submitInput (event, type) {
      let eventInput
      (event.target.value || event.target.value === 0) ? eventInput = Number(event.target.value) : eventInput = null
      if (this.isChangeValue && !this.extraDataEmit) this.$emit(type, eventInput)
      if (this.extraDataEmit && type !== 'input') this.$emit('blur', {inputData: eventInput, extraDataEmit: this.extraDataEmit})
    }
  }
}
</script>

<style scoped lang="scss">

.custom-number {
  position: relative;
}
.percentage {
  height: auto;
  position: absolute;
  top: 35%;
  left: 16px;
  color: #393939;
}
.txt {
  position: relative;
}
.right {
  text-align: right;
}
.disabled {
  color: #39393988 !important;
}

</style>
