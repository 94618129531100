var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OverGroups" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "span",
        {
          staticClass: "OverGroups-item",
          class: [
            _vm.selectedItem === item ||
            (_vm.selectedItem &&
              _vm.selectedItem[_vm.selectedKey] === item[_vm.selectedKey])
              ? "active"
              : "",
            _vm.useUpperCase ? "upper-case" : "",
            _vm.isNotActiveDisabled ? "pointer-none" : "",
            _vm.size,
          ],
          attrs: { id: `custom-toggle-group-${index}` },
          on: {
            click: function ($event) {
              return _vm.onChangeSelectedItem(item)
            },
          },
        },
        [_vm._v(_vm._s(item[_vm.fieldKey]))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }