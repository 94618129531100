var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("businessMultiselect", {
    ref: "businessRVCFilter",
    attrs: {
      componentName: "multiselect-rvc-filter",
      label: _vm.$t("Global.toolbar_RVC_Label"),
      popupHeader: _vm.$t("Global.toolbar_RVC_PopupHeader"),
      multiselectItems: _vm.rvcList.list,
      withBadgedText: true,
      badgedTextField: "storeGroupName",
    },
    on: { submitFilter: _vm.submitFilter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }