var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-container" },
    [
      _vm.isDataLoading
        ? _c("Loading", { attrs: { theme: "disable" } })
        : _c("div", { staticClass: "icon-container-box" }, [
            _vm.data.length === 0
              ? _c("span", [_vm._v(_vm._s(_vm.$t("Global.Search_No_Result")))])
              : _c(
                  "div",
                  { staticClass: "icon-box" },
                  _vm._l(_vm.data, function (i) {
                    return _c("div", { staticClass: "icon-info" }, [
                      _c(
                        "div",
                        { staticClass: "box-icon" },
                        [_c("Icon", { attrs: { name: i.iconName } })],
                        1
                      ),
                      _c("div", { staticClass: "title" }, [
                        _c("span", { attrs: { id: `${i.name}-title` } }, [
                          _vm._v(_vm._s(i.name)),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }