var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Search-input" },
    [
      _c("Icon", {
        staticClass: "Search-input-icon",
        attrs: { name: "icon-search" },
      }),
      _vm.search
        ? _c(
            "Button",
            {
              staticClass: "Search-input-close",
              attrs: {
                type: "button",
                variant: "icon",
                id: `btn-${_vm.componentName}-clear-search`,
              },
              on: { click: _vm.clearSearch },
            },
            [
              _vm.isSuggestionLoading ? _c("Loading") : _vm._e(),
              !_vm.isSuggestionLoading
                ? _c("Icon", {
                    attrs: {
                      id: "search-input-close-icon",
                      name: "icon-popup-close",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search",
          },
        ],
        staticClass: "txt Search-input-txt",
        attrs: {
          id: `input-${_vm.componentName}-search`,
          autocomplete: "off",
          disabled: _vm.isInputDisable,
          type: "text",
          placeholder: _vm.inputPlaceholderText,
        },
        domProps: { value: _vm.search },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
            _vm.onChangeSearchValue,
          ],
        },
      }),
      !_vm.suggestionsIsEmpty && _vm.showSuggestionBox
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.clearOutsideSearch,
                  expression: "clearOutsideSearch",
                },
              ],
              staticClass: "Search-suggestion",
              attrs: { id: `search-suggestion-list-${_vm.componentName}` },
            },
            [
              _c(
                "h5",
                {
                  staticClass: "Search-suggestion-title",
                  attrs: {
                    id: `search-suggestion-list-title-${_vm.componentName}`,
                  },
                },
                [_vm._v(_vm._s(_vm.suggestionTitle))]
              ),
              _vm._l(_vm.suggestions, function (suggestion, index) {
                return _c(
                  "Button",
                  {
                    key: suggestion.id,
                    staticClass: "Search-suggestion-item",
                    attrs: {
                      size: "small",
                      id: `btn-${_vm.componentName}-suggestion-item-${index}`,
                      type: "button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onChangeQueryItem(suggestion)
                      },
                    },
                  },
                  [
                    _c("strong", { staticClass: "name" }, [
                      _vm._v(_vm._s(suggestion.name)),
                    ]),
                    _vm.showSuggestionType
                      ? _c(
                          "i",
                          {
                            staticClass: "type",
                            attrs: {
                              id: `search-suggestion-list-item-type-${_vm.componentName}-${index}`,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `OnHand.search_SuggestionType_${suggestion.typeKey}`
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }