<template lang="pug">
.toggle-sorting(
    @click="sort"
  )
    label.order-number(v-show="order") {{ order }}
    .icons
      svg.icon.icon-down-arrow.icons-up.toggle-button(
        :class="item.sortType === 0 ? 'active' : ''"
      )
        use(xlink:href="#icon-down-arrow")
      svg.icon.icon-down-arrow.icons-down.toggle-button(
        :class="item.sortType === 1 ? 'active' : ''"
      )
        use(xlink:href="#icon-down-arrow")
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    order: {
      type: Number,
      required: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
    sort () {
      this.$emit('sort', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>

$icon-h: 6.5px;
$icon-w: 8px;
$gap: 4px;

.toggle-sorting {
  display: flex;
  cursor: pointer;
}

.order-number {
  color: $color-light;
  margin-right: 5px;
}

.icon-down-arrow {
  width: $icon-w;
  height: $icon-h;
}
.icons {
  // position: absolute;
  right: 15px;
  top: calc(50% - $icon-h);
  display: flex;
  flex-direction: column;
  width: $icon-w;
  height: $gap + ($icon-h * 2);
  margin: 0;
  padding: 0;

  .toggle-button {
    color: darken($color: $color-gray, $amount: 10%);

    &.active {
      color: $color-light;
    }
  }

  &-up {
    margin-bottom: $gap;
    transform: rotate(180deg)
  }

}
</style>
