var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeIn" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showAlert,
            expression: "showAlert",
          },
        ],
        staticClass: "Alert",
      },
      [
        _c("transition", { attrs: { name: "slideUp", mode: "in-out" } }, [
          _c("div", { staticClass: "content" }, [
            _c("div", {
              staticClass: "Alert-overlay",
              on: { click: _vm.close },
            }),
            _c("div", { staticClass: "Alert-body" }, [
              _c(
                "div",
                { staticClass: "Alert-body-icon" },
                [
                  _c("Icon", {
                    staticClass: "icon-alert-warning",
                    attrs: { name: "icon-global-warning" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "Alert-body-text" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.alert.title)),
                ]),
                _c("p", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.alert.message)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "Alert-body-action" },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "uppercase",
                      attrs: { variant: _vm.cancelButton.style },
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.cancelButtonText))]
                  ),
                  _vm.confirmButton
                    ? _c(
                        "Button",
                        {
                          staticClass: "uppercase",
                          attrs: {
                            id: "alert-body-action-confirm-button",
                            variant: _vm.confirmButton.style,
                            isLoading: _vm.confirmButtonIsRunAction,
                            disabled:
                              _vm.confirmButtonIsRunAction ||
                              _vm.confirmButton.disabled,
                          },
                          on: { click: _vm.confirmAction },
                        },
                        [_vm._v(_vm._s(_vm.confirmButton.text))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }