<template lang="pug">
.breadcrumb
    router-link.breadcrumb-link(
    v-for="(link, index) in links",
    :key="index"
    :to="{ name: link.to }"
    :id="`breadcrumb-${link.name}`"
    )
      span {{ link.name }}
      span.breadcrumb-link-icon(v-if="links.length - 1 > index") {{ ">" }}
</template>

<script>

export default {
  name: 'Breadcrumb',
  computed: {
    links () {
      if (!this.$route.meta.breadcrumb) return []
      return this.$route.meta.breadcrumb.map(item => {
        const currentRoute = this.$route
        return {
          ...item,
          name: item.withParams ? currentRoute.params[item.param] : this.$t(`Breadcrumb.${item.name}`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .breadcrumb {
    &-link {
      margin-right: 4px;
      font-size: $font-size-micro;
      opacity: 0.43;
      font-weight: bold;

      &:hover {
        opacity: 1;
      }
      &-icon {
        margin-left: 4px;
      }
    }
  }
</style>
