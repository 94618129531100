<template lang="pug">
.App
    ErrorTemplate 
    Icons
    Notify
    .Auth
      .Auth-cover
        .Auth-cover-body
          .Auth-cover-logo
            img(src="./assets/logo.svg")

          .Auth-cover-welcome
            Icon(name="simpra-text")

      .Auth-body
        .Auth-inner
          router-view

</template>

<script>

import Icons from '@/view/global/icons'
import { mapGetters } from 'vuex'
import ErrorTemplate from '@/view/global/error-template'


export default {
  name: 'LoginNo',
  components: {
    ErrorTemplate,
    Icons
  },
  computed: {
    ...mapGetters('i18n', [
      'langSupport'
    ]),

    ...mapGetters('Global', [
      'showLanguageDropdown'
    ]),

    text () {
      return this.$t(this.$route.name + '.infoText')
    }
  }
}
</script>

<style lang="scss">

  @import '../../../stylesheet/config/mixin';

  .Auth {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: #fdfdfd;

    $left-panel: 49.2%;
    $left-panel-d: 45%;
    $left-panel-t: 40%;

    &-cover {
      position: absolute;
      left: 0;
      height: 100%;
      width: $left-panel;
      background: #29373E;
      @include screen('desktop') {
        width: $left-panel-d;
      }
      @include screen('tablet') {
        width: $left-panel-t;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(28, 47, 57, 0.9);
      }

      &-body {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $color-gray;
        text-align: center;
      }

      &-logo {
        svg {
          width: 230px;
          @include screen('desktop') {
            width: auto;
          }
          @include screen('tablet') {
            width: auto;
          }
        }
      }

      &-welcome {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        font-weight: $font-weight-bold;
        font-size: $font-size-big;
        line-height: 1.5;
        @include screen('desktop') {
          font-size: $font-size-normal;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    &-body {
      overflow: scroll;
      position: absolute;
      right: 0;
      left: $left-panel;
      height: 100%;
      @include screen('desktop') {
        left: $left-panel-d;
      }
      @include screen('tablet') {
        left: $left-panel-t;
      }
    }

    &-inner {
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-form {
      margin: 0 auto;
      width: 370px;

      &-title {
        font-size: 1.8em;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-gray;
        margin-bottom: 30px;
      }

      &-link {
        color: $color-text-light;
        text-align: center;
        a {
          font-weight: $font-weight-bold;
          text-decoration: underline;
          color: $color-text-dark;
        }
      }
    }

    .auth-step {
      $dash-with: 6px;
      margin-bottom: 40px;
      display: flex;

      &-item {
        color: $color-light;
        position: relative;
        width: 50%;

        h5 {
          font-size: 50px;
          line-height: 1;
        }

        p {
          margin-top: 8px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 30px;
          top: 39px;
          height: 3px;
          width: 50%;
          background: repeating-linear-gradient(to right, #fdfdfd, #fdfdfd $dash-with, currentColor $dash-with, currentColor $dash-with * 2);
        }

        &:last-child {
          margin-left: 34px;
          &:after {
            left: auto;
            right: 100%;
            margin-right: 5px;
          }
        }

        &:not(.active):first-child {
          &:after {
            background: repeating-linear-gradient(to left, #fdfdfd, #fdfdfd $dash-with, currentColor $dash-with, currentColor $dash-with * 2);
          }
        }

        &.active {
          color: $color-success;

          &:after {
            background: currentColor;
          }
        }
      }
    }
  }
  .simpra-text {
    width: 50px !important;
  }
</style>
