var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: _vm.variants, attrs: { for: _vm.id } }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value",
        },
      ],
      staticClass: "switch-input",
      attrs: { type: "checkbox", name: _vm.name, id: _vm.id },
      domProps: {
        checked: Array.isArray(_vm.value)
          ? _vm._i(_vm.value, null) > -1
          : _vm.value,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.value,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value = $$c
            }
          },
          _vm.change,
        ],
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }