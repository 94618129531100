var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "downloadField" },
    [
      _c(
        "Button",
        _vm._g(
          _vm._b(
            {
              staticClass: "report-download-button",
              class: _vm.classes,
              attrs: {
                size: _vm.size,
                justIcon: true,
                useIconClass: false,
                isLoading: _vm.isLoading,
                disabled: _vm.isLoading || _vm.disabled,
              },
            },
            "Button",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("Icon", {
            staticClass: "file-icon",
            attrs: { name: _vm.iconName },
          }),
          _vm.textButton
            ? _c("span", [_vm._v(_vm._s(_vm.textButton))])
            : _vm._e(),
          _c("Icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.useCustomButton,
                expression: "!useCustomButton",
              },
            ],
            staticClass: "arrow-icon",
            attrs: { name: "export-arrow" },
          }),
        ],
        1
      ),
      _c("a", {
        ref: "downloadButton",
        staticClass: "display-none-download-link",
        attrs: { download: _vm.downloadFileName },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }