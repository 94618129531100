<template lang="pug">
.selectedButton(:class="[type]")
    button(
      :id="`selected-btn-${id}-${index}`"
      v-for="(input, index) in data"
      @click="selectItem(input.value)"
      :class="{'selected': value === input.value }, size"
    ) {{ input.text }}
</template>

<script>

export default {
  name: 'SelectedButtons',
  props: {
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'formTab'
    },
    size: {
      type: String,
      default: ''
    },
    value: {},
    data: { type: Array }
  },
  methods: {
    selectItem (val) {
      this.$emit('input', val)
      this.$emit('changeValue', val)
    }
  }
}
</script>

<style lang="scss" scoped>

  .selectedButton {
    &.pageTab {
      display: flex;
      border-bottom: 1px solid $color-primary;
      button {
        margin-right: 10px;
        font-size: 18px;
        color: $color-dark;
        min-height: 46px;
        padding: 10px 20px;
        border-width: 1px 1px 0 1px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-style: solid;
        border-color: transparent;
        line-height: normal;
        font-weight: $font-weight-normal;
        &.selected {
          pointer-events: none;
          color: $color-primary;
          background: $color-ocean;
          border-color: $color-primary;
        }
        &.disabled {
          pointer-events: none;
          opacity: .5;
        }
      }
    }
    &.formTab {
      display: flex;
      align-items: flex-end;
      height: 39px;
      border-bottom: 1px solid $color-gray;
      margin-bottom: 25px;
      button {
        font-weight: $font-weight-normal;
        font-size: $font-size-bigger;
        text-align: left;
        border-bottom: 4px solid transparent;
        color: $color-dark;

        &.lg {
          font-size: $font-size-normal;
        }
        &.md {
          font-size: $font-size-small;
        }
        &.sm {
          font-size: $font-size-micro;
        }
        &.xsm {
          font-size: 10px;
        }

        &:not(:last-child) {
          margin-right: 40px;
        }
        &.lg:not(:last-child) {
          margin-right: 20px;
        }
        &.selected {
          color: $color-primary;
          border-bottom-color: $color-primary;
          font-weight: $font-weight-normal;
          cursor: default;
          pointer-events: none;
        }
      }
    }
    &.tab {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
       button {
        font-weight: $font-weight-normal;
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid $color-gray;
        border-radius: 6px;
        padding: 10px;
        color: $color-light;

        &.lg {
          font-size: $font-size-normal;
        }
        &.md {
          font-size: $font-size-small;
        }
        &.sm {
          font-size: $font-size-micro;
        }
        &.xsm {
          font-size: 10px;
        }

        &.selected {
          background: $color-primary;
          color: $color-white;
          cursor: default;
          pointer-events: none;
        }
       }
    }
  }
</style>
