var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "selected-list" },
    _vm._l(_vm.value, function (item, index) {
      return _c(
        "Button",
        {
          key: index,
          attrs: {
            seashell: "",
            size: "medium",
            id: `selected-${_vm.moduleName}-${index}`,
          },
          on: {
            click: function ($event) {
              return _vm.removeListItem(item)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(item[_vm.label]))]),
          _c("Icon", {
            staticClass: "m-left-5",
            attrs: {
              name: "icon-popup-close",
              id: `selected-${_vm.moduleName}-item-delete-${index}`,
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }