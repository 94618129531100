<template lang="pug">
  businessMultiselect(
      componentName="multiselect-rvc-filter"
      :label="$t('Global.toolbar_RVC_Label')"
      :popupHeader="$t('Global.toolbar_RVC_PopupHeader')"
      :multiselectItems="rvcList.list"
      :withBadgedText="true",
      badgedTextField="storeGroupName"
      ref="businessRVCFilter"
      @submitFilter="submitFilter"
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {

  props: {
    showPassives: {
      type: Boolean,
      default: true
    },
  },

  components: {
    businessMultiselect
  },

  async beforeMount () {
    await this.getRVCList({ pageSize: 1000 , showPassives: this.showPassives })
  },

  computed: {
    ...mapGetters('MenuItems', {
      rvcList: 'RVCList'
    })
  },

  methods: {
    ...mapActions('MenuItems', [
      'getRVCList'
    ]),

    submitFilter ({list, dontSubmit}) {
      this.$emit('submitFilter', {list, dontSubmit})
    },

    clearRVCFilter (dontSubmit = true) {
      this.$refs.businessRVCFilter.clearAllFilter(dontSubmit)
    }
  }
}
</script>
