var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { class: [_vm.name, _vm.useIconClass ? "icon" : null] }, [
    _c("use", { attrs: { "xlink:href": `#${_vm.name}` } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }