var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab" },
    _vm._l(_vm.links, function (link) {
      return _c(
        "router-link",
        {
          key: link,
          class: {
            isSelected: _vm.isSelected(link),
            disabled: _vm.disabledValues.find((l) => l === link),
          },
          attrs: {
            id: `link-${_vm.translationGroup}-tab-header-${link}`,
            to: { name: link },
          },
        },
        [
          _vm._t("linkExtra", null, { linkName: link }),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t(`${_vm.translationGroup}.breadcrumb_${link}`))
            ),
          ]),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }