<template lang="pug">
.search(
    :ref="componentName"
    v-click-outside="closeDropdown"
    @click.stop="isCustomSituation ? onChangeInput : ''"
    @keydown.down.prevent="setHoveredItem('down')"
    @keydown.up.prevent="setHoveredItem('up')"
    @keydown.esc.prevent="closeDropdown"
    @keydown.enter.prevent="onSelect(hoveredItem)"
    )
    .search-input(
      :class="{'search__shadow': showDropdown && !isEmptyOptions && search}"
    )
      input.txt.search-input__input(
        ref="search"
        v-model="search"
        type="text"
        autocomplete="off"
        v-bind="$attrs"
        :disabled="disabled"
        :class="inputClass"
        @input="onChangeInput"
        @focus="onFocusInput"
        @blur="onBlurInput"
        :id="`${componentName}-search-input`"
      )
      Loading.search-input__icon.isLoading(
        v-if="isLoading"
      )
      Icon.search-input__icon.search(
        v-else
        name="icon-search"
        :class="showDropdown ? ['search__transition', 'opened_dropdown'] : false"
      )
      svg.search-input__icon.dropdown(
        @click="changeShowDropdown"
        :class="showDropdown ? ['dropdown-icon__transform', 'opened_dropdown'] : false"
      )
        use(xlink:href="#icon-arrow-down")
    template(v-if="search || showDropdown")
      .search-dropdown(v-if="showDropdown && !isEmptyOptions")
        .search-dropdown-item(
          v-for="(option, index) in options"
          @click="onSelect(option)"
          @mouseover="setHoveredItem('', option)"
          :class="[hoveredItem === option ? 'search-dropdown-item__hover' : null, isItemDisabled(option) ? '' : 'disabled-item']"
          :id="`${componentName}-search-item-${index}`"
          :index="index"
          )
          span.search-dropdown-item__text(
            :id="`${componentName}-search-item-${index}-text`"
          ) {{ option[label] }}
      .search-dropdown(
        v-if="showDropdown && isEmptyOptions && search.length >= startFrom && !dontShowDropdown && !isLoading"
        :id="`${componentName}-search-item-no-result`"
        )
        .search-dropdown-item
          span.search-dropdown-item__text(:id="`${componentName}-search-item-no-result-text`") {{ noResultTextComputed }}
      .search-dropdown-tranparency(v-if="showDropdown && this.options.length > 4")

</template>

<script>


export default {
  props: {
    componentName: {
      type: String,
      default: 'custom-search'
    },
    value: {
      default: null
    }, // dont' delete. its for v-model
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },

    customEmit: {
      type: Object,
      required: false
    },

    debounce: {
      type: Number,
      default: 500
    },

    withDebounce: {
      type: Boolean,
      default: true
    },

    onSearch: {
      type: Function,
      required: false
    },

    searchSnapshotStatus: {
      type: Boolean,
      default: true
    },

    directSelectOption: {
      type: Object,
      default: () => {}
    },

    loading: {
      type: Boolean,
      default: false
    },

    startFrom: {
      type: Number,
      default: 2
    },

    noResultText: {
      type: String,
      required: false
    },
    inputClass: {},
    dontShowDropdown: {
      type: Boolean,
      default: false
    },
    searchAll: {
      type: Boolean,
      default: false
    },
    isAvailableCondition: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isCustomSituation: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.syncSearchToValue()
  },
  watch: {
    directSelectOption () {
      if (!this.directSelectOption || Object.keys(this.directSelectOption).length === 0) return
      this.onSelect(this.directSelectOption)
    },

    showDropdown () {
      if (!this.value) return
      if (this.value[this.label] && this.showDropdown === false) this.search = this.value[this.label]
    },

    options () {
      this.hoveredItem = this.options[0] || null
    },

    value () {
      this.syncSearchToValue()
    }
  },
  data () {
    return {
      search: '',
      searchSnapshot: '',
      showDropdown: false,
      debounceTimerId: null,
      debounceLoading: false,
      hoveredItem: null
    }
  },
  computed: {
    noResultTextComputed () {
      return this.noResultText || this.$t('Global.Search_No_Result')
    },

    isLoading () {
      return this.loading || this.debounceLoading
    },

    isEmptyOptions () {
      return this.options.length === 0
    }
  },
  methods: {
    onFocusInput () {
      if (!this.search) return
      if (this.searchSnapshotStatus) this.searchSnapshot = JSON.parse(JSON.stringify(this.search))
      this.$emit('focus')
    },
    onBlurInput () {
      if (this.directSelectOption) this.search = this.directSelectOption.name
      else this.search = JSON.parse(JSON.stringify(this.searchSnapshot))
    },
    setHoveredItem (direction, newOption = null) {
      if (newOption) {
        // if mouseover
        this.hoveredItem = newOption
      } else {
        // if arrow keys
        if (this.isEmptyOptions) return
        const index = this.options.findIndex(item => item === this.hoveredItem)
        if (direction === 'down') this.hoveredItem = this.options[index + 1] || this.options[0]
        else this.hoveredItem = this.options[index - 1] || this.options[this.options.length - 1]
      }
    },

    syncSearchToValue () {
      this.search = this.value ? this.value[this.label] : ''
    },

    closeDropdown () {
      this.showDropdown = false
    },

    openDropdown () {
      this.showDropdown = true
      this.$refs.search.focus()
    },

    async changeShowDropdown () {
      if (this.disabled) return
      if (this.showDropdown) this.closeDropdown()
      else if (this.searchAll && !this.showDropdown) {
        await this.onSearch('')
        this.openDropdown()
      }
    },

    setDebounceLoading (loading = true) {
      this.debounceLoading = loading
    },

    async onChangeInput () {
      this.$emit('onClickInput', this.customEmit)
      const value = event.target.value
      if (value.length >= 2) {
        await this.searchWithOptions(value)
      }
      else {
        this.$emit('resetOptions')
      }
    },

    async searchWithOptions (val = '') {
      this.showDropdown = true
      if (!val || val.length < this.startFrom) {
        this.$emit('resetOptions')
        return
      }
      if (this.withDebounce) {
        if (this.debounceTimerId) clearTimeout(this.debounceTimerId) // if devounce used before stop it
        // start loading
        this.setDebounceLoading(true)
        // start debounce
        this.debounceTimerId = setTimeout(async () => {
          // after the time search (val)
          await this.onSearch(val)
          // and clear timer
          await clearTimeout(this.debounceTimerId)
          // stop loading
          this.setDebounceLoading(false)
        }, this.debounce)
      } else {
        // no debounce directly execute onSearch
        this.onSearch(val)
      }
    },

    isItemDisabled (item) {
      return this.isAvailableCondition ? item[this.isAvailableCondition] : true
    },

    onSelect (option) {
      if (!option) return
      if (this.directSelectOption) this.search = this.directSelectOption.name
      if (this.customEmit) this.$emit('input', {option: option, customEmit: this.customEmit})
      else this.$emit('input', option)
      this.closeDropdown()
      this.$emit('afterSelected', option)
    }
  }
}
</script>

<style lang="scss">

// local variables
$dropdown-height: 266px;
$icon-size: 20px;
$search-box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.11);

.search {
  position: relative;
  width: 100%;

  &-input {
    position: relative;
    width: inherit;
    .txt{
      padding-right: 64px;
    }
    &__input {
      width: inherit;

      &::placeholder {
        color: $color-dark;
      }
    }
    &__icon {
      position: absolute;
      color: $color-success;
      width: $icon-size;
      height: $icon-size;
      right: 35px;
      top: calc(50% - #{$icon-size / 2});
      transition: all 0.3s ease;
      z-index: 20;

      &.opened_dropdown {
        color: $color-success;
      }

      &.dropdown {
        right: 10px;
      }
      // mini animation for dropdown icon, all icons has transition: all
      &.dropdown-icon__transform {
        transform: rotate(180deg);
      }
    }

  }
  &-dropdown {
    position: absolute;
    z-index: 30;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: $dropdown-height;
    border: 1px solid $color-gray;
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
    overflow-y: auto;
    box-shadow: $search-box-shadow;

    &-item {
      display: flex;
      width: 100%;
      background-color: #fff;
      position: relative;
      align-items: center;
      min-height: 54px;
      padding: 10px;
      border-bottom: 1px solid $color-gray;
      cursor: pointer;

      &.disabled-item {
        pointer-events: none;
        background-color: $color-white;
        color: $color-gray;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }

    &-item__hover {
      background-color: $color-gray;
    }
  }

  &-dropdown-tranparency {
    width: 100%;
    position: absolute;
    top: $dropdown-height;
    z-index: 20;
    min-height: 54px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ffffff);
    border-radius: 0 0 $border-radius $border-radius;
    pointer-events: none;
    border: 1px solid $color-gray;
    border-top: none;
  }

  &__shadow {
    box-shadow: $search-box-shadow;
  }
}

.clear-border-radius-bottom {
  border-radius: $border-radius $border-radius 0 0;
}
</style>
