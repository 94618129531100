<template lang="pug">
  aside.Sidebar(
      :class="[MenuMinimalize ? 'MiniMenu' : 'Expect']"
    )
      // div(v-if="!MenuMinimalize")
      .Sidebar-top
        Logo
        nav.Nav#Sidebar
          ul.mainList
            Link(
              ref="NavRoute",
              v-for="(item, index) in mainNav",
              :link="item",
              :key="index",
              :sales-warning="showSalesWarning")
  
      .Sidebar-bottom
        .user-section.user(:class="MenuMinimalize ? 'user-short-minimalize' : ''" @click="goToSettings")
          Icon(name="icon-settings-user" v-if="!MenuMinimalize")
          .user-name(v-if="!MenuMinimalize").m-left-10 {{ getUserName() }}
          .user-short {{shortName}}
        
        .tenant-name(v-if="!MenuMinimalize") {{ getTenantName() }}
  
        .user
          router-link(
          id="link-settings"
          :to="{ name: 'SettingsUser' }")
            Icon.m-right-10(name="icon-global-settings")
            span(v-if="!MenuMinimalize") {{ $t('Global.userMenu_Settings') }}
        
        Help.user(
          :showHelpCenterText="MenuMinimalize ? false : true"
        )
        .user(@click="logOut")
          Icon(name="icon-logout")
          span(v-if="!MenuMinimalize").m-left-10 {{$t('Global.Mobile_Logout')}}
      
        .sideBarToggleBtn.m-top-20.m-bottom-15
          span.version(v-if="!MenuMinimalize") v{{appVersion}}
          Button(
            @click="toggleExpandedMenu",
            :iconName="toggleIconName",
            :justIcon="true"
            :iconClass="toggleIconName")
      
  </template>
  
  <script>
  import Logo from './logo'
  import router from '@/router'
  import FeatureToggleRouter from '@/utils/feature-toggle-router'
  import Link from './link'
  import UserMenu from '@/view/global/user-menu'
  import Notification from '@/view/global/notification'
  import Help from '@/view/global/help'
  import { SidebarMenuItems } from './sidebar-menu-list'
  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import AuthorizationContext from "@/sso/keycloak-instance.js";
  import requestHandler from '@/utils/requestHandler'
  import HTTP from "@/utils/http";
  
  export default {
  
    name: 'Sidebar',
  
    data () {
      return {
        permissionList: []
      }
    },
    props: {
      showSalesWarning: {
        type: Boolean,
        default: false
      }
    },
  
    components: {
      Logo,
      Link,
      UserMenu,
      Notification,
      Help
    },
    async mounted () {
      this.RESET_MENU_MINIMALIZE()
      this.permissionList = await this.getPermissionList()
    },
    computed: {
      ...mapGetters('Global', [
        'MenuMinimalize',
        'appVersion',
        'isSupportUser'
      ]),
      ...mapGetters('Auth', [
        'Profile'
      ]),
      
      shortName () {
        const firstname = this.Profile.user.firstname.charAt(0)
        const surname = this.Profile.user.surname.charAt(0)
        return `${firstname}${surname}`.toLocaleUpperCase()
      },
      toggleIconName () {
        return this.MenuMinimalize ? 'icon-menu-open' : 'icon-menu-close'
      },
      mainNav () {
        let nav = router.options.routes.filter(route => {
          return (
            route.hasOwnProperty('meta') &&
            route.meta.main_menu &&
            (!route.meta.feature_flag ||
              FeatureToggleRouter.isActive(route.meta.feature_flag))
          )
        })
  
        let categories = SidebarMenuItems(this.$t)
  
        nav.forEach(x => {
          if (categories[x.meta.category]) {
            categories[x.meta.category].role = 'TabLink'
            const item = {
              ...x,
              linkName: x.meta.slug ? this.$t(`Sidebar.nav_${x.meta.slug}`) : ''
            }
            if (!x.meta.permissionKey) {
              categories[x.meta.category].children.push(item)
            } else {
              const findPermission = this.permissionList.find(item => item.permissionKey === x.meta.permissionKey)
              if (findPermission) categories[x.meta.category].children.push(item)
            }
          } else {
            let permission = false
            if (!x.meta.permissionKey) {
              permission = true
            } else {
              this.permissionList.forEach(l => {
                if (x.meta.permissionKey === l.permissionKey) {
                  permission = true
                }
              })
            }
            if (permission) {
              categories[x.name] = {
                name: x.meta.slug ? this.$t(`Sidebar.nav_${x.meta.slug}`) : '',
                icon: x.meta.slug ? `icon-navigation-${x.meta.slug}` : '',
                route: x.name,
                children: [],
                role: 'MainLink'
              }
            }
          }
        })
  
        let sortedCategories = {
          'Dashboard': {
            ...categories['Dashboard']
          },
          ...categories
        }
        return sortedCategories
      }
    },
  
    methods: {
      ...mapMutations('Global',
        ['TOGGLE_MENU_MINIMALIZE',
          'RESET_MENU_MINIMALIZE',
          'RESET_SUSPEND'
        ]),
  
      ...mapActions('Settings', [
        'fetchUser'
      ]),
  
      async logOut () {
        if (this.isSupportUser) {
          this.$router.push({
            name: 'Login'
          })
        } else {
          window.history.replaceState({}, '', '/')
          await requestHandler({
            title: "SSO Logout",
            methodName: "SSO/Logout",
            method () {
              return HTTP.delete("User/sso/logout");
            },
            success () {
              AuthorizationContext.logout()
            }
          });
        }
        this.RESET_SUSPEND()
        await this.$store.dispatch('Auth/Logout', null, { root: true })
      },
      async getPermissionList () {
        const res = await this.fetchUser()
        return res.data.permissionList
      },
  
      goToSettings () {
        this.$router.push({
          name: 'SettingsUser'
        })
      },
  
      getUserName () {
        let fullName
        if (this.isSupportUser) {
          let name = this.Profile.user.firstname.toLowerCase()
          name = name.charAt(0).toUpperCase() + name.slice(1)
          let surname = this.Profile.user.surname.toLowerCase()
          surname = surname.charAt(0).toUpperCase() + surname.slice(1)
          fullName = name + ' ' + surname
        } else {
          const parsedToken = AuthorizationContext.token
          fullName = (parsedToken.given_name ? parsedToken.given_name.trim() : '') + ' ' + (parsedToken.family_name ? parsedToken.family_name.trim() : '')
        }
        return fullName.length < 13 ? fullName : fullName.substr(0, 12).concat('...')
      },
  
      getTenantName () {
        return this.Profile.tenant.name.length < 13 ? this.Profile.tenant.name : this.Profile.tenant.name.substr(0, 12).concat('...')
      },
  
      getAfterIcon (item) {
        switch (item.name) {
          case 'MenuItems':
            return this.showSalesWarning ? 'row-status' : ''
          default:
            return ''
        }
      },
      toggleExpandedMenu () {
        this.TOGGLE_MENU_MINIMALIZE()
      }
    }
  }
  </script>
  
  <style lang="scss">
  
  .Sidebar {
    overflow-y: auto;
    user-select: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: linear-gradient(168deg,$color-sidebar-dark, rgba(0, 23, 31, 0.9));
    transition: .6s width cubic-bezier(0.19, 1, 0.22, 1);
    z-index: $z-index-xl;
    &.Expect {
      width: $sidebar-size;
      .Nav {
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar
        {
          width: 10px;
          background: $color-sidebar-dark;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0,0,0, 0.5);
          border-radius: $border-radius;
          border: 1px solid rgba($color-white, 0.2);
        }
      }
    }
    &.MiniMenu {
      width: $sidebar-mini-size;
      overflow: unset !important;
      .sideBarToggleBtn {
        justify-content: center;
        padding-right: 0;
        padding-left: 0;
      }
    }
    .Nav {
      display: flex;
      flex-grow: 1;
      margin-top: 35px;
      padding: 0;
      flex-wrap: wrap;
      ul,
      li {
        width: 100%;
      }
  
      li {
        min-height: 54px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $color-text-light;
        font-weight: 500;
        a {
          display: flex;
          padding-left: 17px;
          position: relative;
          cursor: pointer;
          height: 54px;
          align-items: center;
          border-left: 3px solid transparent;
          &:hover {
            color: $color-white;
          }
          &.router-link-active:not(.selfLink) {
            color: $color-white;
          }
          &.dropLink {
            &.selfLink {
              &.router-link-exact-active {
                color: $color-white;
                border-left: 3px solid $color-orange;
                background: $color-sidebar-dark;
              }
            }
          }
          &.isSelected {
            .arrow-down-icon {
              transform: rotate(0)
            }
          }
          &.router-link-exact-active {
            color: $color-white;
          }
          .icon-body {
            margin-right: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 21px;
            height: 21px;
          }
          .arrow-down-icon {
            position: absolute;
            right: 15px;
            font-size: 13px;
            transform: rotate(-90deg);
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 29px;
          background: $color-sidebar-dark;
        }
      }
    }
    
    &-bottom {
      display: flex;
      color: $color-light;
      padding-left: 20px;
      position: relative;
      padding-right: 17px;
      flex-direction: column;
      cursor: pointer;
      .user-section{
        .icon {
          margin-top: 16px;
        }
      }
      .user {
        padding-top: 15px;
        padding-bottom:15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        &:hover {
          color: white
        }
        &-name {
          display: flex;
          margin-left: -48px;
        }
      }
      .settings {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .help-center, .logout {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .sideBarToggleBtn {
        display: flex;
        align-items: center;
        color: $color-light;
        justify-content: space-between;
        flex-shrink: 0;
        align-self: flex-end;
        width: 100%;
        button {
          background: transparent;
          color: $color-success;
          position: relative;
          min-width: 24px;
          width: auto;
          padding: 0;
          height: 24px;
          margin-left: -10px;
          border-radius: 0;
        }
        svg {
          position: absolute;
          top: 0;
          left: 0;
          &.icon-menu-open {
            height: 24px;
            width: 25px;
          }
          &.icon-menu-close {
            height: 24px;
            width: 24px;
          }
        }
    }
    }
    &-top {
      margin-bottom: 30px;
    }
  }
  .user-short {
    text-align: center;
    background-color: $color-seashell;
    border-radius: 50%;
    font-weight: $font-weight-bold;
    color: $color-success;
    border: 3px solid;
    width: 37px;
    height: 37px;
    font-size: 16px;
    position: absolute;
    right: 0;
    padding: 5px 2px;
    margin-top: 16px;
    &-minimalize {
      right: -5px;
      top: -34px;
    }
  }
  .icon-settings-user {
    width: 50px;
    height: 50px;
  }
  
  .version {
    color: #828D90;
    opacity: 0.5;
  }
  
  .tenant-name {
    position: absolute;
    top: 45px;
    left: 46px;
    font-size: $font-size-micro;
  }
  </style>
  