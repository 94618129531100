<template lang="pug">
.multi-search-select
    label.Form-item-label {{ formLabel }}
    .control
      custom-search(
      ref="vSelect"
      v-model="searchItem"
      :data-vv-as="formLabel"
      :on-search="getOptionsList"
      :options="selectOptionsList"
      :label="searchLabel"
      :placeholder="inputPlaceholder"
      :disabled="disabled"
      v-bind="$attrs"
      :loading="isLoadingAction"
      :searchAll="searchAll"
      :inputClass="inputClass")
        template(slot="no-options") {{ searchNoResult }}

    .items
      SelectedList(
        :label="searchLabel",
        :moduleName="moduleName",
        v-model="lists"
      )
</template>

<script>
import SelectedList from '@/view/global/selected-list'

export default {
  name: 'MultiSearchSelect',
  /**
  *
  **/
  props: {
    value: {},
    searchAction: {
      type: String,
      required: true
    },
    isSearchListResetInEveryAction: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    noResultText: {
      type: String,
      default: ''
    },
    searchLabel: {
      type: String,
      default: 'name'
    },
    formLabel: {
      type: String,
      default: ''
    },
    moduleName: {
      type: String,
      default: ''
    },
    searchValidation: {
      type: [String, Object],
      default: null
    },
    hasSearchPayload: {
      type: Boolean,
      default: true
    },
    searchPayload: {
      type: Object,
      default: () => { return {} }
    },
    searchResultKey: {
      type: String,
      required: true
    },
    searchKey: {
      type: String,
      default: 'Text'
    },
    uniqueKey: {
      type: String,
      default: 'id'
    },

    inputClass: {
      type: [String, Array, Object],
      default: ''
    },

    ignoredList: {
      type: Array,
      default: () => []
    },

    searchAll: {
      type: Boolean,
      default: false
    }
  },

  /**
   *
   **/

  computed: {

    inputPlaceholder () {
      return this.placeholder || this.$t('Global.search_Placeholder')
    },

    searchNoResult () {
      this.noResultText || this.$t('Global.search_NoResults')
    },

    isLoadingAction () {
      this.$wait.is(`${this.searchAction}`)
    }

  },
  components: {
    SelectedList
  },
  /**
   *
   **/

  data () {
    return {
      selectOptionsList: [],
      lists: [],
      searchItem: null
    }
  },
  /**
  *
  **/

  created () {
    this.lists = [...this.value]
  },

  methods: {

    getOptionsList (search) {
      const payload = this.hasSearchPayload ? {
        [this.searchKey]: search,
        ...this.searchPayload
      } : search

      this.$store.dispatch(`${this.searchAction}`, payload)
        .then(res => {
          if (res) {
            this.selectOptionsList = res.data[this.searchResultKey]
          }
        })
    },

    inputFocus () {
      this.$refs.vSelect.$refs.search.focus()
    },

    resetSearch () {
      this.searchItem = null
      this.selectOptionsList = []
    }
  },

  watch: {
    searchItem (val) {
      const uniqueKey = this.uniqueKey
      const isIgnored = this.ignoredList.some(item => item[uniqueKey] === val[uniqueKey])
      if (!val || isIgnored || this.lists.find(item => item[uniqueKey] === val[uniqueKey])) return
      this.lists.push(val)
    },

    lists (val) {
      this.$emit('input', val)
      if (val.length === 0) {
        this.searchItem = null
      }
    },

    value (values, oldValues) {
      if (this.lists.length === 0 && this.value.length !== 0) { // this for edit, to-do : use lodash or different way
        this.lists = [...this.value]
      }
      if (values.length === 0 && oldValues.length > 0) {
        this.lists = []
        if (this.isSearchListResetInEveryAction) this.selectOptionsList = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

 .selected-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>
