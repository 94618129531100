<template lang="pug">
.VehicleLoading
    img(:src="loading" :width="size" :height="size")
</template>

<script>
import vehicleLoading from '@/view/global/vehicle-loading/assets/inventory-loading.png'

export default {
  name: 'VehicleLoading',
  props: {
    size: {
      type: Number,
      default: 100
    }
  },
  computed: {
    loading () {
      return vehicleLoading
    }
  }
}
</script>
