<template lang="pug">
.phone-wrapper(:class="{ disabled: isDisabled }")
  .phone
    .phone-country-code(v-click-outside="closeSelectbox")
      .select-dropdown-custom(ref="selectDropdown" @click="openSelectbox")
        .select-dropdown-custom-selected(
          :class="[isOpen ? 'open' : '', callingCodes.length > 7 ? 'scroll' : '']"
        ) 
          img(:src="`/static/images/${selectedIcon}.svg`")
          span.m-left-10 {{selectedCountry.code || '+90'}}
          
          span.select-dropdown-custom-name(
            id="select-dropdown-selected-name"
          )
          svg.icon.icon-select-box-arrow
            use(xlink:href="#icon-down-arrow")
  
      .select-dropdown-custom-options.m-top-5(
        :class="[isOpen ? 'open' : '']"
        id="select-dropdown-custom-option"
      )
        .select-dropdown-custom-options-search
          Icon.Search-icon(name="icon-search")
          Button.close(
            variant="icon"
            v-if="search",
            iconName="icon-popup-close"
            iconClass="icon-popup-close"
            :justIcon="true"
            @click="clearSearch")

          customTextInput.Search-txt(
            :id="`input-custom-select-search-phone-code`"
            ref="searchField"
            v-model="search"
            :placeholder="$t('Global.Filter_SearchPlaceholder')"
          )
        div(v-for="(i, index) in callingCodes")
          span.select-dropdown-custom-options-item(@click="selectCountryCode(i)" :disabled="selectedCountry.long === i.long" :class="[selectedCountry.long === i.long ? 'disabled-country' : '', isOpen ? 'open' : '']")
            div
              img(:src="`/static/images/${i.short}.svg`")
              span.m-left-10 {{ i.long }}
            span {{ i.code }}    
    
    .phone-number
    
      customTextInput(
        type="number"
        id="input-register-phone-number"
        v-validate="'max:15'"
        v-model="phoneNumber"
        :placeholder="$t('Settings.Tenant_FormField_phoneNumber')"
        name="phoneNumber"
        :data-vv-as="$t('Settings.Tenant_FormField_phoneNumber')"
        :isDelayInput="true"
        :error="veeErrors.has('phoneNumber')"
        @input="getPhoneNumber"
      )
      showValidateError.Form-item-help(
        v-show="veeErrors.has('phoneNumber')"
        :errorName="veeErrors.first('phoneNumber')"
      )
      
</template>

<script>

import CallingCodes from '@/constant/calling-codes.js'
import customSelect from '@/view/global/custom-select'
import matchingSearch from '@/utils/matchingSearch'

export default {
  name: 'PhoneNumber',
  data () {
    return {
      drop: false,
      isOpen: false,
      isOpeningUpside: false,
      search: '',
      countryName: '',
      selectedIcon: 'TR',
      phoneNumber: null
    }
  },

  components: {
    customSelect
  },

  props: {
    selectedCountry: {
      type: Object,
      default: {long: 'Turkey', code: '+90', short: 'TR'}
    },
    existingPhoneNumber: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    callingCodes() {
      return CallingCodes.filter(temp => {
        return matchingSearch(temp.long, this.search) || matchingSearch(temp.short, this.search) || matchingSearch(temp.code, this.search) || matchingSearch(temp.code.split('+')[1], this.search)
      })
    }
  },

  mounted() {
    const existingPhone = CallingCodes.find(i => i.code === this.selectedCountry.code)
    this.selectedIcon = existingPhone?.short || 'TR'
    this.phoneNumber = this.existingPhoneNumber
  },
  methods: {
    selectCountryCode (item) {
      this.selectedIcon = item.short
      this.$emit('getCountryCode', item)
      this.closeSelectbox()
    },
    getPhoneNumber () {
      this.$emit('getPhoneNumber', this.phoneNumber)
    },
    openSelectbox () {
      this.isOpen = !this.isOpen
    },

    clearSearch () {
      this.search = ''
    },

    closeSelectbox () {
      if (this.isOpen) {
        this.isOpen = false
        this.clearSearch()
      }
    },

  }
}
</script>

<style lang="scss" scoped>
  .phone-wrapper {
    &.disabled {
      cursor: not-allowed;

      .phone {
        pointer-events: none;
      }

      .select-dropdown-custom-selected,
      .phone-number input {
        background-color: #f5f5f5;
        color: #39393988;
      }
    }
  }
  .phone {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    &-country-code {
      width: 35%;
    }
    &-number {
      width: 70%;
      height: 40px;
      z-index: 99;
      background: white;
    }
  }
  .select-dropdown-custom {
    img {
      width: 16px;
      margin-left: 10px;
    }
    position: relative;
    cursor: pointer;
    .is-danger {
      border-color: $color-warning;
      background-color: rgba($color-warning, 0.05);
    }
    &-selected {
      display: flex;
      align-items: center;
      height: 54px;
      border: 1px solid $color-gray;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: $color-white;
      .icon-select-box-arrow {
        margin-right: 10px;
        color: $color-success;
        transition: all 0.5s;
        position: absolute;
        right: 0;
        width: 12px;
        top: 38%;
      }
      &.open {
        border: 1px solid $color-gray;
        border-radius: 4px 0px 0px 4px;
        .icon-select-box-arrow {
          transform: rotate(180deg);
          top: 38%;
        }
      }
      &-button {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      &-actions {
        margin: 6px 0 10px;
        width: 60px;
      }
    }
    &-options {
      position: absolute;
      display: grid;
      top: 55px;
      left: 0;
      border: 1px solid $color-gray;
      background: $color-white;
      opacity: 0;
      white-space: pre;
      visibility: hidden;
      pointer-events: none;
      z-index: $z-index-xl;
      width: 370px;
      img {
        width: 16px;
      }
      &-item {
        padding: 10px 16px;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
        justify-content: space-between;
        cursor: pointer;
        display: none;
        &:hover {
          background: #F9FAFB;
        }
        &.open {
          display: flex;
        }

      }
      &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        overflow-y: scroll;
        overflow-x: hidden;
        height: auto;
        max-height: 285px;
        border-radius: 4px;
        width: 370px;
        &::-webkit-scrollbar {
          display: none !important;
        }
      }
      &.scroll {
        overflow-y: scroll;
        height: 355px;
      }
      &.normal {
        top: 100%;
        border-top: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
      &.upside {
        bottom: 100%;
        border-bottom: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
      &-search {
        position: sticky;
        top: 0;
        z-index: 9999;
        padding: 10px 10px;
        background: white;
        .icon {
          position: absolute;
          left: 25px;
          top: 52%;
          font-size: 20px;
          transform: translateY(-50%);
          color: $color-light;
        }

        .close {
          position: absolute;
          right: 0;

          .icon {
            width: 18px;
            height: 18px;
            top: 20px;
            left: auto;
          }

          &:hover {
            background-color: rgba($color-warning, 0.1);
          }
        }

        .txt {
          height: 44px;
          min-height: 44px;
          padding-left: 40px;
          width: 348px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    .select-dropdown-custom-name {
      position: relative;
      height: 44px;
      line-height: 44px;
      transition: all 0.5s;
      user-select: none;
      padding-left: 13px;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .selected-name {
      color: $color-light;
      white-space: nowrap;
    }
    .option-name {
      color: $color-dark;
    }
    .disabled-button {
      pointer-events: none;
      opacity: 0.6;
    }
  }
  .disabled-country {
    box-shadow: none !important;
    background: #F9FAFB !important;
    cursor: unset;
  }
  :deep() .txt {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .Form-item-help {
    font-size: 13px;
    position: absolute;
    top: 45px;
  }
</style>