<template lang="pug">
.Container
  .page-info(@click="getInfo")
    .page-info-title {{ $t('Page.newNotification')}} > 
    .page-info-content.m-left-5 {{ $t('Page.notificationText')}}
  header.Header(v-if="hasHeaderSlots")
    .header-left
      .header-title-breadcrumb
        .title.m-right-10(:style="{ color: titleColorSelection }")
          Loading(v-if="isLoadingLang")
          div(v-else)
            p(v-if="!hasDashboardSlots") {{pageTitle}}
            slot(name="header-title")
        Breadcrumb.breadcrumb
        slot(name="header-breadcrumb-left")
      .header-left-dropdown(v-if="hasHeaderLeftDropdownSlots")
        slot(name="header-left-dropdown")
      .header-left-search(v-if="hasHeaderLeftSearchSlots")
        slot(name="header-left-search")

    .header-center(v-if="hasHeaderCenterSlots")
      slot(name="header-center")
    .header-right
      .header-right-search(v-if="hasHeaderRightSearchSlots")
        //- Sağ tarafta ortaya yakın search gibi alanlar için kullanılabilir
        slot(name="header-right-search")
      .header-right-dropdown
        //- Sağ tarafta dropdown gibi alanlar için kullanılabilir
        slot(name="header-right-dropdown")
      .header-right-content
        //- Button vs gibi alanlar için kullanılabilir
        slot(name="header-right-content")
  Nav.Overgroups(v-if="$slots['overgroups-left'] || $slots['overgroups-right']")
    //- Overgroup alanımız
    .overgroups-left
      slot(name="overgroups-left")
    .overgroups-right
      slot(name="overgroups-right")

  Nav.toolbar(v-if="$slots['toolbar-left'] || $slots['toolbar-right']")
    //- Toolbarımız
    .toolbar-left
      slot(v-if="$slots['toolbar-left']" name="toolbar-left")
    .toolbar-right
      slot(v-if="$slots['toolbar-right']" name="toolbar-right")
  Article.Content
    .content-left(v-if="$slots['content-left']")
      //- Dashboard'da ki gibi half yapılırsa sol
      slot(name="content-left")
    .content-center(v-if="$slots['content-center']")
      //- Tüm sayfada kullanılacaksa
      slot(name="content-center")
    .content-right(v-if="$slots['content-right']")
      //- Sayfanın yarısında kullanılacaksa sağ 
      slot(name="content-right")
  Article.No-Content(v-if="hasNoContentSlots")
    .no-content(name="no-content")
      //- Content alanı boş ise
      slot(name="no-content")
  Footer.Footer(v-if="hasFooterSlots")
    slot(name="footer")

</template>

<script>
import Breadcrumb from '@/view/global/breadcrumb'

export default {
  name: 'Page',

  props: {
    titleType: {
      type: String,
      default: 'translate'
    }
  },

  components: {
    Breadcrumb
  },

  computed: {
    isLoadingLang () {
      return this.$wait.is('setUserLang')
    },

    isCustomTitle () {
      return this.titleType === 'custom'
    },

    pageTitle () {
      return this.isCustomTitle ? this.$route.matched[0].meta.page : this.$t('Page.header_' + this.$route.matched[0].meta.page)
    },

    titleColor () {
      return this.$route.meta.pageTitleColor || 'dark'
    },

    titleColorSelection () {
      return this.$colors[this.titleColor]
    },

    hasHeaderSlots () {
      return !!(this.$slots['header-left-dropdown'] || this.$slots['header-left-search'] || this.$slots['header-right-search'] || this.$slots['header-right-dropdown'] || this.$slots['header-right-content'] || this.$slots['header-title'] || this.$slots['header-breadcrumb-left'])
    },

    hasHeaderCenterSlots () {
      return !!(this.$slots['header-center'])
    },

    hasNoContentSlots () {
      return !!(this.$slots['no-content'])
    },

    hasFooterSlots () {
      return !!(this.$slots['footer'])
    },

    hasDashboardSlots () {
      return !!(this.$slots['header-title'])
    },
  },

  methods: {
    hasHeaderLeftDropdownSlots () {
      return !!(this.$slots['header-left-dropdown'])
    },
    hasHeaderRigthDropdownSlots () {
      return !!(this.$slots['header-right-dropdown'])
    },
    hasHeaderLeftSearchSlots () {
      return !!(this.$slots['header-left-search'])
    },
    hasHeaderRightSearchSlots () {
      return !!(this.$slots['header-right-search'])
    },

    hasHeaderLeftContentSlots () {
      return !!(this.$slots['header-left-content'])
    },
    hasHeaderRightContentSlots () {
      return !!(this.$slots['header-right-content'])
    },
    getInfo () {
      this.$emit('getInfo')
    }
  }
}
</script>

<style lang="scss" scoped>

.Container {
  background: $color-background-gray;
  min-height: 100vh
}

.Content {
  padding-left: 25px;
  padding-right: 25px;

  .content-left {
    margin: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    display:flex;
  }

  .content-center {
    flex-direction: column;
  }

  .content-right {
    flex: 1;
    margin:15px;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
    display:flex;
  }
}
.No-Content {
  padding-left: 25px;
  padding-right: 25px;
}

.Overgroups {
  padding: 0 25px 0 25px;
  margin-bottom: 10px;
  .overgroups-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .overgroups-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.Header{
  display:flex;
  justify-content: space-between;
  border-bottom: solid 1px $color-gray;
  margin-bottom: 15px;
  background: #ffff;
  padding: 20px;
  top: 0;
  z-index: $z-index-xl;
  position: sticky;
  .header-left{
    white-space: nowrap;
    display: flex;
    align-items: center;

    .title{
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
    }

    &-search {
      margin-left: 5px;
    }
  }
  .header-right{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    &-search {
      margin-right: 5px;
    }
    &-dropdown {
      margin-right: 5px;
    }
  }
}
.page-info {
  text-align: center;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  padding: 5px;
  justify-content: center;
  background: #F3F5F7;
  &-title {
    color: #19BB7A;
    font-weight: 550;
  }
  &-content {
    color: #505050;
    font-weight: 550;
  }
}
</style>