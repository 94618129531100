var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "shortkey",
            rawName: "v-shortkey",
            value: ["esc"],
            expression: "['esc']",
          },
        ],
        staticClass: "Popup",
        on: { shortkey: _vm.close },
      },
      [
        _c("div", { staticClass: "Popup-overlay", on: { click: _vm.close } }),
        _c("div", { staticClass: "Popup-body" }, [
          _c("div", { staticClass: "Popup-header" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "h3",
                  { staticClass: "Popup-header-title" },
                  [_vm._t("title")],
                  2
                ),
                _c("Button", {
                  staticClass: "Popup-header-close",
                  attrs: {
                    id: "btn-popup-view-close",
                    variant: "icon",
                    type: "button",
                    iconName: "icon-popup-close",
                    iconClass: "icon-popup-close",
                  },
                  on: { click: _vm.close },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "Popup-content" },
            _vm._l(_vm.fields, function (field, index) {
              return _vm.hyphen ||
                (_vm.item[field.name] !== "null" &&
                  _vm.item[field.name] !== null &&
                  _vm.item[field.name] !== "")
                ? _c("div", { key: index, staticClass: "Item" }, [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(_vm._s(field.title || "–")),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.item[field.name] || "–"))]),
                  ])
                : _vm._e()
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }