<template lang="pug">
custom-multiselect.toolbar-filters__item(
    :id="`business-multiselect-${componentName}`",
    :itemUniqKey="uniqKey",
    :items="multiselectItems",
    :selected="selectedItems",
    :label="label"
    :unselectedText="label",
    :popupHeader="popupHeader",
    :allLabel="allLabel",
    :buttonText="buttonText",
    :buttonIconLeft="buttonIconLeft",
    :isDisabled="isDisabled"
    :useCustomEmptyState="useCustomEmptyState"
    :customEmptyState="customEmptyState"
    :dontDisableButton="dontDisableButton"
    ref="businessMultiselect",
    :badgedTextField="badgedTextField",
    :withBadgedText="withBadgedText",
    :type="selectType"
    @delegateCheckAll="onChangeFilter",
    @delegateCheckItem="onChangeFilter",
    @delegateSubmitItems="submitFilter"
  )
</template>

<script>

export default {
  props: {
    componentName: {
      type: String,
      default: 'componentName'
    },

    customSelectedData: {
      type: Array,
      default: () => []
    },

    uniqKey: {
      type: String,
      default: 'id'
    },

    multiselectItems: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    useCustomEmptyState: {
      type: Boolean,
      default: false
    },
    
    customEmptyState: {
      type: String,
      default: ''
    },

    unselectedText: {
      type: String,
      default () {
        return this.$t('Global.toolbar_Filters_Unselected')
      }
    },

    popupHeader: {
      type: String,
      default: ''
    },

    updateDropDownLabelFromParent: {
      type: String,
      default: 'updateLabels'
    },

    allLabel: {
      type: String,
      default () {
        return this.$t('Global.toolbar_Filters_AllLabel')
      }
    },

    buttonText: {
      type: String,
      default () {
        return this.$t('Global.toolbar_Filters_ButtonText')
      }
    },

    buttonIconLeft: {
      type: String,
      default: 'btn-success'
    },

    withBadgedText: {
      type: Boolean,
      default: false
    },

    badgedTextField: {
      type: String,
      default: ''
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    dontDisableButton: {
      type: Boolean,
      default: false
    },

    selectType: {
      type: String,
      default: 'checkbox'
    },
    useUnselectedList: {
      type: Boolean,
      default: false
    }

  },

  data () {
    return {
      selectedItems: []
    }
  },

  created () {
    this.useUnselectedList ? this.selectedItems = [] : this.selectedItems = [...this.multiselectItems]
    if (this.customSelectedData.length) this.selectedItems = [...this.customSelectedData]
  },

  methods: {
    onChangeFilter (event) {
      this.selectType === 'checkbox' ? this.onChangeSelectbox(event) : this.onChangeRadio(event)
    },

    onChangeSelectbox (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedItems = [...this.multiselectItems]
        } else {
          this.selectedItems.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedItems = []
        } else {
          this.selectedItems.splice(this.selectedItems.findIndex(i => i[this.uniqKey] === event.item[this.uniqKey]), 1)
        }
      }
    },

    onChangeRadio (event) {
    },

    submitFilter (dontSubmit = false) {
      this.$emit('submitFilter', { list: this.selectedItems, dontSubmit })
    },

    clearAllFilter (dontSubmit = true) {
      if (this.selectedItems.length !== this.multiselectItems.length) {
        this.selectAllItems()
        this.clearFilter()
      }
      this.submitFilter(dontSubmit)
    },

    selectAllItems () {
      this.selectedItems = [...this.multiselectItems]
    },

    clearFilter () {
      this.$refs.businessMultiselect.showAllSelectedLabel()
    }
  },

  watch: {
    multiselectItems () {
      if (!this.customSelectedData.length) {
        this.selectedItems = [...this.multiselectItems]
        this.useUnselectedList ? this.selectedItems = [] : this.selectedItems = [...this.multiselectItems]
      }
      this.submitFilter(true)
    }
  }
}
</script>
