var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "customInputField",
    staticClass: "txt",
    class: { "is-danger": _vm.error },
    attrs: {
      autocomplete: "off",
      "data-vv-delay": _vm.isDelayInput ? _vm.$getConst("INPUT_DELAY_TIME") : 0,
      type: "text",
      disabled: _vm.disabled,
    },
    domProps: { value: _vm.value },
    on: {
      click: function ($event) {
        return _vm.selectInput($event)
      },
      input: function ($event) {
        $event.stopPropagation()
        return _vm.submitText($event)
      },
      blur: function ($event) {
        return _vm.submitInput($event, "blur")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }