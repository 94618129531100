var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderComponent
    ? _c("div", { staticClass: "NestedDetail" }, [
        _c(
          "div",
          {
            staticClass: "detailTable",
            class: { empty: _vm.detailData.length === 0 },
            style: { overflowX: _vm.dontUseScroll ? "none" : "auto" },
            attrs: { id: "NestedDetail" },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.detailData.length > 0 || _vm.showDetailWithEmptyData,
                    expression:
                      "detailData.length > 0 || showDetailWithEmptyData",
                  },
                ],
                staticClass: "NestedDetail-row p-bottom-10",
                style: _vm.gridTemplate,
                attrs: { id: `${_vm.moduleName}` },
              },
              [
                _vm.selectableAll
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "NestedDetail-row-col c-success font-size-micro-small font-weight-bold",
                      },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: `checkbox-nested-table-row`,
                            label: "",
                            checked: _vm.isAllSelected,
                          },
                          on: { change: _vm.selectAll },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.detailFields, function (field, fieldIndex) {
                  return _vm.isFieldVisible(field.is_visible)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "NestedDetail-row-col c-success font-size-micro-small font-weight-bold",
                          class: [
                            field.name,
                            {
                              price: field.type === "currency",
                              "u-textRight":
                                field.type === "quantity" ||
                                field.type === "currency" ||
                                field.type === "mono" ||
                                field.editable,
                              "fixed-col": field.type === "fixed",
                            },
                          ],
                          style: field.style || "",
                          attrs: {
                            id: `NestedDetailItem-header-${fieldIndex}`,
                          },
                        },
                        [_vm._v(_vm._s(field.short_title))]
                      )
                    : _vm._e()
                }),
              ],
              2
            ),
            _vm._l(_vm.detailData, function (row, rowIndex) {
              return _c(
                "div",
                {
                  staticClass: "NestedDetail-row list-item",
                  style: _vm.gridTemplate,
                  attrs: { tabindex: "-1", id: `NestedDetailItem-${rowIndex}` },
                  on: {
                    click: function ($event) {
                      return _vm.configureRow(row)
                    },
                  },
                },
                [
                  _vm.selectable
                    ? _c(
                        "div",
                        { staticClass: "NestedDetail-row-col checkbox" },
                        [
                          _c("CustomCheckbox", {
                            attrs: {
                              id: `checkbox-nested-table-select-all-${row.id}`,
                              label: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.selectItem(row)
                              },
                            },
                            model: {
                              value: row.isChecked,
                              callback: function ($$v) {
                                _vm.$set(row, "isChecked", $$v)
                              },
                              expression: "row.isChecked",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.detailFields, function (field, fieldIndex) {
                    return _vm.isFieldVisible(field.is_visible)
                      ? _c(
                          "div",
                          {
                            staticClass: "NestedDetail-row-col",
                            class: [
                              field.name,
                              {
                                price: field.type === "currency",
                                "ff-mono u-textRight":
                                  field.type === "currency" ||
                                  field.type === "mono" ||
                                  field.type === "quantity",
                                "fixed-col": field.type === "fixed",
                                "u-textRight": field.editable,
                                "tooltip-relative": field.type === "supplier",
                              },
                            ],
                            style: field.style || "",
                            attrs: {
                              id: `${_vm.moduleName}-${_vm.headerRowIndex}-${rowIndex}-${fieldIndex}`,
                            },
                          },
                          [
                            field.type === "select"
                              ? [
                                  field.editable === true &&
                                  _vm.detailActions.indexOf("edit") > -1 &&
                                  (_vm.rowData.status === 1 ||
                                    (_vm.isActionButtonsVisible &&
                                      _vm.rowData.status === 4))
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.form[rowIndex][
                                                        field.name
                                                      ],
                                                    expression:
                                                      "form[rowIndex][field.name]",
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value:
                                                      _vm.generateValidationRules(
                                                        field.validationRules,
                                                        row
                                                      ),
                                                    expression:
                                                      "generateValidationRules(field.validationRules, row)",
                                                  },
                                                ],
                                                staticClass: "txt table-input",
                                                class: {
                                                  "is-danger":
                                                    _vm.veeErrors.has(
                                                      `select-${rowIndex}`
                                                    ),
                                                },
                                                attrs: {
                                                  tabindex: _vm.tabindex(
                                                    rowIndex,
                                                    field
                                                  ),
                                                  id: `select-nested-table-row-${rowIndex}`,
                                                  name: `select-${rowIndex}`,
                                                  disabled: field.disabled(row),
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.form[rowIndex],
                                                        field.name,
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.changeValue(
                                                        field.name,
                                                        row,
                                                        rowIndex
                                                      )
                                                    },
                                                  ],
                                                },
                                              },
                                              _vm._l(
                                                field.selectList.options(
                                                  row[field.filterKey]
                                                ),
                                                function (item) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: item[
                                                        field.selectList.value
                                                      ],
                                                      attrs: {
                                                        id: `option-nested-table-select-${rowIndex}`,
                                                      },
                                                      domProps: {
                                                        value:
                                                          item[
                                                            field.selectList
                                                              .value
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item[
                                                            field.selectList
                                                              .label
                                                          ]
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c("Icon", {
                                              staticClass: "icon-arrow-down",
                                              attrs: {
                                                name: "icon-arrow-down",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        field.dataField === "unit"
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-unitName`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.findUnit(
                                                        row[field.name]
                                                      ).name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    id: `${
                                                      _vm.moduleName
                                                    }-${rowIndex}-${fieldIndex}-${
                                                      row[field.name]
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(row[field.name])
                                                  ),
                                                ]
                                              ),
                                            ],
                                      ],
                                ]
                              : field.type === "status"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-statusText`,
                                      },
                                    },
                                    [_vm._v(_vm._s(field.getStatusText(row)))]
                                  ),
                                ]
                              : field.type === "currency"
                              ? [
                                  field.editable === true &&
                                  _vm.rowData.status === 1 &&
                                  _vm.detailActions.indexOf("edit") > -1
                                    ? [
                                        _c("currency-input", {
                                          staticClass:
                                            "table-input txt ff-mono u-textRight",
                                          attrs: {
                                            value: row[field.name],
                                            id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-currencyInput`,
                                          },
                                          nativeOn: {
                                            blur: function ($event) {
                                              return _vm.changeValue.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-currencyValue`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  row[field.name]
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              : field.type === "mono"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "100%",
                                      },
                                      attrs: {
                                        id: `${
                                          _vm.moduleName
                                        }-${rowIndex}-${fieldIndex}-${
                                          row[field.name]
                                        }`,
                                      },
                                    },
                                    [_vm._v(_vm._s(row[field.name]))]
                                  ),
                                ]
                              : field.type === "with-badge"
                              ? [
                                  _vm._v(_vm._s(row[field.name])),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "row-badge",
                                      class: [field.typeData(row).className],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(field.typeData(row).badgeText)
                                      ),
                                    ]
                                  ),
                                ]
                              : field.type === "percent"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          row[field.name] !== null
                                            ? "%" + row[field.name]
                                            : "-"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              : field.type === "date"
                              ? [
                                  field.editable === true &&
                                  _vm.rowData.status === 1 &&
                                  _vm.detailActions.indexOf("edit") > -1
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.form[rowIndex][
                                                      field.name
                                                    ],
                                                  expression:
                                                    "form[rowIndex][field.name]",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: `date_format:YYYY-MM-DD|date_between:${field.usableDate.from},${field.usableDate.to}`,
                                                  expression:
                                                    "`date_format:YYYY-MM-DD|date_between:${field.usableDate.from},${field.usableDate.to}`",
                                                },
                                              ],
                                              staticClass: "table-input txt",
                                              class: {
                                                "is-danger": _vm.veeErrors.has(
                                                  `date-${rowIndex}`
                                                ),
                                              },
                                              attrs: {
                                                type: "date",
                                                tabindex: field.tabIndex
                                                  ? _vm.tabindex(
                                                      rowIndex,
                                                      field
                                                    )
                                                  : "",
                                                id: `date-input-table-row-${rowIndex}`,
                                                name: `date-${rowIndex}`,
                                                min: field.usableDate.from,
                                                format: "YYYY-MM-DD",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.form[rowIndex][
                                                    field.name
                                                  ],
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.changeValue(
                                                    field.name,
                                                    row,
                                                    rowIndex,
                                                    "date"
                                                  )
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.form[rowIndex],
                                                    field.name,
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c("Icon", {
                                              staticClass: "icon-arrow-down",
                                              attrs: {
                                                name: "icon-arrow-down",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              id: `${_vm.moduleName}-${rowIndex}-${fieldIndex}-dateValue`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                new Date(
                                                  row[field.name]
                                                ).toLocaleDateString(
                                                  _vm.$i18n.locale
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              : field.type === "quantity"
                              ? [
                                  field.editable === true &&
                                  _vm.detailActions.indexOf("edit") > -1 &&
                                  (_vm.rowData.status === 1 ||
                                    (_vm.isActionButtonsVisible &&
                                      _vm.rowData.status === 4))
                                    ? [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value:
                                                _vm.generateValidationRules(
                                                  field.validationRules,
                                                  row
                                                ),
                                              expression:
                                                "generateValidationRules(field.validationRules, row)",
                                            },
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value:
                                                _vm.form[rowIndex][field.name],
                                              expression:
                                                "form[rowIndex][field.name]",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          ref: "quantityInput",
                                          refInFor: true,
                                          staticClass:
                                            "table-input txt no-spinners quantity",
                                          class: {
                                            "is-danger": _vm.veeErrors.has(
                                              `quantity-${rowIndex}`
                                            ),
                                          },
                                          attrs: {
                                            id: `quantity-input-table-row-${rowIndex}`,
                                            name: `quantity-${rowIndex}`,
                                            autocomplete: "off",
                                            tabindex: _vm.tabindex(
                                              rowIndex,
                                              field
                                            ),
                                            disabled:
                                              field.disabledStatus &&
                                              field.disabledStatus(row),
                                            type: "number",
                                            step: "any",
                                          },
                                          domProps: {
                                            value:
                                              _vm.form[rowIndex][field.name],
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeValue(
                                                field.name,
                                                row,
                                                rowIndex
                                              )
                                            },
                                            click: function ($event) {
                                              return $event.target.select()
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form[rowIndex],
                                                field.name,
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                row[field.name] !== null
                                                  ? row[field.name]
                                                  : "-"
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              : field.type === "supplier"
                              ? [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getSupplierName(row[field.name])
                                      )
                                    ),
                                  ]),
                                  row[field.name] && row[field.name].length > 10
                                    ? _c("Tooltip", {
                                        attrs: {
                                          white: "",
                                          right: "",
                                          text: row[field.name],
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : field.type === "portal"
                              ? [
                                  _c("portalTarget", {
                                    attrs: {
                                      name: field.name,
                                      "slot-props": (_vm.detail = {
                                        row,
                                        rowIndex,
                                      }),
                                    },
                                  }),
                                ]
                              : field.type === "spacer"
                              ? [_c("span")]
                              : [_vm._v(_vm._s(row[field.name] || "-"))],
                            field.withDescription &&
                            field.getTypeText(row.type).text
                              ? [
                                  _c(
                                    "span",
                                    {
                                      class: field.getTypeText(row.type).class,
                                      attrs: {
                                        id: `${
                                          field.getTypeText(row.type).id
                                            ? field.getTypeText(row.type).id
                                            : "custom"
                                        }-${
                                          _vm.moduleName
                                        }-${rowIndex}-${fieldIndex}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(field.getTypeText(row.type).text)
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e()
                  }),
                  _vm.showDetailWithEmptyData ||
                  (!_vm.readOnly &&
                    !_vm.readOnlyForDetail &&
                    (!_vm.rowData.approvalStatus ||
                      _vm.rowData.approvalStatus === 0 ||
                      (_vm.rowData.approvalStatus === 3 &&
                        _vm.isActionButtonsVisible)) &&
                    (_vm.rowData.status === 1 ||
                      (_vm.isActionButtonsVisible && _vm.rowData.status === 4)))
                    ? _c("ActionButton", {
                        attrs: { data: _vm.detailActions, item: row },
                        on: {
                          onItemDelete: function ($event) {
                            return _vm.deleteDetail(row)
                          },
                          onItemEdit: function ($event) {
                            return _vm.editDetail(row)
                          },
                          onItemExport: function ($event) {
                            return _vm.exportDetail(row)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.usePortalAction
                    ? _c(
                        "div",
                        { staticClass: "NestedDetail-row-col actions" },
                        [
                          _c("portalTarget", {
                            attrs: {
                              name: "actionPortal",
                              "slot-props": (_vm.rows = {
                                rowData: _vm.rowData,
                                row,
                              }),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            }),
            _vm.showDetailWithEmptyData && !_vm.isDetailDataLoading
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.newRow, function (row, rowIndex) {
                      return _c(
                        "div",
                        {
                          staticClass: "NestedDetail-row list-item",
                          style: _vm.gridTemplate,
                          attrs: {
                            tabindex: "-1",
                            id: `NestedDetailItem-${rowIndex}`,
                          },
                        },
                        _vm._l(_vm.detailFields, function (field, fieldIndex) {
                          return _vm.isFieldVisible(field.is_visible)
                            ? _c(
                                "div",
                                {
                                  staticClass: "NestedDetail-row-col",
                                  class: [
                                    field.name,
                                    {
                                      price: field.type === "currency",
                                      "ff-mono u-textRight":
                                        field.type === "currency" ||
                                        field.type === "mono" ||
                                        field.type === "quantity",
                                      "fixed-col": field.type === "fixed",
                                      "u-textRight": field.editable,
                                    },
                                  ],
                                  style: field.style || "",
                                  attrs: {
                                    id: `${_vm.moduleName}-${_vm.headerRowIndex}-${rowIndex}-${fieldIndex}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.configureRow(row)
                                    },
                                  },
                                },
                                [
                                  field.type === "portal"
                                    ? [
                                        _c("portalTarget", {
                                          attrs: {
                                            name: field.name,
                                            "slot-props": (_vm.detail = {
                                              row,
                                              rowIndex,
                                            }),
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    }),
                    _vm.activateNewRow
                      ? _c("Button", {
                          attrs: {
                            iconName: "circle-add",
                            justIcon: true,
                            iconClass: "circle-add",
                          },
                          on: { click: _vm.addNewData },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ],
          2
        ),
        !_vm.readOnly && !_vm.usePortalFooter
          ? _c(
              "div",
              { staticClass: "NestedDetail-foot m-top-20" },
              [
                _vm.rowData.status === 1 && _vm.canAddNewDetail
                  ? _c(
                      "Button",
                      {
                        staticClass: "m-right-20",
                        attrs: {
                          id: "nested-detail-add-button",
                          primary: "",
                          inline: "",
                          size: "small",
                          disabled: _vm.disableNewDetailButton,
                          iconName: "icon-global-add",
                          iconClass: "icon-global-add",
                        },
                        on: { click: _vm.addNewDetail },
                      },
                      [_c("span", [_vm._v(" " + _vm._s(_vm.addButtonText))])]
                    )
                  : _vm._e(),
                _vm.rowData.status === 1 && _vm.isFinalizeAction
                  ? _c(
                      "Button",
                      {
                        staticClass: "m-right-20",
                        attrs: {
                          disabled:
                            _vm.detailData.length === 0 ||
                            !_vm.isValidForm ||
                            _vm.isLoading ||
                            _vm.disableFinalizeButton ||
                            _vm.isLoadingFinalize,
                          id: "nested-detail-finalize-button",
                          primary: "",
                          inline: "",
                          size: "small",
                          iconName: "icon-btn-success",
                          iconClass: "icon-left",
                        },
                        on: { click: _vm.makeFinalize },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.finalizeButtonText))])]
                    )
                  : _vm._e(),
                _vm.extraButtonTexts.length > 0
                  ? [
                      _vm.rowData.status === 2
                        ? _c(
                            "Button",
                            {
                              staticClass: "m-right-20",
                              attrs: {
                                disabled: _vm.detailData.length === 0,
                                id: "nested-detail-extra-action-button-1",
                                inline: "",
                                primary: "",
                                size: "small",
                                iconName: "icon-btn-success",
                                iconClass: "icon-left",
                              },
                              on: { click: _vm.detailExtraAction1 },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.extraButtonTexts[0])),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.rowData.status === 2 &&
                      _vm.extraButtonTexts.length === 2
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                disabled: _vm.detailData.length === 0,
                                id: "nested-detail-extra-action-button-2",
                                primary: "",
                                inline: "",
                                size: "small",
                                iconName: "icon-btn-success",
                                iconClass: "icon-left",
                              },
                              on: { click: _vm.detailExtraAction2 },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.extraButtonTexts[1])),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.usePortalFooter
          ? _c(
              "div",
              { staticClass: "NestedDetail-foot m-top-20" },
              [
                _c("portalTarget", {
                  attrs: { name: "footerPortal", "slot-props": _vm.rowData },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }