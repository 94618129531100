var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ReqireStep" }, [
    _c(
      "div",
      { staticClass: "inner-container" },
      [
        _vm.iconName
          ? _c("Icon", {
              staticClass: "icon-class",
              attrs: { name: _vm.iconName },
            })
          : _vm._e(),
        _c(
          "h3",
          { staticClass: "title", attrs: { id: "require-step-title" } },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _c(
          "p",
          {
            staticClass: "description",
            attrs: { id: "require-step-description" },
          },
          [_vm._v(_vm._s(_vm.description))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }