var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-input" },
    [
      _c("Icon", { attrs: { name: "icon-search" } }),
      _c(
        "input",
        _vm._b(
          {
            staticClass: "txt",
            attrs: { type: "txt", placeholder: _vm.searchPlaceholder },
            on: { input: _vm.onSearchAction },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }