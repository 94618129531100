var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "UserDrop",
    },
    [
      _c(
        "button",
        {
          staticClass: "UserDrop-action",
          attrs: { id: "btn-user-dropdown", type: "button" },
          on: {
            click: function ($event) {
              _vm.drop = !_vm.drop
            },
          },
        },
        [_vm._v(_vm._s(_vm.shortName))]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.drop,
              expression: "drop",
            },
          ],
          staticClass: "UserDrop-menu",
        },
        [
          _c("div", { staticClass: "UserDrop-menu-row info" }, [
            _c("div", { staticClass: "user" }, [
              _vm._v(
                _vm._s(_vm.Profile.user.firstname) +
                  " " +
                  _vm._s(_vm.Profile.user.surname)
              ),
            ]),
            _c("div", { staticClass: "tenant" }, [
              _vm._v(_vm._s(_vm.Profile.tenant.name)),
            ]),
          ]),
          _c("div", { staticClass: "UserDrop-menu-row settings" }, [
            _c(
              "button",
              {
                staticClass: "UserDrop-menu-button",
                attrs: { id: "btn-user-dropdown-settings", type: "button" },
                on: { click: _vm.settings },
              },
              [_vm._v(_vm._s(_vm.$t("Global.userMenu_Settings")))]
            ),
          ]),
          _c(
            "div",
            { staticClass: "UserDrop-menu-row logout" },
            [
              _c(
                "Button",
                {
                  staticClass: "UserDrop-menu-button",
                  attrs: {
                    id: "btn-user-dropdown-logout",
                    type: "button",
                    iconName: "icon-logout",
                    iconClass: "icon-logout",
                  },
                  on: { click: _vm.logOut },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Global.userMenu_Logout")))])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "UserDrop-menu-row version" },
            [
              _c("Icon", { attrs: { name: "icon-global-info" } }),
              _c("span", [_vm._v("v" + _vm._s(_vm.appVersion))]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }