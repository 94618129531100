var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status" },
    [
      _c("Icon", {
        staticClass: "icon-row-status status-icon",
        attrs: { name: "icon-row-status" },
      }),
      _c("div", { staticClass: "status-title" }, [_vm._v(_vm._s(_vm.message))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }