<template lang="pug">
businessMultiselect(
      componentName="supplier-filter"
      :multiselectItems="supplierList"
      :label="$t('Global.toolbar_Supplier_Label')"
      :popupHeader="$t('Global.toolbar_Supplier_PopupHeader')"
      :unselectedText="$t('Global.toolbar_Filters_Unselected')"
      ref="businessSupplierFilter"
      @submitFilter="submitFilter"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {

  components: {
    businessMultiselect
  },

  data () {
    return {
      supplierFilter: []
    }
  },

  async beforeMount () {
    await this.getMiniSuppliersList()
    this.supplierFilter = [...this.supplierList]
  },

  computed: {
    ...mapGetters('Supplier', {
      supplierList: 'MiniSuppliers'
    })
  },

  methods: {
    ...mapActions('Supplier', [
      'getMiniSuppliersList'
    ]),

    submitFilter ({ list, dontSubmit }) {
      this.$emit('submitFilter', {list, dontSubmit})
    },

    clearSupplierFilter (dontSubmit = true) {
      this.$refs.businessSupplierFilter.clearAllFilter(dontSubmit)
    }
  }
}
</script>
