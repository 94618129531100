var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideReasonPopup,
          expression: "hideReasonPopup",
        },
      ],
      staticClass: "popup-container m-right-20",
    },
    [
      _c(
        "Button",
        {
          attrs: {
            id: `${_vm.componentName}-reason-show`,
            type: "button",
            size: "small",
            danger: "",
            disabled: _vm.isButtonDisable,
          },
          on: { click: _vm.showReasonPopup },
        },
        [_vm._v(_vm._s(_vm.buttonText))]
      ),
      _vm.showReasonButtonPopup
        ? _c(
            "form",
            {
              staticClass: "description-right-MiniPopup",
              attrs: { id: `${_vm.componentName}-reason-form` },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "label",
                {
                  staticClass: "description-MiniPopup-label required",
                  attrs: { id: `${_vm.componentName}-reason-label` },
                },
                [_vm._v(_vm._s(_vm.descText))]
              ),
              _c("textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, max: 128 },
                    expression: "{required: true, max: 128}",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reasonDescription,
                    expression: "reasonDescription",
                  },
                ],
                ref: "reason",
                staticClass: "txt description-MiniPopup-input",
                class: { "is-danger": _vm.veeErrors.has(`reason`) },
                attrs: {
                  name: "reason",
                  autocomplete: "off",
                  id: `${_vm.componentName}-reason-input`,
                },
                domProps: { value: _vm.reasonDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.reasonDescription = $event.target.value
                  },
                },
              }),
              _c(
                "Button",
                {
                  staticClass: "m-top-15",
                  attrs: {
                    type: "submit",
                    primary: "",
                    size: "medium",
                    variant: "full",
                    disabled: _vm.isDisable,
                    id: `${_vm.componentName}-reason-submit`,
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.agreeButtonText))])]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }