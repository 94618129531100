var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderComponent && _vm.data.length > 0
    ? _c(
        "div",
        [
          _c(
            "Button",
            {
              staticClass: "action-button p-bottom-15",
              attrs: {
                variant: "icon small c-light",
                id: "btn-table-show-actions-header",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showActionBar(_vm.item)
                },
              },
            },
            [
              _c("span", [
                _vm._v("..."),
                _vm.item?.showActions && _vm.showActions
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: () => _vm.closeActionBar(_vm.item),
                            expression: "() => closeActionBar(item)",
                          },
                        ],
                        staticClass: "action-box",
                      },
                      [
                        _vm.shouldShow("overview") &&
                        !_vm.showExportOnly &&
                        !_vm.cannotBeViewed
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-table-overview`",
                                  size: "small",
                                  iconName: "icon-login-eye",
                                  iconClass: "icon-login-eye",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemOverview.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Global.overview"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.shouldShow("separateInvoice") && !_vm.showExportOnly
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-table-separate-invoice`",
                                  size: "small",
                                  iconName: "icon-separate-invoice",
                                  iconClass: "icon-separate-invoice",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemSeparateInvoice.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Global.separateInvoice"))
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.shouldShow("export")
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "btn-export-action",
                                  type: "button",
                                  size: "small",
                                  iconName: "icon-export",
                                  iconClass: "icon-export",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemExport.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Global.export"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.shouldShow("copy") && !_vm.showExportOnly
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-nested-table-copy`",
                                  size: "small",
                                  iconName: "icon-global-copy",
                                  iconClass: "icon-global-copy",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemCopy.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Global.copy"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.shouldShow("edit") && !_vm.showExportOnly
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-nested-table-edit`",
                                  size: "small",
                                  iconName: "icon-global-edit",
                                  iconClass: "icon-global-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemEdit.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Global.edit"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.shouldShow("active") && !_vm.showExportOnly
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-table-activate`",
                                  size: "small",
                                  iconName: !_vm.item.isActive
                                    ? `icon-global-active-recipe`
                                    : `icon-global-passive-recipe`,
                                  iconClass: !_vm.item.isActive
                                    ? `icon-global-active-recipe`
                                    : `icon-global-passive-recipe`,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemChangeActiveStatus.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm.item.isActive
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Global.passive"))),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Global.activate"))),
                                    ]),
                              ]
                            )
                          : _vm._e(),
                        (_vm.shouldShow("remove") ||
                          _vm.shouldShow("delete")) &&
                        !_vm.showExportOnly
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-table-delete`",
                                  size: "small",
                                  iconName: "icon-global-remove",
                                  iconClass: "icon-global-remove",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemDelete.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Global.delete"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.shouldShow("customAction") && !_vm.showExportOnly
                          ? _c(
                              "Button",
                              {
                                attrs: {
                                  id: "`btn-table-custom-action`",
                                  size: "small",
                                  iconName: _vm.customActionIconName,
                                  iconClass: _vm.customActionIconClass,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onItemCustomAction.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.customActionName)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }