var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("businessMultiselect", {
    ref: "businessStoreFilter",
    attrs: {
      componentName: "multiselect-store-filter",
      label: _vm.labelTitle,
      popupHeader: _vm.$t("Global.toolbar_Stores_PopupHeader"),
      multiselectItems: _vm.storeList,
    },
    on: { submitFilter: _vm.submitFilter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }