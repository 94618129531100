var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container p-0" },
    [
      _c(
        "div",
        { staticClass: "Boxlist" },
        _vm._l(_vm.data, function (row, rowIndex) {
          return _c(
            "div",
            {
              staticClass: "Boxlist-item flexbox direction-column",
              class:
                _vm.showPassiveBox && !row.isActive
                  ? "passive"
                  : _vm.extraClass.forBox,
            },
            [
              _c(
                "div",
                { staticClass: "Boxlist-actions" },
                [
                  _c("portal-target", {
                    staticClass: "action-calorie",
                    attrs: {
                      name: "action-calorie",
                      "slot-props": (row = _vm.setNewRow(row, rowIndex)),
                    },
                  }),
                  _c("portal-target", {
                    staticClass: "action-buttons",
                    attrs: {
                      name: "action-buttons",
                      "slot-props": (row = _vm.setNewRow(row, rowIndex)),
                    },
                  }),
                  _c("portal-target", {
                    staticClass: "box-header-info",
                    attrs: {
                      name: "box-header-info",
                      "slot-props": (row = _vm.setNewRow(row, rowIndex)),
                    },
                  }),
                  _vm.usePortalAction
                    ? _c("portalTarget", {
                        attrs: { name: "actionPortal-Box", "slot-props": row },
                      })
                    : _vm._e(),
                  !_vm.usePortalAction
                    ? _c("ActionButton", {
                        attrs: {
                          id: "box-action-button",
                          data: _vm.actions,
                          item: row,
                        },
                        on: {
                          onItemEdit: function ($event) {
                            return _vm.onItemEdit(row)
                          },
                          onItemDelete: function ($event) {
                            return _vm.onItemRemove(row)
                          },
                          onItemCopy: function ($event) {
                            return _vm.onItemCopy(row)
                          },
                          onItemChangeActiveStatus: function ($event) {
                            return _vm.onItemChangeActiveStatus(row)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "box-header flexbox",
                  class:
                    _vm.showPassiveBox && !row.isActive
                      ? "passive-head"
                      : _vm.extraClass.forHead,
                },
                [
                  _c("portal-target", {
                    attrs: {
                      name: "box-header-left",
                      "slot-props": (row = _vm.setNewRow(row, rowIndex)),
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: {
                        id: `box-list-${_vm.componentName}-title-${rowIndex}`,
                      },
                    },
                    [
                      _vm.title.length
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "matched-title-name-text tooltip-relative",
                              attrs: {
                                id: `box-list-${_vm.componentName}-title-name-text-${rowIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.getName(row[_vm.title[0].name]))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.subTitle.length
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "matched-property-name tooltip-relative",
                              attrs: {
                                id: `box-list-${_vm.componentName}-span-name-text-${rowIndex}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getName(
                                    _vm.getSelectionName(_vm.subTitle, row)
                                  )
                                )
                              ),
                              (_vm.subTitle[0].useCustomSubTitle
                                ? _vm.getCustomSubTitle(row)
                                : row[_vm.subTitle[0].name]
                              )?.length > 30
                                ? _c("Tooltip", {
                                    attrs: {
                                      white: "",
                                      bottom: "",
                                      text: _vm.subTitle[0].useCustomSubTitle
                                        ? _vm.getCustomSubTitle(row)
                                        : row[_vm.subTitle[0].name],
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c("BoxContent", {
                attrs: {
                  componentName: _vm.componentName,
                  fields: _vm.fields,
                  extraClass: _vm.extraClass.forContent,
                  data: row,
                  rowIndex: rowIndex,
                },
              }),
              _c("Button", {
                staticClass: "boxLink",
                attrs: {
                  id: `box-list-${_vm.componentName}-row-${rowIndex}`,
                  variant: "full",
                },
                on: {
                  click: function ($event) {
                    return _vm.onClick(row, "Show")
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.page
        ? [
            _c("BoxFoot", {
              attrs: { componentName: _vm.componentName, page: _vm.page },
              on: {
                delegateOnChangePage: _vm.onChangePage,
                delegateOnChangePageSize: _vm.onChangePageSize,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }