var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.links, function (link, index) {
      return _c(
        "router-link",
        {
          key: index,
          staticClass: "breadcrumb-link",
          attrs: { to: { name: link.to }, id: `breadcrumb-${link.name}` },
        },
        [
          _c("span", [_vm._v(_vm._s(link.name))]),
          _vm.links.length - 1 > index
            ? _c("span", { staticClass: "breadcrumb-link-icon" }, [
                _vm._v(_vm._s(">")),
              ])
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }