var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "select",
      class: { "is-danger": _vm.error },
      attrs: { id: "custom-form-select-input" },
      on: {
        change: function ($event) {
          return _vm.submitSelect($event)
        },
        click: _vm.getOptions,
      },
    },
    [
      !_vm.hideDefault
        ? _c(
            "option",
            {
              attrs: {
                value: "-1",
                disabled: _vm.isDefaultDisable,
                id: `${_vm.optionIdName}-select-input-unselected`,
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.existingData && _vm.optionData.length === 0
                    ? _vm.existingData
                    : _vm.defaultText
                )
              ),
            ]
          )
        : _vm._e(),
      _vm._l(_vm.optionData, function (data) {
        return _c(
          "option",
          {
            key: data[_vm.optionIdKey],
            attrs: { id: `${_vm.optionIdName}-${data[_vm.optionIdKey]}` },
            domProps: {
              value: data[_vm.optionKey],
              selected: _vm.selectedOption(data),
            },
          },
          [_c("span", [_vm._v(_vm._s(data[_vm.optionName]))])]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }