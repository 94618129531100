<template lang="pug">
transition(name="fade")
    .Notify(v-if="hasMessage && !error")
      span.Notify-icon
        Icon(name="icon-notify-icon")
      span.Notify-messages(v-html="message")
      Button.Notify-close(
      id="btn-notify-hide"
      type="button",
      variant="icon"
      iconName="icon-notify-close",
      iconClass="icon-notify-close",
      :justIcon="true",
      @click="notifyHide")
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Notify',
  data () {
    return {
      timeout: null
    }
  },
  watch: {
    message () {
      if (this.hasMessage && !this.error) {
        this.timeout = setTimeout(() => {
          this.notifyHide()
        }, this.delay)
      }
    }
  },
  computed: {
    ...mapGetters('Notify', ['error', 'hasMessage', 'message', 'delay'])
  },
  methods: {
    ...mapActions('Notify', ['notifyHide'])
  }
}
</script>

<style lang="scss" scoped>

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.3s;
    opacity: 0;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-leave {
    opacity: 1;
  }

  .Notify {
    z-index: $z-index-xxl;
    position: fixed;
    top: 20px;
    left: 50%;
    display: flex;
    padding: 20px 10px 20px 20px;
    align-items: flex-start;
    transform: translateX(-50%);
    color: #fff;
    background-color: $color-success;
    border-radius: $finger-size;
    box-shadow: 0 10px 30px rgba(black, 0.2);

    &-icon {
      margin-right: 15px;
    }

    &-messages {
      max-width: 400px;
    }

    &-close {
      align-self: center;
      margin-bottom: -15px;
      margin-top: -20px;
      color: #159B54;
    }
  }
</style>
