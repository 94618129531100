<template lang="pug">
label.label.tooltip-relative(
    :id="'row-' + id"
    @mousedown="active(true)"
    @mouseup="toggle(false)"
    @mouseleave="active(false)"
  )

  input.input(
    type="checkbox"
    :checked="checked"
    :disabled="disabled"
    :id="`${id}-input`"
  )

  span.indicator(
    :class="indicatorClassObject"
    @keydown.space.prevent="active(true)"
    @keyup.space.prevent="toggle(false)"
    :tabindex="tabindex"
    :id="`${id}-indicator`"
  )

    Icon(name="icon-custom-checkbox-tick")
  | {{ getFieldName(label) }}
  Tooltip(
    v-if="label.length > 30 && useTooltipForLabel"
    white
    bottom
    :text="label"
  )
</template>

<script>

export default {
  name: 'CustomCheckbox',

  model: {
    prop: 'checked',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    arrowIcon: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    useTooltipForLabel: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    tabindex () {
      return this.disabled ? -1 : 0
    },

    indicatorClassObject () {
      return {
        active: this.isActive,
        bare: this.label === '',
        large: this.size === 'large',
        disabled: this.disabled,
        checked: this.$props.checked,
      }
    }
  },

  methods: {
    active (isActive) {
      if (!this.disabled) {
        this.isActive = isActive
      }
    },

    toggle (isActive) {
      if (!this.disabled && this.isActive) {
        this.active(!!isActive)
        this.$emit('change', !this.$props.checked)
      }
    },

    getFieldName (name) {
      return (name && name.length > 30 && this.useTooltipForLabel) ? name.substr(0, 30).concat('...') : name
    },
  }
}
</script>

<style scoped lang="scss">

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // width: max-content;
    font-size: 14px;
    color: $color-light;
    cursor: pointer;
  }

  .input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    display: none;
  }

  .indicator {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    border: 2px solid $color-success;
    border-radius: 4px;
    background: #fff;
    outline-color: $color-success !important;

    &.active {
      background: $color-success !important;
      border: $color-success !important;
    }

    &:hover {
      background: #1ecf713f;
    }

    &.checked .icon {
      display: inline-block;
    }

    &.checked {
      border: 1px solid $color-success;
    }

    &.checked:not(.active) {
      background: $color-success;
    }

    &.checked.active {
      background: desaturate(darken($color-success, 5%), 25%);
    }

    &.disabled,
    &.disabled.checked {
      outline: none;
      background: $color-gray;
      border-color: $color-gray;
    }

    &.disabled.checked .icon {
      color: darken($color-gray, 25%);
    }    

    &.bare {
      margin: 0;
    }

    &.large {
      width: 27px;
      height: 27px;

      .icon {
        width: 14.5px;
        height: 11px;
      }
    }

    &:focus {
      outline-width: 5px;
      outline-style: auto;
      outline-color: rgb(59, 153, 252);
      outline-color: -webkit-focus-ring-color;
    }
  }

  .icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-white;
  }
</style>
