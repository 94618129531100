<template lang="pug">
div.filterList
    div.search
      .inputSearch
        Icon(
          name="icon-search"
        )
        input.txt(
          v-model="searchText",
          :id="`input-general-filter-${id}`"
          :placeholder="$t('Global.GlobalFilterSearchTitle', { field: title })"
        )
    div.listItems
      h4.title {{ title }}
      .item(
        v-if="!searchText"
      )
        CustomCheckbox(
          :id="`checkbox-general-filter-${id}-all-selected`"
          :label="$t('Global.toolbar_Filters_AllLabel')"
          :checked="isAllSelected",
          @change="isChecked => toggleAllSelected(isChecked)"
        )
      .item(
        v-for="(item, index) in searchingList",
        :key="item.id"
      )
        CustomCheckbox(
          :id="`checkbox-general-filter-${id}-${index}`"
          :label="item.name"
          :checked="selectedList.some(i => i.id === item.id)",
          @change="isChecked => changeList({ isChecked, item })"
        )
</template>

<script>

import matchingSearch from '@/utils/matchingSearch'

export default {
  name: 'checkFilter',
  props: {
    value: {},
    id: {
      type: String,
      default: 'custom-filter'
    },
    title: {
      type: String,
      default: ''
    },
    filterList: {
      type: Array,
      default () {
        return []
      }
    },
    filterName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      selectedList: [],
      searchText: ''
    }
  },

  computed: {
    searchingList () {
      return this.filterList.filter(item => {
        return matchingSearch(item.name, this.searchText)
      })
    },
    isAllSelected () {
      return this.searchingList.length === this.selectedList.length
    }
  },
  methods: {
    changeList ({ isChecked, item }) {
      if (isChecked) {
        this.selectedList.push(item)
      } else {
        this.selectedList.splice(this.selectedList.findIndex(i => i.id === item.id), 1)
      }
    },
    toggleAllSelected (isChecked) {
      if (isChecked) {
        this.setSelectedList([...this.searchingList])
      } else {
        this.setSelectedList()
      }
    },

    setSelectedList (list = []) {
      this.selectedList = list
    }
  },
  mounted () {
    this.toggleAllSelected(true)
  },
  watch: {
    selectedList () {
      this.$emit('input', this.selectedList)
      this.$emit('onChangeAnyList', this.filterName, this.selectedList)
    },
    filterList () {
      this.selectedList = [...this.filterList]
    }
  }
}
</script>

<style lang="scss" scoped>

  .filterList {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    .search {
      position: fixed;
      z-index: $z-index-xl;
      background: #fff;
      padding-top: 20px;
      max-width: max-content;
    }
    .inputSearch {
      border: 1px solid $color-gray;
      display: flex;
      align-self: center;
      border-radius: $border-radius;
      margin-bottom: 17px;
      position: relative;
      width: max-content;
      .icon {
        color: $color-light;
        width: 30px;
        position: relative;
        top: 15px;
        left: 10px;
      }
      .txt {
        min-height: $finger-size;
        border: none;
      }
    }
    .listItems {
      display: flex;
      flex-direction: column;
      margin-top: 84px;
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-top: 3px;
      }
      .item {
        padding-bottom: 10px;
        padding-top: 10px;
        .label {
          justify-content: flex-start !important;
          color: $color-dark;
          font-size: $font-size-small;
        }
      }
    }
    &::after {
      content: ".";
      height: 30px;
      color: transparent;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.79) 3%, #ffffff 98%);
      position: sticky;
      bottom: -20px;
      width: calc(100% - 20px);
      display: flex;
    }
  }
</style>
