<template lang="pug">
.status
    Icon.icon-row-status.status-icon(name="icon-row-status")
    .status-title {{ message }}

</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    defaultMessage: {
      type: String,
      default: 'Global.TooltipMessage'
    }
  },
  computed: {
    message () {
      return this.title || this.$t(this.defaultMessage)
    }
  }
}
</script>

<style lang="scss" scoped>

$icon-size: 26px;
$box-size: 200px;

.status {
  position: absolute;
  left: -$icon-size / 2;
  top: calc(50% - #{$icon-size / 2});

  &-title {
    display: none;
    z-index: $z-index-xxl;
    color: $color-light;
    position: relative;
    font-size: $font-size-small;
    background-color: white;
    border: 2px solid $color-warning;
    max-width: $box-size;
    box-shadow: 0 2px 15px 0 rgba(254, 90, 55, 0.28);
    border-radius: $border-radius;
    padding: 10px 20px;
    top: 10px;
    right: calc(50% - #{$icon-size / 2});

    &::before {
      content: '';
      position: absolute;
      width: $triangle-size;
      height: $triangle-size;
      border: solid $color-warning;
      background-color: #fff;
      border-width: 2px 0 0 2px;
      border-top-left-radius: $border-radius;
      transform: rotate(45deg);
      top: -$triangle-size / 2 - 1.5px; // -1.5px borderdan geliyor
      left: calc(50% - #{$triangle-size / 2});

    }
  }

  svg:hover + div {
    display: block;
  }
}
.status-icon {
  width: $icon-size;
  height: $icon-size;
}

</style>
