import VueAutonumeric from 'vue-autonumeric'

/**
 * Currency input component factory
 *
 * @description
 *  Create an extended version of VueAutonumeric by overriding default
 *  options with Settings.currencyOptions
 *
 * @param {Object} Store - Global store instance
 *
 * @returns {VueComponent}
 * */
const CurrencyInput = Store => Object.assign({}, VueAutonumeric, {
  props: Object.assign({}, VueAutonumeric.props, {
    options: {
      type: Object,
      default() {
        return Store.getters['Settings/currencyOptions']
      }
    }
  })
})

export default CurrencyInput
