var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "Sidebar",
      class: [_vm.MenuMinimalize ? "MiniMenu" : "Expect"],
    },
    [
      _c(
        "div",
        { staticClass: "Sidebar-top" },
        [
          _c("Logo"),
          _c("nav", { staticClass: "Nav", attrs: { id: "Sidebar" } }, [
            _c(
              "ul",
              { staticClass: "mainList" },
              _vm._l(_vm.mainNav, function (item, index) {
                return _c("Link", {
                  key: index,
                  ref: "NavRoute",
                  refInFor: true,
                  attrs: { link: item, "sales-warning": _vm.showSalesWarning },
                })
              }),
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Sidebar-bottom" },
        [
          _c(
            "div",
            {
              staticClass: "user-section user",
              class: _vm.MenuMinimalize ? "user-short-minimalize" : "",
              on: { click: _vm.goToSettings },
            },
            [
              !_vm.MenuMinimalize
                ? _c("Icon", { attrs: { name: "icon-settings-user" } })
                : _vm._e(),
              !_vm.MenuMinimalize
                ? _c("div", { staticClass: "user-name m-left-10" }, [
                    _vm._v(_vm._s(_vm.getUserName())),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "user-short" }, [
                _vm._v(_vm._s(_vm.shortName)),
              ]),
            ],
            1
          ),
          !_vm.MenuMinimalize
            ? _c("div", { staticClass: "tenant-name" }, [
                _vm._v(_vm._s(_vm.getTenantName())),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "user" },
            [
              _c(
                "router-link",
                {
                  attrs: { id: "link-settings", to: { name: "SettingsUser" } },
                },
                [
                  _c("Icon", {
                    staticClass: "m-right-10",
                    attrs: { name: "icon-global-settings" },
                  }),
                  !_vm.MenuMinimalize
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("Global.userMenu_Settings"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("Help", {
            staticClass: "user",
            attrs: { showHelpCenterText: _vm.MenuMinimalize ? false : true },
          }),
          _c(
            "div",
            { staticClass: "user", on: { click: _vm.logOut } },
            [
              _c("Icon", { attrs: { name: "icon-logout" } }),
              !_vm.MenuMinimalize
                ? _c("span", { staticClass: "m-left-10" }, [
                    _vm._v(_vm._s(_vm.$t("Global.Mobile_Logout"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "sideBarToggleBtn m-top-20 m-bottom-15" },
            [
              !_vm.MenuMinimalize
                ? _c("span", { staticClass: "version" }, [
                    _vm._v("v" + _vm._s(_vm.appVersion)),
                  ])
                : _vm._e(),
              _c("Button", {
                attrs: {
                  iconName: _vm.toggleIconName,
                  justIcon: true,
                  iconClass: _vm.toggleIconName,
                },
                on: { click: _vm.toggleExpandedMenu },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }