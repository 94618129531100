<template lang="pug">
.Tableee-container(:class="{ 'Tableee-scroll': fields.find(i => i.fixedColumnHeader) || addScrollToNoFixedColumns }")
    VehicleLoading.loading(:size="250" v-if="useInfiniteScroll && isLoadingInfiniteScrollRequest")

    .Tableee-main(:class="{ 'fixed': !!fields.filter(i => i.fixedColumnHeader), 'bg-shadow': isLoadingInfiniteScrollRequest }" v-scroll="handleScroll")
      table.Tableee-root

        //- Table main head
        TableHead(
          :componentName="componentName"
          :fields="fields",
          :readOnly="readOnly"
          :usePortalAction="usePortalAction"
          :selectableAll="selectableAll"
          @showAllSelectedItems="allItemChecked"
          @sort="field => $emit('sort', field)"
          @openFilterPopup="field => $emit('openFilterPopup', field)"
          @changePopupFilterSearch="changePopupFilterSearch"
          @submitFilter="field => $emit('submitFilter', field)"
          @clearFilter="field => $emit('clearFilter', field)"
          :isCheckedAll="isChecked"
          :infoData="infoData"
          :selectedRows="selectedRows"
          :data="data"
          )

        //- Table main body
        TableBody(
          :componentName="componentName"
          :fields="fields",
          :data="data",
          :pointer="pointer"
          :readOnly="readOnly",
          :actions="actions"
          :selectable="selectable"
          :selectedRows="selectedRows",
          :showCheckboxForPassives="showCheckboxForPassives",
          :usePortalAction="usePortalAction"
          @delegateOnItemCopy="onItemCopy",
          @delegateOnItemEdit="onItemEdit",
          @delegateOnItemDelete="onItemDelete",
          @onItemChangeActiveStatus="onItemChangeActiveStatus"
          @delegateOnItemShow="onItemShow",
          @delegateOnItemShowCustom="row => $emit('delegateOnItemShowCustom', [row])",
          @delegateOnItemOverview="onItemOverview"
          @delegateOnItemSelect="onItemSelect"
          @delegateOnItemExport="onItemExport"
          :uniqueIdentifier="uniqueIdentifier"
        )

      EmptyState(
        v-if="isEmpty"
        :title="emptyStateTitle"
        :description="emptyStateDesc")

    template(v-if="page")
      TableFoot(
        v-if="!useInfiniteScroll"
        :componentName="componentName"
        :page="page",
        :footNote="footNote",
        @delegateOnChangePage="onChangePage"
        @delegateOnChangePageSize="onPageSizeChange"
      )
</template>

<script>

import TableHead from './head'
import TableBody from './body'
import TableFoot from './foot'

export default {
  name: 'TableView',

  components: {
    TableHead,
    TableBody,
    TableFoot
  },

  data () {
    return {
      isChecked: false
    }
  },

  props: {
    fields: {
      type: Array,
      default: []
    },
    componentName: {
      type: String,
      default: 'custom'
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    page: {
      type: Object
    },
    usePortalAction: {
      type: Boolean,
      default: false
    },
    footNote: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    showCheckboxForPassives: {
      type: Boolean,
      default: false
    },
    pointer: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: String,
      default: ''
    },
    selectableAll: {
      type: String,
      default: ''
    },
    selectedRows: {
      type: Array,
      default () {
        return []
      }
    },
    addScrollToNoFixedColumns: {
      type: Boolean,
      default: false
    },
    useInfiniteScroll: {
      type: Boolean,
      default: false
    },
    isLoadingInfiniteScrollRequest: {
      type: Boolean,
      default: false
    },
    uniqueIdentifier: {
      type: String,
      default: ''
    },
    isRowClickable: {
      type: Boolean,
      default: true
    },
    emptyStateTitle: {
      type: String,
      default () { 
        return this.$t('Global.Default_EmptyState_Title')
      }
    },
    emptyStateDesc: {
      type: String,
      default () { 
        return this.$t('Global.Default_EmptyState_Desc')
      }
    }
  },

  computed: {
    isEmpty () {
      return this.data.length === 0
    }
  },

  methods: {
    handleScroll (evt, element) {
      if (this.isLoadingInfiniteScrollRequest || (this.page && this.page.number >= this.page.last) || !this.useInfiniteScroll) return
      if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
        this.$emit('delegateOnChangePage', 'next')
      }
    },
    onChangePage (page) {
      this.$emit('delegateOnChangePage', page)
    },
    onPageSizeChange (pageSize) {
      this.$emit('delegateOnChangePageSize', pageSize)
    },

    onItemCopy (item) {
      this.$emit('delegateOnItemCopy', item)
    },

    onItemEdit (item) {
      this.$emit('delegateOnItemEdit', item)
    },

    onItemDelete (item) {
      this.$emit('delegateOnItemDelete', item)
    },

    onItemChangeActiveStatus (item) {
      this.$emit('delegateOnItemChangeActiveStatus', item)
    },

    onItemShow (item, event) {
      this.$emit('delegateOnItemShow', item)
      this.$emit('delegateOnItemShowCustom', [item, event])
    },

    onItemOverview (item) {
      this.$emit('delegateOnItemOverview', item)
    },

    onItemExport (item) {
      this.$emit('delegateOnItemExport', item)
    },

    onItemSelect (item) {
      if (this.isChecked) this.isChecked = !this.isChecked
      this.$emit('delegateOnItemSelect', item)
    },

    allItemChecked () {
      this.isChecked = !this.isChecked
      this.$emit('showAllSelectedItems', this.isChecked)
    },

    changePopupFilterSearch ({search, field}) {
      this.$emit('changePopupFilterSearch', {search, field})
    }
  }
}
</script>

<style lang="scss" scoped>

.loading {
  position: absolute;
  z-index: 3;
  bottom: calc(50% - 125px);
  left: calc(50% - 125px);
  // padding: 20px;
  // display: flex;
  // justify-content: center;
  // width: 100%;
}
.bg-shadow{
  opacity: 0.4;
}
</style>
