var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multi-search-select" }, [
    _c("label", { staticClass: "Form-item-label" }, [
      _vm._v(_vm._s(_vm.formLabel)),
    ]),
    _c(
      "div",
      { staticClass: "control" },
      [
        _c(
          "custom-search",
          _vm._b(
            {
              ref: "vSelect",
              attrs: {
                "data-vv-as": _vm.formLabel,
                "on-search": _vm.getOptionsList,
                options: _vm.selectOptionsList,
                label: _vm.searchLabel,
                placeholder: _vm.inputPlaceholder,
                disabled: _vm.disabled,
                loading: _vm.isLoadingAction,
                searchAll: _vm.searchAll,
                inputClass: _vm.inputClass,
              },
              model: {
                value: _vm.searchItem,
                callback: function ($$v) {
                  _vm.searchItem = $$v
                },
                expression: "searchItem",
              },
            },
            "custom-search",
            _vm.$attrs,
            false
          ),
          [
            _c("template", { slot: "no-options" }, [
              _vm._v(_vm._s(_vm.searchNoResult)),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "items" },
      [
        _c("SelectedList", {
          attrs: { label: _vm.searchLabel, moduleName: _vm.moduleName },
          model: {
            value: _vm.lists,
            callback: function ($$v) {
              _vm.lists = $$v
            },
            expression: "lists",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }