var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-info" },
    _vm._l(_vm.listData, function (item) {
      return _c(
        "div",
        {
          staticClass: "row",
          class: { "multiple-values": item.list.length > 1 },
        },
        [
          _c("div", { staticClass: "col title" }, [_vm._v(_vm._s(item.title))]),
          _c(
            "div",
            { staticClass: "col value" },
            _vm._l(item.list, function (infoName) {
              return _c("span", { staticClass: "item-info__name" }, [
                _vm._v(_vm._s(infoName)),
              ])
            }),
            0
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }