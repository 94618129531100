var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("businessMultiselect", {
    ref: "businessRecipeGroupsFilter",
    attrs: {
      componentName: "recipe-groups",
      label: _vm.$t("Global.toolbar_RecipeGroups_Label"),
      popupHeader: _vm.$t("Global.toolbar_RecipeGroups_PopupHeader"),
      customSelectedData: _vm.customSelectedData,
      multiselectItems: _vm.recipeGroupList,
    },
    on: { submitFilter: _vm.submitFilter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }