var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkPdfLoad
    ? _c("iframe", {
        ref: "pdfIframe",
        staticClass: "secret-iframe",
        attrs: { type: "application/pdf", frameborder: "0", src: _vm.printPdf },
        on: { load: _vm.takePrint },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }