<template lang="pug">
businessMultiselect(
    componentName="multiselect-store-filter"
    :label="labelTitle"
    :popupHeader="$t('Global.toolbar_Stores_PopupHeader')"
    :multiselectItems="storeList"
    ref="businessStoreFilter"
    @submitFilter="submitFilter"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    isDataAvailable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    businessMultiselect
  },

  async beforeMount () {
    if (!this.isDataAvailable) await this.getMiniStoreList()
  },

  computed: {
    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    labelTitle () {
      return this.label ? this.label : this.$t('Global.toolbar_Store_Label')
    }
  },

  methods: {
    ...mapActions('Stores', [
      'getMiniStoreList'
    ]),

    clearStoreFilter (dontSubmit = true) {
      this.$refs.businessStoreFilter.clearAllFilter(dontSubmit)
    },

    submitFilter ({ list, dontSubmit }) {
      this.$emit('submitFilter', {list, dontSubmit})
    }
  }
}
</script>
