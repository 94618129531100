<template lang="pug">
div.view-type
  div.firstNode(@click="firstNodeClick" :class="{'c-primary': firstSelected}")
    Icon.m-right-10(:name="firstNodeIcon")
  div.divider
  div.secondNode(@click="secondNodeClick" :class="{'c-primary': !firstSelected}")
    Icon.m-left-10(:name="secondNodeIcon")
</template>

<script>
export default {
  props: {
    firstNodeIcon: {
      type: String,
      required: true
    },
    secondNodeIcon: {
      type: String,
      required: true
    },
    firstSelected: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    firstNodeClick (event) {
      this.$emit('firstNodeClick', event)
    },
    secondNodeClick (event) {
      this.$emit('secondNodeClick', event)
    }
  }
}
</script>

<style lang="scss" scoped>

  .view-type{
    display: flex;
    justify-content: center;
    width: 72px;
    height: 44px;
    padding: 12px 10px;
    border-radius: 3px;
    border: solid 1px $color-gray;
    background-color: $color-white;
    color: $color-gray
  }
  .divider{
      width: 1px;
      background: $color-gray;
      height: 25px;
      position: absolute;
      margin-top: -3px;
  }
  .firstNode, .secondNode{
    align-self: flex-end;
    &:hover{
        cursor: pointer;
        color: $color-primary;
    }
  }
</style>
