var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help-button-area" }, [
    _c(
      "button",
      {
        staticClass: "help-button m-right-10",
        attrs: { id: "btn-help", type: "button", size: "xsmall" },
        on: { click: _vm.goHelpCenter },
      },
      [
        _c("Icon", { staticClass: "help-icon", attrs: { name: "icon-help" } }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showHelpCenterText,
                expression: "showHelpCenterText",
              },
            ],
            staticClass: "m-left-10",
            attrs: { id: "show-help-center" },
          },
          [_vm._v(_vm._s(_vm.$t("Page.header_helpcenter")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }