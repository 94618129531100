var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      ref: _vm.componentName,
      staticClass: "search",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          _vm.isCustomSituation ? _vm.onChangeInput : ""
        },
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            $event.preventDefault()
            return _vm.setHoveredItem("down")
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            )
              return null
            $event.preventDefault()
            return _vm.setHoveredItem("up")
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            $event.preventDefault()
            return _vm.closeDropdown.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return _vm.onSelect(_vm.hoveredItem)
          },
        ],
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "search-input",
          class: {
            search__shadow:
              _vm.showDropdown && !_vm.isEmptyOptions && _vm.search,
          },
        },
        [
          _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                ref: "search",
                staticClass: "txt search-input__input",
                class: _vm.inputClass,
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  disabled: _vm.disabled,
                  id: `${_vm.componentName}-search-input`,
                },
                domProps: { value: _vm.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                    _vm.onChangeInput,
                  ],
                  focus: _vm.onFocusInput,
                  blur: _vm.onBlurInput,
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
          _vm.isLoading
            ? _c("Loading", { staticClass: "search-input__icon isLoading" })
            : _c("Icon", {
                staticClass: "search-input__icon search",
                class: _vm.showDropdown
                  ? ["search__transition", "opened_dropdown"]
                  : false,
                attrs: { name: "icon-search" },
              }),
          _c(
            "svg",
            {
              staticClass: "search-input__icon dropdown",
              class: _vm.showDropdown
                ? ["dropdown-icon__transform", "opened_dropdown"]
                : false,
              on: { click: _vm.changeShowDropdown },
            },
            [_c("use", { attrs: { "xlink:href": "#icon-arrow-down" } })]
          ),
        ],
        1
      ),
      _vm.search || _vm.showDropdown
        ? [
            _vm.showDropdown && !_vm.isEmptyOptions
              ? _c(
                  "div",
                  { staticClass: "search-dropdown" },
                  _vm._l(_vm.options, function (option, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "search-dropdown-item",
                        class: [
                          _vm.hoveredItem === option
                            ? "search-dropdown-item__hover"
                            : null,
                          _vm.isItemDisabled(option) ? "" : "disabled-item",
                        ],
                        attrs: {
                          id: `${_vm.componentName}-search-item-${index}`,
                          index: index,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onSelect(option)
                          },
                          mouseover: function ($event) {
                            return _vm.setHoveredItem("", option)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "search-dropdown-item__text",
                            attrs: {
                              id: `${_vm.componentName}-search-item-${index}-text`,
                            },
                          },
                          [_vm._v(_vm._s(option[_vm.label]))]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.showDropdown &&
            _vm.isEmptyOptions &&
            _vm.search.length >= _vm.startFrom &&
            !_vm.dontShowDropdown &&
            !_vm.isLoading
              ? _c(
                  "div",
                  {
                    staticClass: "search-dropdown",
                    attrs: { id: `${_vm.componentName}-search-item-no-result` },
                  },
                  [
                    _c("div", { staticClass: "search-dropdown-item" }, [
                      _c(
                        "span",
                        {
                          staticClass: "search-dropdown-item__text",
                          attrs: {
                            id: `${_vm.componentName}-search-item-no-result-text`,
                          },
                        },
                        [_vm._v(_vm._s(_vm.noResultTextComputed))]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.showDropdown && this.options.length > 4
              ? _c("div", { staticClass: "search-dropdown-tranparency" })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }