var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "toggle-sorting", on: { click: _vm.sort } }, [
    _c(
      "label",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.order,
            expression: "order",
          },
        ],
        staticClass: "order-number",
      },
      [_vm._v(_vm._s(_vm.order))]
    ),
    _c("div", { staticClass: "icons" }, [
      _c(
        "svg",
        {
          staticClass: "icon icon-down-arrow icons-up toggle-button",
          class: _vm.item.sortType === 0 ? "active" : "",
        },
        [_c("use", { attrs: { "xlink:href": "#icon-down-arrow" } })]
      ),
      _c(
        "svg",
        {
          staticClass: "icon icon-down-arrow icons-down toggle-button",
          class: _vm.item.sortType === 1 ? "active" : "",
        },
        [_c("use", { attrs: { "xlink:href": "#icon-down-arrow" } })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }